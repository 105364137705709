/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef, useCallback } from 'react';
import Card from "../../Base/Card";
import ReactMapGL, { FullscreenControl, MapRef, AttributionControl } from 'react-map-gl';
import './map.css';

import { getBaseLayer, getActionType, getOverlays } from '../../../features/map/mapSlice';

import { useAppSelector } from '../../../app/hooks';
import { getSelectedLocation } from '../../../features/dash/dashSlice';
import { LngLat } from 'maplibre-gl';
import { store } from '../../../app/store';
import { NWSMapAlert, Observation, useAlertsByPointMutation, useObservationsMutation } from '../../../Services/API';
import { LightningLayer, NWSAlertLayer, RadarLayer, StormVectorLayer, SurfaceAnalysisLayer, TropicalModelLayer } from './Layers';
import { NWSAlertView, ObservationView, StormVectorView, StrikeView } from './Views';
import { LocationMarker } from './Markers';
import { BaseLayerControl, BottomLayerGroup, CenterControl, LayerControl, LeftLayerGroup, LegendControl,
	ObservationsControl, OptionsControl, PlayButtonControl, RadarSelectControl, TimelineControl, ZoomControl, RightLayerGroup, MeasureControl } from './Controls';
import { MapEditor } from './MapEditor';
import { useMediaQuery, useTheme } from '@mui/material';
import LightningQuickLayer from './Layers/LightningQuickLayer';

const fsControlStyle = {
	left: 10,
	top: 10,
  };

const groupControlStyle = {
	// left: 10,
	zIndex: 100,
	// bottom: 40,
	bottom: 20,
};

const mapTilerKey = process.env.REACT_APP_MAP_TILER_KEY;

type baseLayerType = 'satellite' | 'dark' | 'light';

const getMapStyle = (type : baseLayerType) => {
	switch (type) {
		case 'satellite':
			return `https://api.maptiler.com/maps/hybrid/style.json?key=${mapTilerKey}`;
		case 'dark':
			return `https://api.maptiler.com/maps/7a330e3e-5974-4112-9b7f-15f3a2416735/style.json?key=${mapTilerKey}`;
		case 'light':
			return `https://api.maptiler.com/maps/basic/style.json?key=${mapTilerKey}`;
	}
};

interface MapProps {
	children?: React.ReactNode
}

type ActiveElementType = 'none' | 'options' | 'radar' | 'layer' | 'base' | 'legend';

export default function Map(props :MapProps) {
	const { children } = props;
	const theme = useTheme();
	const [getAlertsByPoint, {data: alertsByPoint}] = useAlertsByPointMutation();

	const [getObservation, {data: observationData}] = useObservationsMutation();
	const [getMetarObservation, {data: metarObservationData}] = useObservationsMutation();

	const mapRef = useRef<MapRef>(null);

	const [activeElement, setActiveElement] = useState<ActiveElementType>('none');
	const [observation, setObservation] = useState<Observation | undefined>(undefined);
	const [metarObservation, setMetarObservation] = useState<Observation | undefined>(undefined);

	const [alerts, setAlerts] = useState<NWSMapAlert[]>();

	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	const [viewport, setViewport] = useState({
		latitude: 32.7767,
		longitude: -96.7970,
		zoom: 8
	});

	const [clickedInfo, setClickedInfo] = useState<any>(undefined);

	const actionType = useAppSelector(getActionType);

	function onVPChange(nextVP: any) {
		setClickedInfo(null);
		setViewport(nextVP);
	}

	const resetObservation = () => {
		setObservation(undefined);
		setMetarObservation(undefined);
	}

	const selectedLocation = useAppSelector(getSelectedLocation);
	useEffect( () => {
		if (selectedLocation) {
			setViewport({
				latitude: selectedLocation!.latitude,
				longitude: selectedLocation!.longitude,
				zoom: 8
			});
		}
	},[ selectedLocation ]);


	useEffect( () => {
		if (mapRef.current) {
			let map = mapRef.current!.getMap();
			// map.loadImage('https://docs.mapbox.com/mapbox-gl-js/assets/cat.png', (error: any, image: any) => {
			map.loadImage('/LightningStrike.png', (error: any, image: any) => {
				if (error) throw error;
				if (!map.hasImage('map-lightning')) map.addImage('map-lightning', image, { sdf: true });
			});
			map.loadImage('/LightningStrike-Red.png', (error: any, image: any) => {
				if (error) throw error;
				if (!map.hasImage('map-lightning-outline-red')) map.addImage('map-lightning-outline-red', image, { sdf: false });
			});
			map.loadImage('/LightningStrike-Yellow.png', (error: any, image: any) => {
				if (error) throw error;
				if (!map.hasImage('map-lightning-outline-yellow')) map.addImage('map-lightning-outline-yellow', image, { sdf: false });
			});
			map.loadImage('/LightningStrike-Green.png', (error: any, image: any) => {
				if (error) throw error;
				if (!map.hasImage('map-lightning-outline-green')) map.addImage('map-lightning-outline-green', image, { sdf: false });
			});
			map.loadImage('/LightningStrikeRedAlt.png', (error: any, image: any) => {
				if (error) throw error;
				if (!map.hasImage('map-lightning-outline-red-alt')) map.addImage('map-lightning-outline-red-alt', image, { sdf: false });
			});
			map.loadImage('/LightningStrikeYellowAlt.png', (error: any, image: any) => {
				if (error) throw error;
				if (!map.hasImage('map-lightning-outline-yellow-alt')) map.addImage('map-lightning-outline-yellow-alt', image, { sdf: false });
			});
			map.loadImage('/LightningStrikeGreenAlt.png', (error: any, image: any) => {
				if (error) throw error;
				if (!map.hasImage('map-lightning-outline-green-alt')) map.addImage('map-lightning-outline-green-alt', image, { sdf: false });
			});
			map.loadImage('/Triangle.png', (error: any, image: any) => {
				if (error) throw error;
				if (!map.hasImage('triangle-outline')) map.addImage('triangle-outline', image, { sdf: true });
			});
		}
	}, [mapRef])

	useEffect( () => {
		setAlerts(alertsByPoint);
	},[ alertsByPoint ]);

	const chosenStyle = useAppSelector(getBaseLayer);
	const chosenLayers = useAppSelector(getOverlays);
	let mapStyle = getMapStyle(chosenStyle);

	const onClick = useCallback(event => {
		const {
		  features,
		  srcEvent: {offsetX, offsetY}
		} = event;


		// Removing single feature kind of breaks this, but that's okay.... storm vector maybe lightning strike.
		const clickedFeature = features && features;
		const currentLayers = store.getState().map.overlays;

		const obsActive = store.getState().map.actionType === 'observation';

		if(currentLayers.includes('nws-alerts'))
		{
			getAlertsByPoint({longitude: event.lngLat[0], latitude: event.lngLat[1]});
		}

		if(!clickedFeature && obsActive)
		{
			getObservation({coord:{latitude: event.lngLat[1], longitude: event.lngLat[0]}, preferred: true})
			getMetarObservation({coord:{latitude: event.lngLat[1], longitude: event.lngLat[0]}, preferred: false})
		}
		else
		{
			resetObservation();
		}

		setClickedInfo(
			{
				feature: clickedFeature,
				x: offsetX,
				y: offsetY,
				lngLat: new LngLat (event.lngLat[0], event.lngLat[1])
			}
		);
	  }, [getAlertsByPoint]);

	const onChildClick = (element: ActiveElementType) => {
		if(element === activeElement)
			setActiveElement('none')
		else
			setActiveElement(element);
	}

	const getInteractiveLayerIds = () => {
		let layerIds: string[] = [];
		if(chosenLayers.includes('lightning'))
		{
			layerIds.push("unclustered-point-green", "unclustered-point-yellow", "unclustered-point-red");
		}
		if(chosenLayers.includes('lightning-special'))
		{
			layerIds.push("unclustered-point-green-all", "unclustered-point-yellow-all", "unclustered-point-red-all");
		}

		if(chosenLayers.includes('storm-vectors'))
		{
			layerIds.push('storm-vector-point')
		}
		return layerIds;
	};

	useEffect( () => {
		if(actionType !== 'observation')
		{
			resetObservation();
		}
	},[ actionType ]);

	useEffect( () => {
		setObservation(observationData);
	},[ observationData ]);

	useEffect( () => {
		setMetarObservation(metarObservationData);
	},[ metarObservationData ]);
	return (
		<Card fullContent header="Live Radar" >
			<ReactMapGL
				className="main-map"
				{...viewport}
				width="100%"
				height="100%" // <--- Has problems in fullscreen, using 100vh causes a much too large map <--- Groß
				// height="calc(100% - 32px)" // <--- Has problems in fullscreen, using 100vh causes a much too large map <--- Groß
				mapStyle={mapStyle}
				onViewportChange={onVPChange}
				interactiveLayerIds={getInteractiveLayerIds()}
				onClick={onClick}
				ref={mapRef}
				attributionControl={false}
				style={{cursor: 'cross-hair' }}
			>
				<AttributionControl style={{bottom: 0, right: 0, fontSize: 10}} compact={false}/>
				<LocationMarker />
				{ chosenLayers.includes('radar') ? <RadarLayer hasLocation={selectedLocation !== undefined} mapRef={mapRef.current?.getMap()} viewport={viewport}/> : <></> }
				{ chosenLayers.includes('surface-analysis') ? <SurfaceAnalysisLayer /> : <></>}
				{ chosenLayers.includes('tropical-models') ? <TropicalModelLayer /> : <></>}


				{children}

				{actionType === 'measure' &&
					<MapEditor />
				}

				<BottomLayerGroup style={groupControlStyle} className={undefined}>
					{/* <OptionsControl onClick={onChildClick}/> */}
					<div style={{display: 'flex', flexDirection: 'row', justifyContent:"space-evenly", flexGrow: 1}}>
						<OptionsControl onClick={e => onChildClick('options')} show={activeElement === 'options'}/>
						<div>
							<RadarSelectControl onClick={e => onChildClick('radar')} show={activeElement === 'radar'}/>
							<PlayButtonControl />
						</div>
						{/* <PlayButton onMouseOver={() => setShowHoverMenu(true)} onMouseOut={() => setShowHoverMenu(false)} /> */}
					</div>
					{/* <TimelineControl ref={timelineRef} */}
					<TimelineControl
					style={{
						height: '100%',
						flexGrow: 10
						// minWidth: '68%',
					}}/>
					<div style={{display: 'flex', flexDirection: 'row', justifyContent:"space-evenly", flexGrow: 1}}>
						<LayerControl onClick={e => onChildClick('layer')} show={activeElement === 'layer'}/>
						<BaseLayerControl onClick={e => onChildClick('base')} show={activeElement === 'base'}/>
					</div>
				</BottomLayerGroup>
				{mapRef.current && chosenLayers.includes('storm-vectors') ? <StormVectorLayer />: <></>}
				{mapRef.current && chosenLayers.includes('lightning') ? <LightningQuickLayer viewport={viewport} mapRef={mapRef.current?.getMap()} /> : <></> }
				{mapRef.current && chosenLayers.includes('lightning-special') ? <LightningLayer viewport={viewport} mapRef={mapRef.current?.getMap()} /> : <></> }
				{mapRef.current && chosenLayers.includes('nws-alerts') ? <NWSAlertLayer viewport={viewport} mapRef={mapRef.current?.getMap()} /> : <></> }
				<LeftLayerGroup style={fsControlStyle} className={undefined}>
					<ZoomControl />
					<FullscreenControl style={{position: 'relative', marginBottom: 10}} />
					<CenterControl  />
					{!isMobile && 
					<>
						<MeasureControl />
						<ObservationsControl/>
					</>
					}
				</LeftLayerGroup>
				<RightLayerGroup>
					<LegendControl onClick={e => onChildClick('legend')} show={activeElement === 'legend'}/>
					{isMobile && 
					<>
						<MeasureControl />
						<ObservationsControl/>
					</>
					}
				</RightLayerGroup>

				{(clickedInfo && actionType === 'observation' && observation) &&
					<ObservationView clickedInfo={clickedInfo} observation={observation} metarObservation={metarObservation}/>
				}
				{(clickedInfo && clickedInfo.feature && clickedInfo.feature[0] && clickedInfo?.feature[0].source === 'lightning') &&
					<StrikeView clickedInfo={clickedInfo}/>
				}
				{(clickedInfo && clickedInfo.feature && clickedInfo.feature[0] && clickedInfo?.feature[0].source === 'lightning-all') &&
					<StrikeView clickedInfo={clickedInfo}/>
				}

				{(clickedInfo && clickedInfo.feature && clickedInfo.feature[0] && clickedInfo?.feature[0].source === 'storm-vectors') &&
					<StormVectorView clickedInfo={clickedInfo}/>
				}
				{(chosenLayers.includes('nws-alerts') && clickedInfo && alerts && alerts.length !== 0) && (
					<NWSAlertView clickedInfo={clickedInfo} alerts={alerts}/>
				)}



			</ReactMapGL>
		</Card>
	);
}
