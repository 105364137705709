/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Divider, Grid, IconButton, Typography, useTheme } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { BaseModal, Button, Page, TextArea, TextField } from "../Components/Base";
import Table, { RowMenuProps } from "../Components/Base/BaseTable";
import { ModelStateErrorResponse, PAStoredMessage, PWError, StoredPaMessageErrorsModel, useAddPAStoredMessageMutation, useDeletePAStoredMessageMutation, usePaStoredMessagesQuery, useUpdatePAStoredMessageMutation } from "../Services/API";
import { Create, DeleteOutline } from '@mui/icons-material';
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { openToast } from "../features/toast/toastSlice";

type ModalOptions = 'ADD' | 'UPDATE' | 'DELETE';

interface InputRowProps{
    children: JSX.Element,
    label: string
}

function InputRow(props:InputRowProps){
    const {children, label} = props;

    return(
        <Grid container spacing={2} style={{marginBottom: 16}}>
            <Grid container item md sm={12} style={{display:'flex', flexDirection: 'column', justifyContent:'center'}}>
                <Typography>{label}</Typography>
            </Grid>
            <Grid container item md={7} sm={12}>
                {children}
            </Grid>
        </Grid>
    );
}

export function PAStoredMessages() {
    const theme = useTheme();
    const dispatch = useDispatch();

    const { data: paStoredMessages, refetch } = usePaStoredMessagesQuery();

    const [addMessage, {data: addResponse, isSuccess: addSuccess, isError: addError, error: addErrorResponse}] = useAddPAStoredMessageMutation();
    const [updateMessage, {data: updateResponse, isSuccess: updateSuccess, isError: updateError, error: updateErrorResponse}] = useUpdatePAStoredMessageMutation();
    const [removeMessage, {data: deleteResponse, isSuccess: deleteSuccess, isError: deleteError, error: deleteErrorResponse}] = useDeletePAStoredMessageMutation();

    const [modalOpen, setModalOpen] = useState(false);
    const [modalOption, setModalOption] = useState<ModalOptions | undefined>(undefined);

    const [newMessage, setNewMessage] = useState<PAStoredMessage>({title: '', message: ''});
    const [editMessage, setEditMessage] = useState<PAStoredMessage | undefined>(undefined);
    const [deleteMessage, setDeleteMessage] = useState<PAStoredMessage | undefined>(undefined);

    const [errorState, setErrorState] = useState<StoredPaMessageErrorsModel>({});

    const handleSaveNewMessage = () => {
        addMessage(newMessage);
    }

    const handleUpdateMessage = () => {
        if(editMessage)
            updateMessage(editMessage);
    }

    const handleDeleteMessage = () => {
        if(deleteMessage && deleteMessage.id)
            removeMessage(deleteMessage.id);
    }

    const handleModalClose = () => {
        setErrorState({}); 
        setModalOpen(false);
    }

    const renderOptions = (props: RowMenuProps) => {
        const {id} = props;

        const message = paStoredMessages?.find(x => x.id === id);

        return (
            <Box component='div' display='flex' overflow='hidden' width='100%'>
                <IconButton onClick={() => {
                    setEditMessage(message)
                    setModalOption('UPDATE');
                    setModalOpen(true);
                }}>
                    <Create htmlColor={theme.palette.primary.main} />
                </IconButton>
                <IconButton onClick={() => {
                    setDeleteMessage(message);
                    setModalOption('DELETE');
                    setModalOpen(true);
                }}>
                    <DeleteOutline htmlColor={theme.palette.error.main} />
                </IconButton>
            </Box>
        );
    };

    const renderModal = () => {
        switch(modalOption)
        {
            case 'ADD':
                return(
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant='h5'>New Message</Typography>
                        </Grid>
                        <Divider style={{ height: 1, width: '100%', borderColor: theme.palette.divider, marginTop: 16, marginBottom: 16 }} />
                        <Grid item xs={12}>
                            <InputRow label='Title'>
                                <TextField isError={errorState.Title && errorState.Title.length > 0} helperText={errorState.Title ? errorState.Title[0] : undefined}
                                     value={newMessage.title} onChange={e => setNewMessage({...newMessage, title: e.target.value})}/>
                            </InputRow>
                            <InputRow label='Message'>
                                <TextArea isError={(errorState.Message && errorState.Message.length > 0) || newMessage.message.length > 350} helperText={
                                    <Typography variant={errorState.Message ? 'caption' : 'overline'} color={(errorState.Message && errorState.Message.length > 0) ? theme.palette.error.main : 100 > (350 -  newMessage.message.length) ? 20 > (350 - newMessage.message.length) ? theme.palette.error.main : theme.palette.warning.main : undefined}>{(errorState.Message && errorState.Message.length > 0) ? errorState.Message[0] : `${350-newMessage.message.length} characters left`}</Typography>
                                } value={newMessage.message} onChange={e => {setErrorState({...errorState, Message: undefined}); setNewMessage({...newMessage, message: e.target.value})}}/>
                            </InputRow>
                        </Grid>
                        <Grid container display='flex' justifyContent='flex-end'>
                            <Grid item xs={8} sm={5} display='flex' justifyContent='space-between'>
                                <Button type='clear' onClick={e => handleModalClose()}><Typography>Cancel</Typography></Button>
                                <Button onClick={e => handleSaveNewMessage()}><Typography>Save</Typography></Button>
                            </Grid>
                        </Grid>
                    </Grid>
                );
            case 'UPDATE':
                return(
                    editMessage &&
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant='h5'>Update Message</Typography>
                        </Grid>
                        <Divider style={{ height: 1, width: '100%', borderColor: theme.palette.divider, marginTop: 16, marginBottom: 16 }} />
                        <Grid item xs={12}>
                            <InputRow label='Title'>
                            <TextField isError={errorState.Title && errorState.Title.length > 0} helperText={errorState.Title ? errorState.Title[0] : undefined}
                                     value={editMessage.title} onChange={e => setEditMessage({...editMessage, title: e.target.value})}/>
                            </InputRow>
                            <InputRow label='Message'>
                                <TextArea isError={(errorState.Message && errorState.Message.length > 0) || editMessage.message.length > 350} helperText={
                                    <Typography variant={errorState.Message ? 'caption' : 'overline'} color={(errorState.Message && errorState.Message.length > 0) ? theme.palette.error.main : 100 > (350 -  editMessage.message.length) ? 20 > (350 - editMessage.message.length) ? theme.palette.error.main : theme.palette.warning.main : undefined}>{(errorState.Message && errorState.Message.length > 0) ? errorState.Message[0] : `${350-editMessage.message.length} characters left`}</Typography>
                                } value={editMessage.message} onChange={e => {setErrorState({...errorState, Message: undefined}); setEditMessage({...editMessage, message: e.target.value})}}/>
                            </InputRow>
                        </Grid>
                        <Divider style={{ height: 1, width: '100%', borderColor: theme.palette.divider, marginTop: 16, marginBottom: 16 }} />
                        <Grid item xs={7}>
                        </Grid>
                        <Grid item xs={5} display='flex' justifyContent='space-between'>
                            <Button type='clear' onClick={e => handleModalClose()}><Typography>Cancel</Typography></Button>
                            <Button onClick={e => handleUpdateMessage()}><Typography>Save</Typography></Button>
                        </Grid>
                    </Grid>
                );
            case 'DELETE':
                return(
                    deleteMessage &&
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant='h5'>Delete Message</Typography>
                        </Grid>
                        <Divider style={{ height: 1, width: '100%', borderColor: theme.palette.divider, marginTop: 16, marginBottom: 16 }} />
                        <Grid item xs={12} marginBottom={2}>
                            <Typography>Are you sure you want to delete message <Typography component='span' variant='subtitle1'> {deleteMessage.title}?</Typography></Typography>
                        </Grid>
                        <Grid item xs={7}>
                        </Grid>
                        <Grid item xs={5} display='flex' justifyContent='space-between'>
                            <Button type='clear' onClick={e => handleModalClose()}><Typography>Cancel</Typography></Button>
                            <Button type='delete' onClick={e => handleDeleteMessage()}><Typography>Delete</Typography></Button>
                        </Grid>
                    </Grid>
                );
            default:
                return(
                    <Typography>Invalid Option</Typography>
                )
        }
    };


    const columns: GridColDef[] =
    [
        { field: 'id', headerName: 'Id', sortable: true, flex: .1, hide: true },
        { field: 'title', headerName: 'Title', sortable: true, flex: .2, hide: false },
        { field: 'message', headerName: 'Message', sortable: true, flex: .2, hide: false },
        { field: 'customerId', headerName: 'Customer', sortable: true, flex: .1, hide: true },
        { field: 'modalOptions', headerName: '', sortable: false, flex: .1, hide: false, renderCell: renderOptions},

    ];

    useEffect(() => {
        if(addSuccess && addResponse){
            dispatch(openToast({variant: 'success', header: 'Successfully Added Stored Message'}));
            handleModalClose();
            setNewMessage({title: '', message: ''})
            refetch();
        }else if(addError){
            var paStoredMessageErrors = (addErrorResponse as ModelStateErrorResponse)?.data.responseException?.errors as StoredPaMessageErrorsModel;
            if(paStoredMessageErrors)
            {
                setErrorState(paStoredMessageErrors);
                if(paStoredMessageErrors.ServerError)
                    dispatch(openToast({variant:'error', header: paStoredMessageErrors.ServerError[0]}));
                else
                    dispatch(openToast({variant:'error', header: 'Failed To Add Message'}));
            }
            else
            {
                dispatch(openToast({variant:'error', header: 'Failed To Add Message'}));
            }
        }
      },[addSuccess, addError, addResponse, addErrorResponse])


    useEffect(() => {
        if(updateSuccess && updateResponse){
            dispatch(openToast({variant: 'success', header: 'Successfully Updated Stored Message'}));
            handleModalClose();
            setEditMessage(undefined);
            refetch();
        }else if(updateError){
            var paStoredMessageErrors = (updateErrorResponse as ModelStateErrorResponse)?.data.responseException?.errors as StoredPaMessageErrorsModel;
            if(paStoredMessageErrors)
            {
                setErrorState(paStoredMessageErrors);
                if(paStoredMessageErrors.ServerError)
                    dispatch(openToast({variant:'error', header: paStoredMessageErrors.ServerError[0]}));
                else
                    dispatch(openToast({variant:'error', header: 'Failed To Update Message'}));
            }
            else
            {
                dispatch(openToast({variant:'error', header: 'Failed To Update Message'}));
            }
        }
      },[updateSuccess, updateError, updateResponse, updateErrorResponse]);

      useEffect(() => {
        if(deleteSuccess && deleteResponse){
            dispatch(openToast({variant: 'success', header: 'Successfully Deleted Stored Message'}));
            handleModalClose();
            refetch();
        }
        else if(deleteError){
            if((deleteErrorResponse as PWError)?.data.responseException.message)
            {
                dispatch(openToast({variant:'error', header: (deleteErrorResponse as PWError)?.data.responseException.message}));
            }
            else
            {
                dispatch(openToast({variant:'error', header: 'Failed To Delete Message'}));
            }
        }
      },[deleteSuccess, deleteError, deleteResponse, deleteErrorResponse]);

    return (
        <Page
            docTitle='Stored PA Messages'
            title={<Typography variant="h3" color="textPrimary" noWrap>Stored PA Messages</Typography>}
            pageAction={[
                <Button onClick={() => {
                    setModalOption('ADD');
                    setModalOpen(true);
                }}>
                    <Typography>Add Message</Typography>
                </Button>]}>
            {paStoredMessages &&
                <Table
                    cols={columns}
                    rows={paStoredMessages}
                    rowSpace={8}
                    rowHeight={54}
                    rowsPerPageOptions={[10]}
                />
            }
            <BaseModal disabled={true}
                modalState={{open: modalOpen,
                handleClose:() => {setNewMessage({title: '', message: ''}); handleModalClose()},
                handleOpen:() => setModalOpen(true)}}>
                {renderModal()}
            </BaseModal>
        </Page>
    );
}
export default PAStoredMessages;
