import { api } from ".";

export interface UserNotification{
    id: string,
    sentUtc: Date,
    owner: string,
    service: string,
    destination: string,
    message: string,
    log: string,
    timeZone: string
  }

  export interface NotificationSettings{
    declinesNotifications: boolean,
    optOutForecast: boolean,
    lightningNotification: boolean,
    precipitationNotification: boolean,
    severeWeatherNotification: boolean
    notificationType: number,
    timeZone: string
  }

const notificationsApi = api.injectEndpoints({
    endpoints: (builder) => ({
        notifications: builder.query<UserNotification[], {startDate: string, endDate: string, count: number}>({
            query: (request) => ({
              url: `User/Notifications`,
              method: 'POST',
              body: request
            }),
            transformResponse: (response: {data: UserNotification[]}) => response.data
          }),
          notificationSettings: builder.query<NotificationSettings, void>({
            query: () => 'Settings/Notifications',
            transformResponse: (response: { data: NotificationSettings }) => response.data
          }),
          updateNotificationSettings: builder.mutation<string, NotificationSettings>({
            query: (notiSet) => ({
              url: 'Settings/Notifications',
              method: 'POST',
              body: notiSet
            }),
            transformResponse: (response: { data: string}) => response.data,
          })
    })
})

export const {
    useNotificationsQuery,
    useNotificationSettingsQuery,
    useUpdateNotificationSettingsMutation
} = notificationsApi;