import { Grid, Theme, Typography, useTheme } from '@mui/material';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

interface SubCardContainerContent {
    title: string,
    desc?: JSX.Element,
    children: JSX.Element,
    marginBottom?: string | number,
    justifyTitle?: 'flex-start' | 'center' | 'flex-end'
}

const useStyles = makeStyles((theme:Theme) =>
    createStyles({
        inputContainer:{
            display:'flex', 
            justifyContent:'center', 
            flexDirection: 'column',
            [theme.breakpoints.down('md')]:{
                paddingTop: 16
            }
        }
    })
);

export function SubCardContainer(props: SubCardContainerContent) {
    const {title, desc, children, justifyTitle = 'center'} = props;
    const theme = useTheme();
    const classes = useStyles(theme);
    
    return(
    <div>
      <Grid container spacing={1}>
        <Grid item md={5} xs={12} style={{display: 'flex', flexDirection:'column', justifyContent: justifyTitle}}>
              <Typography textAlign='left' variant='subtitle1' color='textPrimary'>{title}</Typography>
              {desc}
        </Grid>
        <Grid item md={7} xs={12} className={classes.inputContainer}>
          {children}
        </Grid>
      </Grid>
    </div>
    );
  }