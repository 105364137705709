import { Checkbox as MuiCheckbox, styled, useTheme} from '@mui/material';
import React, { useState } from 'react';
import { useEffect } from 'react';

const CustomCheckbox = styled(MuiCheckbox)``;

interface CheckboxProps {
	onChange?: React.ChangeEventHandler<HTMLInputElement>;
	disabled?: boolean;
	checked?: boolean;
}

export function Checkbox (props: CheckboxProps) {
	const {onChange, checked=false, disabled=false} = props;
	const [state, setCheckedState] = useState(checked);

	const theme = useTheme();

	useEffect(() =>{ 
		setCheckedState(checked);
	},[checked])

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setCheckedState(event.target.checked)
		if(onChange !== undefined)
			onChange(event)
	};

	return (
		<CustomCheckbox
			disabled={disabled}
			onChange={handleChange}
			checked={state}
			color='primary'
			sx={{
				'&:hover': {
					color: theme.palette.action.hover,
				}
			}}>
		</CustomCheckbox>
	);
}

export default Checkbox;