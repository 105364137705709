import { useMemo } from 'react';
import { Slider, SliderProps, styled } from '@mui/material';
import { _useMapControl as useMapControl } from "react-map-gl";
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { getProgress, setProgress, getCurrentTime, getRadarPlaying, RadarTimeDisplay } from '../../../../features/map/mapSlice';



const StyledSlider = styled(Slider)(({ theme }) => ({
	color: theme.palette.success.light,
	"& .MuiSlider-track":{
		height: 10,
		borderRadius: 8,
		background: 'linear-gradient(219.96deg, #FFE37E -38.99%, #22BACF 112%)'
	},
	"& .MuiSlider-rail":{
		height: 8,
		borderRadius: 8,
		background: "#ffffff",
	},
	'& .MuiSlider-thumb': {
		color: '#fff'
	},
	'& .MuiSlider-valueLabelLabel': {
		backgroundColor: '#fff',
		color: '#000',
		fontSize: 16,
		// margin: 8
	},
	'& .MuiSlider-valueLabel': {
		backgroundColor: '#fff',
		borderRadius: '10px',
		padding: 8
	}
}));


interface TimelineControlProps extends SliderProps {
	// ref: Ref
}


export function TimelineControl(props: TimelineControlProps) {
	const progress = useAppSelector(getProgress);
	const currentTime = useAppSelector(getCurrentTime);
	const isPlaying = useAppSelector(getRadarPlaying);

	const dispatch = useAppDispatch();
	const {containerRef} = useMapControl({
		onDragStart: (evt: any) => {
			// evt.stopPropagation();
		},
		onDoubleClick: (evt: any) => {
			evt.stopPropagation();
		},
		onClick: (evt: any) => {
			evt.stopPropagation();
		},
	});

	const {className} = props;

	// essentially need position absolute if being used standalone, i.e, not inside the group.
	// const style = useMemo(() => ({position: 'absolute', ...props.style}), [props.style]);
	const style = useMemo(() => ({height: '100%', ...props.style}), [props.style]);

	function formatAMPM(timeDisplay: RadarTimeDisplay) {
	// function formatAMPM(dateString: string) {
        var date = new Date(timeDisplay.time);
        if (date instanceof Date)
        {
          var hours = date.getHours();
          var minutes: string | number = date.getMinutes();
          var ampm = hours >= 12 ? 'PM' : 'AM';
          hours = hours % 12;
          hours = hours ? hours : 12; // the hour '0' should be '12'
          minutes = minutes < 10 ? '0'+minutes : minutes;


		  if (timeDisplay.displayDate) {
			var monthDate = `${date.getMonth() + 1}/${date.getDate()}`;
			let strTime = `${monthDate} ${hours}:${minutes} ${ampm}`;
			return strTime;
		  }
		  else {
			let strTime = `${hours}:${minutes} ${ampm}`;
			return strTime;
		  }
        }
    }

	function valueLabelFormat(value: number) {
		return formatAMPM(currentTime);
	}

	return (
		<div style={style} className={className}>
			{/* <div className="mapboxgl-ctrl mapboxgl-ctrl-group" ref={containerRef}> */}
			<div style={{marginTop: 5, display: 'flex', height:'100%', flexDirection: 'column', justifyContent: 'center'}} ref={containerRef}>
				<StyledSlider
					aria-label="Radar-Progress"
					value={progress}
					valueLabelFormat={valueLabelFormat}
					valueLabelDisplay={isPlaying ? "on" : "auto"}
					// defaultValue={progress}
					// ref={ref}
					onChange={(evt: any) => {dispatch(setProgress(evt ? evt.target.value : 0))}}
					// getAriaValueText={valuetext}
				/>
				{/* <Typography color="#000">{counter}</Typography> */}
			</div>
		</div>
	);
}

export default TimelineControl;