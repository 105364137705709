import { api } from ".";

export interface Advisory{
    id: string,
    title: string,
    link: string,
    expires?: string
  }

export interface Feature{
  id: string,
  geometry?: string,
  properties: NWSAlert
}

export interface NWSAlert{
    Id: string,
    AreaDesc: string,
    Sent: Date,
    Effective?: Date,
    Onset?: Date,
    Expires?: Date,
    Ends?: Date,
    Status: string,
    MessageType: string,
    Category: string,
    Certainty: string,
    Urgency: string,
    Event: string,
    SenderName: string,
    Headline: string,
    Description: string,
    Instruction: string,
    Responise: string
}

const advisoryApi = api.injectEndpoints({
    endpoints: (builder) => ({
        advisory: builder.query<Advisory[], string>({
            query: (locationId) => `Advisory/${locationId}`,
            transformResponse: (response: { data: Advisory[] }) => response.data
          }),
        nwsFeatures: builder.query<Feature[], string>({
          query: (locationId) => `Advisory/Detail/${locationId}`,
          transformResponse: (response: { data: Feature[] }) => response.data
        })
    })
})

export const {
  useAdvisoryQuery,
  useNwsFeaturesQuery
} = advisoryApi;