import { User } from "oidc-react";
import { LightningStrike, Wind, Globe, AirQuality, RainDropOutline, WindChill, HeatIndex } from "../Assets";
import { SelectOption } from "../Components/Base";
import { Policy } from "../Services/API";

export const timezones: SelectOption[] = [{text:'Central', value:'CST'}, {text:'Pacific', value:'PST'}, {text:'Eastern', value:'EST'}, {text:'Mountain', value:'MST'}];

export function CorrectUTC(date: Date){
    let newDate = new Date(new Date(Date.parse(date.toString())).toString().split(' ').splice(0,5).join(' ') + ' UTC');

    if(!isDST(new Date()))
        newDate.setHours(newDate.getHours() + 1)

    return newDate
}

function isDST(d: Date) {
    let jan = new Date(d.getFullYear(), 0, 1).getTimezoneOffset();
    let jul = new Date(d.getFullYear(), 6, 1).getTimezoneOffset();
    return Math.max(jan, jul) !== d.getTimezoneOffset();
}

export function clientUsTimezone(ianaTz: string){

    switch(ianaTz.toLowerCase()){
        case 'america/new_york':
            return 'EST';
        case 'america/chicago':
            return 'CST';
        case 'america/denver':
            return 'MST';
        case 'america/los_angeles':
            return 'PST';
        default:
            return ianaTz;
    }
}

export function formatPhonenumber(phoneNumber: string){
    return (
        `${phoneNumber.slice(0,3)}-${phoneNumber.slice(3,6)}-${phoneNumber.slice(6,11)}`
    )
}
// const Key: string = "PW3";
export function HasPermissionToView(user?: User) {
	// if (user &user.)
	if (user) {
        var hasLegacyAccess = user?.profile?.permissions?.includes('legacy.access');
        if (hasLegacyAccess) return false;
        else if (!hasLegacyAccess)return true;

        // Might do the below for individual allow
		// if (Array.isArray(user?.profile.app_access) && user?.profile.app_access.includes(Key))
		// 	return true;
		// else if (user?.profile.app_access === Key) return true;
	}
	return false;
}

export function getPolicyAlertsIcon(alertType: string, iconColor: string){
    switch(alertType){
        case 'HEAT':
            return {display: 'Heat Index', icon: <HeatIndex color={iconColor} width={24} height={24} />};
        case 'WBGT':
            return {display: 'Wet Bulb Globe', icon: <Globe color={iconColor}/>};
        case 'WIND':
            return {display: 'Wind Speed', icon: <Wind color={iconColor}/>};
        case 'CHILL':
            return {display: 'Wind Chill', icon: <WindChill color={iconColor} width={24} height={24} />};
        case 'GUST':
            return {display: 'Wind Gust', icon: <Wind color={iconColor}/>};
        case 'AQI':
            return {display: 'Air Quality Index', icon: <AirQuality color={iconColor}/>};
        case 'PRECIP':
            return {display: 'Precipitation Accumul.', icon: <RainDropOutline color={iconColor}/>};
        case 'LR1':
            return {display: 'Lightning', icon: <LightningStrike color={iconColor}/>};
    }
}

export function getDefaultPolicyMessage(policy: Policy) : {triggerMessage: string, allClearMessage: string} {
    let isLightningPolicy = policy.policyTypeShortName?.substr(0,2) === 'LR';

    if(isLightningPolicy)
    {
        return {
            triggerMessage: `Lightning Strike ${((policy.threshold || 1) - 0.4).toPrecision(2)} miles from your location at ${new Date().toLocaleTimeString(navigator.language, {hour: 'numeric', minute:'numeric', hour12: true})}`,
            allClearMessage: `${policy.allClearMinutes || 10} minute all clear, {{previous zone}} - ${policy.threshold || 1} mile zone from your location.`
        }
    }
    else
    {
        return{
            triggerMessage: `${policy.policyTypeName || 'temperature'} is ${policy.isDesc ? policy.threshold - 1 : (policy.threshold || 80) + 1}${policy.unit || '°F'} at your location as of ${new Date().toLocaleTimeString(navigator.language, {hour: 'numeric', minute:'numeric', hour12: true})}, which is  ${policy.isDesc ? 'below' : 'above'} your policy of ${policy.threshold || '80'}${policy.unit||'°F'}.`,
            allClearMessage: `The ${policy.policyTypeName || 'temperature'} has remained ${policy.isDesc ? 'above' : 'below'} policy threshold of ${policy.threshold || '80'}${policy.unit || '°F'} for ${policy.allClearMinutes || 60} mins as of ${new Date().toLocaleTimeString(navigator.language, {hour: 'numeric', minute:'numeric', hour12: true})} at your location.`
        }
    }
}

export const groupBy = (array: any, key: string ) => {
  // Return the end result
  if(array){
    return array.reduce((result: any, currentValue: any) => {
        // If an array already present for key, push it to the array. Else create an array and push the object
        (result[currentValue[key]] = result[currentValue[key]] || []).push(
        currentValue
        );
        // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
        return result;
    }, {}); // empty object is the initial value for result object
    }
};