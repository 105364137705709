import { Grid, Typography, useTheme } from "@mui/material";
import { Page } from "../Components/Base";
import Map from '../Components/Hardware/Map/Map';

export function HardwareMap() {

  const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	return (
		<Page docTitle="Hardware Map" title={<Typography variant="h3" color="textPrimary">Hardware Map</Typography>}>
			<Grid container>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid minHeight={1024} item xs={12} sm={12} md={12} lg={12} order={{ xs: 2, sm: 2, md: 2, lg: 1, xl: 1 }}>
                        <Map />
                      </Grid>
					</Grid>
				</Grid>
			</Grid>
		</Page>
	);
}
