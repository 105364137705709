import { Box, Fade, Typography } from "@mui/material";
import { LngLat } from "maplibre-gl";
import { useAppSelector } from "../../../../app/hooks";
import { getSelectedLocation } from "../../../../features/dash/dashSlice";
import { getDistance } from "../../../../features/map/MapHelpers";

const getStrikeType = ( type : number ) => {
	switch (type) {
		case 1:
			return 'Ground General'
		case 2:
			return 'Ground to Cloud'
		case 3:
			return 'Cloud to Ground'
		case 4:
			return 'Cloud Pulse'
	}
}

const getTime = (timestamp: number) => {
	var tsDate = new Date(0);
	tsDate.setUTCSeconds(timestamp);
	return tsDate.toLocaleTimeString('en-US');
}

interface StrikeViewProps {
    clickedInfo: any
}

export function StrikeView(props: StrikeViewProps) {
    const {clickedInfo} = props;
    const selectedLocation = useAppSelector(getSelectedLocation);

    return (
        <Fade in={true}>
            <Box style={{ borderRadius: 8, position: 'absolute', display: 'flex', flexDirection:'column', margin: 8, padding: 8, background: 'rgba(0,0,0,0.8)', color: '#fff', textAlign: 'left',
                minWidth: 140, fontSize: 10, zIndex: 9, pointerEvents: 'none',
                left: clickedInfo ? clickedInfo.x - 70 : 0,  top: clickedInfo ? clickedInfo.y - 120 : 0}}>
                <Typography variant='caption'>Intensity: {clickedInfo.feature.properties.Amp}</Typography>
                <Typography variant='caption'>Type: {getStrikeType(clickedInfo.feature.properties.type)}</Typography>
                <Typography variant='caption'>Distance: {getDistance(clickedInfo.lngLat, new LngLat(selectedLocation!.longitude, selectedLocation!.latitude))} Mi</Typography>
                <Typography variant='caption'>Time: {getTime(clickedInfo.feature.properties.TimeStamp)}</Typography>
            </Box>
		</Fade>
    );
}

export default StrikeView;