import BaseControl from './BaseControl';
import { Straighten } from '@mui/icons-material'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { getActionType, setActionType } from '../../../../features/map/mapSlice';

interface MeasureControlProps{
}

export function MeasureControl(props: MeasureControlProps){

    const actionType = useAppSelector(getActionType);
	var measureActive = actionType === 'measure';
	const dispatch = useAppDispatch();

    const onSetMeasure = () => {
		if(measureActive)
			dispatch(setActionType(undefined))
		else
        	dispatch(setActionType('measure'));
    };

    return(
        <div style={{marginBottom: 10}}>
            <BaseControl selected={measureActive} label="Measure" onClick={() =>  onSetMeasure()}>
                <Straighten />
            </BaseControl>
        </div>
    )
}