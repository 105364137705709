import {Switch as MuiSwitch, styled} from '@mui/material';
import { CSSProperties } from 'react';

const CustomSwitch = styled(MuiSwitch)(({ theme }) => ({
	padding: 0,
	width: 44,
	height: 24,
	borderRadius: 12,
	'& .MuiSwitch-thumb':{
		color: theme.palette.action.active,
	},
	'& .MuiSwitch-track':{
		backgroundColor: theme.palette.action.inactive,
		opacity: 1,
	},
	'& .MuiSwitch-switchBase':{
		padding: 2,
		'&.Mui-checked + .MuiSwitch-track': {
			opacity: 1,
		},
	}
}));


interface SwitchProps {
	onChange: ((event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void);
	checked?: boolean;
	style?: CSSProperties;
}

export function Switch (props: SwitchProps) {
	const {onChange, checked=false, style} = props;

	return (
		<CustomSwitch
			sx={style}
			checked={checked}
			onChange={onChange}>
		</CustomSwitch>
	);
}

export default Switch;
