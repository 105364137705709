import { BBox } from "../../Components/Dashboard/Map/Util";
import { Coordinates } from "../../features/map/MapHelpers";
import { api } from "./api";

export interface Strike {
  age: number;
  amp: number;
  latitude: number;
  longitude: number;
  strikeId: string;
  timeStamp: number;
  type: number;
}

export interface RadarParams {
  type: string;
  isFuture: boolean;
  frame: number;
  product?: string;
}

export interface NWSMapAlert {
  text: string;
  code: string;
  type: string;
}

export interface PrecipPointType {
  text: string,
  value: number,
  units: string
}

export interface PrecipPointRequest {
  product: string,
  mask: string,
  latitude: number,
  longitude: number
}

const debugMode = process.env.NODE_ENV === "development";
const mapApiBaseUrl = process.env.REACT_APP_MAP_API_RELATIVE_URL || '';

const mapApi = api.injectEndpoints({
  endpoints: (builder) => ({
    hardwareMap: builder.query<string, null>({
      query: () => ({
        headers: { Accept: "application/geo+json" },
        url: `${mapApiBaseUrl}Hardware`,
      }),
      transformResponse: (response: { data: string }) => response.data,
    }),

    wxsMap: builder.query<string, null>({
      query: () => ({
        headers: { Accept: "application/geo+json" },
        url: `${mapApiBaseUrl}Hardware/WXS`,
      }),
      transformResponse: (response: { data: string }) => response.data,
    }),

    lightningMap: builder.query<string, BBox>({
      query: (box: BBox) => ({
        headers: { Accept: "application/geo+json" },
        url: `${mapApiBaseUrl}ClusterStrikes?top=${box.top}&right=${box.right}&left=${box.left}&bottom=${box.bottom}&zoom=${
          box.zoom
        }&nonClusterLevel=${debugMode ? 4 : 8}${debugMode ? "&maxStrikes=5" : ""}`,
      }),
      transformResponse: (response: { data: string }) => response.data,
    }),

    lightningMapGeoJson: builder.query<string, null>({
      query: () => ({
        headers: { Accept: "application/geo+json" },
        url: `${mapApiBaseUrl}Strikes?source=weatherflow`,
      }),
      transformResponse: (response: { data: string }) => response.data,
    }),

    stormVectorsGeoJson: builder.query<string, null>({
      query: () => ({ headers: { Accept: "application/geo+json" }, url: `${mapApiBaseUrl}StormVectors/All` }),
      transformResponse: (response: { data: string }) => response.data,
    }),

    // lightningMap: builder.query<Strike[], BBox>({
    //   query: (box) => ({url:`Map/ClusterStrikes?top=${box.top}&right=${box.right}&left=${box.left}&bottom=${box.bottom}&zoom=${box.zoom}`}),
    //   transformResponse: (response: { data: Strike[] }) => response.data
    // }),
    // nam-maxreflectivity-dbz-all

    radar: builder.query<string, RadarParams>({
      query: (params) => ({
        url:
          params.product === ""
            ? `${mapApiBaseUrl}${params.type}?frame=${params.frame}&future=${params.isFuture ? "true" : "false"}`
            : `${mapApiBaseUrl}${params.type}?frame=${params.frame}&product=${params.product}`,
      }),

      transformResponse: (response: { data: string }) => response.data,
    }),

    surfaceAnalysis: builder.query<string, null>({
      query: () => ({ url: `${mapApiBaseUrl}url?product=surface-analysis&mask=Standard-Mercator` }),
      transformResponse: (response: { data: string }) => response.data,
    }),

    tropicalModels: builder.query<string, null>({
      query: () => ({ url: `${mapApiBaseUrl}url?product=tropical-spaghetti-plot-us&mask=Standard-Mercator` }),
      transformResponse: (response: { data: string }) => response.data,
    }),

    tropicalForecast: builder.query<string, null>({
      query: () => ({ url: `${mapApiBaseUrl}url?product=tropical-cone-forecast-us&mask=Standard-Mercator` }),
      transformResponse: (response: { data: string }) => response.data,
    }),

    alertsByPoint: builder.mutation<NWSMapAlert[], Coordinates>({
      query: (params) => ({
        url: `${mapApiBaseUrl}Alerts/ByPoint?latitude=${params.latitude}&longitude=${params.longitude}`,
      }),
      transformResponse: (response: { data: NWSMapAlert[] }) => response.data,
    }),

    // radar: builder.query<string, void>({
    //   query: () => ({url:`map/Radar?frame=19`}),
    //   transformResponse: (response: { data: string }) => response.data
    // }),

    radarAnimList: builder.query<string[], RadarParams>({
      query: (params) => ({
        url:
          params.product === ""
            ? `${mapApiBaseUrl}WMS?future=${params.isFuture}`
            : `${mapApiBaseUrl}WMS?product=${params.product}`,
      }),
      transformResponse: (response: { data: string[] }) => response.data,
    }),
    precipByPoint: builder.query<PrecipPointType, PrecipPointRequest>({
      // query: (params) => ({url:`Map/Point/?latitude=${params.latitude}&longitude=${params.longitude}`}),
      query: (params) => ({url:`Map/Point/?product=${params.product}&mask=${params.mask}&lat=${params.latitude}&lon=${params.longitude}`}),
      // query: (params) => ({url:`http://localhost:5001/Point/?product=${params.product}&mask=${params.mask}&lat=${params.latitude}&lon=${params.longitude}`}),
      // query: (params) => ({url:`https://trial-baron.perryweather.com/Point/?product=${params.product}&mask=${params.mask}&lat=${params.latitude}&lon=${params.longitude}`}),
      transformResponse: (response: { data: PrecipPointType }) => response.data
    }),
  }),
});


export const {
  useHardwareMapQuery,
  useWxsMapQuery,
  useLightningMapGeoJsonQuery,
  useStormVectorsGeoJsonQuery,
  useLightningMapQuery,
  useRadarQuery,
  useRadarAnimListQuery,
  useSurfaceAnalysisQuery,
  useTropicalModelsQuery,
  useTropicalForecastQuery,
  useAlertsByPointMutation,
  usePrecipByPointQuery
} = mapApi;
