import { api } from ".";

export interface WeatherStationAvg {
  minAmbientTemperature: number,
  avgAmbientTemperature: number,
  maxAmbientTemperature: number,
  avgWetBulbGlobalTemp?: number,
  maxWetBulbGlobalTemp?: number,
  avgWindSpeed: number,
  maxWindSpeed: number,
  avgWindGust: number,
  maxWindGust: number,
  maxRain1Hr: number,
  rainTotal: number,
  date: Date
}

export interface DateRange {
  start: Date,
  end: Date
}

export type CustomDate = Date | undefined | null;


const historicalsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    historicalForHourly: builder.query<WeatherStationAvg[], string>({
      query: (id) => ({ url: `Observations/Historical/Day/${id}` }),
      transformResponse: (response: { data: WeatherStationAvg[] }) => response.data
    }),
    historicalForWeek: builder.query<WeatherStationAvg[], string>({
      query: (id) => ({ url: `Observations/Historical/Week/${id}` }),
      transformResponse: (response: { data: WeatherStationAvg[] }) => response.data
    }),
    historicalForMonth: builder.query<WeatherStationAvg[], string>({
      query: (id) => ({ url: `Observations/Historical/Month/${id}` }),
      transformResponse: (response: { data: WeatherStationAvg[] }) => response.data
    }),
    historicalForCustom: builder.query<WeatherStationAvg[], { id: string, startDate: string, endDate: string }>({
      query: ({ id, startDate, endDate }) => ({ url: `Observations/Historical/Custom/${id}?startDate=${startDate}&endDate=${endDate}` }),
      transformResponse: (response: { data: WeatherStationAvg[] }) => response.data
    }),
    historical: builder.query<WeatherStationAvg[], {id: string, hours: number} >({
      query: ({id, hours}) => ({ url: `Observations/Historical/${id}?hours=${hours}`}),
      transformResponse: (response: { data: WeatherStationAvg[] }) => response.data
    }),
  })
})

export const {
  useHistoricalForHourlyQuery,
  useHistoricalForWeekQuery,
  useHistoricalForMonthQuery,
  useHistoricalForCustomQuery,
  useHistoricalQuery
} = historicalsApi;