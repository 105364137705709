import { LngLatBounds, Map } from "maplibre-gl";
import { Viewport } from './';

export interface BBox {
  top: number,
  bottom: number,
  left: number,
  right: number,
  zoom: number
}

export function getBBox(viewport: Viewport, mapRef: Map) {
    const bounds: LngLatBounds = mapRef.getBounds();
    const precision: number = 10000;
    const bbox: BBox = {
        bottom: Math.round(bounds.getSouth() * precision) / precision,
        top: Math.round(bounds.getNorth() *    precision) / precision,
        left: Math.round(bounds.getWest() *    precision) / precision,
        right: Math.round(bounds.getEast() *   precision) / precision,
        zoom: Math.round(viewport.zoom)
    };

    return bbox;
}



export function convertBBoxToAspectRatio(bbox: number[]) {
   const width = bbox[2]-bbox[0];
   const height = bbox[3]-bbox[1];
   return width/height;
 }