import { CSSProperties } from 'react';
import { _useMapControl as useMapControl } from "react-map-gl";
import { Box, Grow, Typography, useMediaQuery, useTheme } from '@mui/material';
import { LightningStrike } from '../../../../Assets';
import { PWColors } from '../../../../Theme/PWColors';
import BaseControl from './BaseControl';

interface LegendControlProps {
	onClick?: React.MouseEventHandler,
	show: boolean
}

export function LegendControl (props: LegendControlProps) {
	const {onClick, show} = props;

	const {containerRef} = useMapControl({
		onDragStart: (evt: any) => {
			evt.stopPropagation();
		},
		onDoubleClick: (evt: any) => {
			evt.stopPropagation();
		},
		onClick: (evt: any) => {
			evt.stopPropagation();
		},
	});

	const theme = useTheme();
	const isSm = useMediaQuery(theme.breakpoints.down('sm'));


	// essentially need position absolute if being used standalone, i.e, not inside the group.
	const style: CSSProperties = {
		right: 10,
		top: 10,
		position: 'absolute',
		backgroundColor: '#fff',
		borderRadius: 100
	};

	var controlClass: string = `mapbroxgl-ctrl mapboxgl-ctrl-group`;

	return (

		!isSm ?
			<div style={{...style, borderRadius: 100}} className={controlClass} ref={containerRef}>
				<Box alignItems="center" justifyContent="space-around" display="flex" flexDirection="column" paddingLeft={2} paddingRight={2} width={300} height={80}>
					<Box alignItems="center" justifyContent="space-around" display="flex" flexDirection="row" paddingLeft={2} paddingRight={2} width={300} height={40}>
						<img src="/hardware/blue_circle_pin.png" alt='b_circle_pin' style={{width: '24px'}}/>
						<Typography color={PWColors.light.base[900]}>WXS</Typography>
						<img src="/hardware/blue_square_pin.png" alt='b_circle_pin' style={{width: '24px'}}/>
						<Typography color={PWColors.light.base[900]}>OWS</Typography>
						<img src="/hardware/blue_diamond_pin.png" alt='b_circle_pin' style={{width: '24px'}}/>
						<Typography color={PWColors.light.base[900]}>OWX</Typography>
					</Box>
					<Box alignItems="center" justifyContent="space-around" display="flex" flexDirection="row" paddingLeft={2} paddingRight={2} width={300} height={40}>
						<div style={{backgroundColor: "#EB3223", width: 24, height: 12}}/>
						<Typography color={PWColors.light.base[900]}>DEG</Typography>
						<div style={{backgroundColor: "#FBE38C", width: 24, height: 12}}/>
						<Typography color={PWColors.light.base[900]}>PWR</Typography>
						<div style={{backgroundColor: "#0060A5", width: 24, height: 12}}/>
						<Typography color={PWColors.light.base[900]}>HLTH</Typography>
					</Box>
				</Box>
			</div>
			:
			<div style={{zIndex: 1, marginBottom:'10px'}}>
				<Grow in={show}>
					<div style={{...style, borderRadius: 8, zIndex: 1000}} className={controlClass} ref={containerRef} onClick={onClick}>
						<Box alignItems="left" justifyContent="space-around" display="flex" flexDirection="column" paddingLeft={1} paddingRight={1} width={120} height={300}>
							<Box width="100%" display="flex" justifyContent="space-around" flexDirection="row">
								<img src="/hardware/blue_circle_pin.png" alt='b_circle_pin' style={{width: '24px'}}/>
								<Typography color={PWColors.light.base[900]}>WXS</Typography>
							</Box>
							<Box width="100%" display="flex" justifyContent="space-around" flexDirection="row">
								<img src="/hardware/blue_square_pin.png" alt='b_circle_pin' style={{width: '24px'}}/>
						 		<Typography color={PWColors.light.base[900]}>OWS</Typography>
							</Box>
							<Box width="100%" display="flex" justifyContent="space-around" flexDirection="row">
								<img src="/hardware/blue_diamond_pin.png" alt='b_circle_pin' style={{width: '24px'}}/>
								<Typography color={PWColors.light.base[900]}>OWX</Typography>
							</Box>
							<Box width="100%" display="flex" justifyContent="space-around" flexDirection="row">
								<div style={{backgroundColor: "#EB3223", width: 24, height: 12}}/>
								<Typography color={PWColors.light.base[900]}>DEG</Typography>
							</Box>
							<Box width="100%" display="flex" justifyContent="space-around" flexDirection="row">
								<div style={{backgroundColor: "#FBE38C", width: 24, height: 12}}/>
								<Typography color={PWColors.light.base[900]}>PWR</Typography>
							</Box>
							<Box width="100%" display="flex" justifyContent="space-around" flexDirection="row">
								<div style={{backgroundColor: "#0060A5", width: 24, height: 12}}/>
								<Typography color={PWColors.light.base[900]}>HLTH</Typography>
							</Box>
						</Box>
					</div>
				</Grow>
				<BaseControl label="Legend" onClick={onClick}>
					<img src="/hardware/blue_diamond_pin.png" alt='b_circle_pin' style={{width: '24px'}}/>
				</BaseControl>
			</div>
	);
}

export default LegendControl;