import { Source, Layer, LayerProps } from 'react-map-gl';
import { RadarParams, useRadarQuery } from '../../../../Services/API';
import { RasterPaint, Map } from 'maplibre-gl';
import { useState, useEffect } from 'react';
import { getOpacity, getOverlays } from '../../../../features/map/mapSlice';
import { useAppSelector } from '../../../../app/hooks';
import { Viewport } from '../Util';


interface AlertLayerProps {
	hasLocation?: boolean,
	viewport: Viewport,
	mapRef: Map
}

// function project(latitude: number, longitude: number) {
// 	const MAX_LAT = 85.0511287798;
// 	var d = Math.PI / 180.0;
// 	var lat = Math.max(Math.min(MAX_LAT, latitude), -MAX_LAT);
// 	var x = longitude * d;
// 	var y = lat * d;

// 	y = Math.log(Math.tan(Math.PI / 4 + y / 2));
// 	const obj = {
// 	x: x * 6378137,
// 		y: y * 6378137,
// 		};
// 		return obj;
// }



export function NWSAlertLayer (props: AlertLayerProps) {

	// const radarPlaying = useAppSelector(getRadarPlaying);
	// const animSpeed = useAppSelector(getAnimSpeed);
	const opacity = useAppSelector(getOpacity);
	const chosenLayers = useAppSelector(getOverlays);

				// {mapRef.current && chosenLayers.includes('alert') ? <AlertLayer viewport={viewport} mapRef={mapRef.current?.getMap()} /> : <></> }
	// const progress = useAppSelector(getProgress);
	// const dispatch = useAppDispatch();


	const [layerParams] = useState<RadarParams>( {type: 'Alert', isFuture: false, frame:0});


	// const radarParams: RadarParams = {
	// 	type: 'Radar',
	// 	isFuture: false,
	// 	frame: 19
	// }

	const { data } = useRadarQuery(layerParams, { pollingInterval: 1 * 60000, refetchOnFocus: true, refetchOnReconnect: true });
	const { mapRef, hasLocation } = props;

	// const [currentUrl, setCurrentUrl] = useState<string>('');
	// const [coordinates, setCoordinates] = useState<number[][]>(getCoordinates());

	// const radarStyle = useAppSelector(getRadarStyle);

	// useEffect( () => {
	// 	if (radarStyle === 'past')
	// 		setRadarParams ({type: 'Radar', isFuture: false, frame: 19});
	// 	else if (radarStyle === 'future')
	// 		setRadarParams ({type: 'Radar', isFuture: true, frame: 0});
	// }, [radarStyle]);

	// var source: Source;

	// useEffect(() => {
	// 	currentImage = progress / 100 % 20;
	// }, [progress]);

	useEffect ( () => {
		// let source: RasterSource = mapRef.getSource('radar') as RasterSource;
		let source: any = mapRef.getSource('alert');
		if (data && source) {
			source.tiles=[data!]
			source.url=data!;

			// console.debug(mapRef);
			let mapAny: any = mapRef as any;
			mapAny.getSource('alert').tiles = [ data! ]
			mapAny.style.sourceCaches['alert'].clearTiles()
			// // Load the new tiles for the current viewport (map.transform -> viewport)
			mapAny.style.sourceCaches['alert'].update(mapAny.transform)
			mapAny.triggerRepaint();
		}
		// console.debug(source);
	// 	source.url=data;
	// 	// mapRef.getMap().getSource(sourceId)
	}, [data, mapRef]);

	/// Should implemenet aspect ratio correction.
	// function getCoordinates(): number[][] {
	// 	if (mapRef) {
	// 		let bounds = mapRef.getBounds();
	// 		return [
	// 			[bounds.getNorthWest().lng, bounds.getNorthWest().lat],
	// 			[bounds.getNorthEast().lng, bounds.getNorthEast().lat],
	// 			[bounds.getSouthEast().lng, bounds.getSouthEast().lat],
	// 			[bounds.getSouthWest().lng, bounds.getSouthWest().lat],
	// 		];
	// 	}
	// 	else return [
	// 		[0.0, 0.0],
	// 		[0.0, 0.0],
	// 		[0.0, 0.0],
	// 		[0.0, 0.0]
	// 	];
	// }


	const paints: RasterPaint = {
		'raster-opacity': opacity,
	};

	const layerStyle: LayerProps = {
		id: 'alert',
		type: 'raster',
		minzoom: 0,
		maxzoom: 14,
		paint: paints,
	};


	var alertsBase = hasLocation ? (<Layer beforeId="radii-1" {...layerStyle} />) : (<Layer beforeId="admin_sub" {...layerStyle} />);
	// var alertsWhenNotAnimating = !radarPlaying ? radarRender : (<></>);
	var alertsToRender = chosenLayers.includes('radar') ? (<Layer beforeId='radar' {...layerStyle}/>) : (alertsBase) ;
	const url: string = data ? data : 'https://st3.depositphotos.com/10839834/32287/v/950/depositphotos_322878930-stock-illustration-transparent-checkered-chess-background.jpg';

	return (
		<>
			<Source  id="alert" type="raster" tileSize={256} tiles={ [ url ] } scheme="tms">
				{/* {!radarPlaying ? ( hasLocation ? <Layer beforeId="radii-1" {...layerStyle} /> : <Layer beforeId="admin_sub" {...layerStyle} /> ) : (<></>)} */}
				{alertsToRender}
			</Source>
		</>
	);
}

export default NWSAlertLayer;