import { Tooltip as MuiTooltip, TooltipProps as MuiTooltipProps, tooltipClasses, styled } from "@mui/material";
import { PWColors } from "../../Theme/PWColors";

interface TooltipProps extends MuiTooltipProps{

}

export const CustomTooltip = styled(({ ...props }: TooltipProps) => (
    <MuiTooltip {...props} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: PWColors.dark.base[300],
        fontSize: 14
    }
}))



export function Tooltip(props: TooltipProps){
    const {children} = props;

    return (<CustomTooltip {...props}>{children}</CustomTooltip>);
}

export default Tooltip;
