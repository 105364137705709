import { PWColors } from '../../../../Theme/PWColors';
import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { getRadarStyle, radarTypes, selectRadarStyle } from '../../../../features/map/mapSlice';
import { Box, styled, ToggleButton, ToggleButtonGroup, Typography, Zoom } from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight, KeyboardDoubleArrowRight} from '@mui/icons-material';


const StyledToggleButton = styled(ToggleButton)(() => ({
	border: '1px',
    borderRadius:'8px',
	padding: 8,
	'&:hover': {
		backgroundColor: '#E9E9E9'
	}
}))


interface RadarSelectControlProps {
	onClick?: React.MouseEventHandler,
	show: boolean
}
export function RadarSelectControl (props: RadarSelectControlProps) {
	const {onClick, show} = props;

	const radarStyle = useAppSelector(getRadarStyle);
	const dispatch = useAppDispatch();

	const handleRadarChange = (event: React.MouseEvent<HTMLElement>, newRadarStyle: string) => {
		if (newRadarStyle !== null)
			dispatch(selectRadarStyle(newRadarStyle as radarTypes));
	};


	return (
		<div style={{position: 'absolute', marginLeft: -15, bottom: 50}}>
			<div onClick={onClick} >
				<Box style={{backgroundColor: '#fff'}} className="radarSelectBox" minWidth={75}  border={"1px solid"} borderRadius={2} zIndex={1}>
					<Typography>{radarStyle}</Typography>
				</Box>
				<Box>
					<Zoom in={show}>
						<div className="overlayMenu left">
							<ToggleButtonGroup
								color="secondary"
								exclusive
								orientation="vertical"
								value={radarStyle as string}
								onChange={handleRadarChange}
							>
								<StyledToggleButton  value="past">
									<Typography variant="body2" color={PWColors.dark.base['900']}>Past</Typography>
									<KeyboardArrowLeft color="secondary"/>
								</StyledToggleButton>
								<StyledToggleButton value="future">
									<Typography variant="body2" color={PWColors.dark.base['900']}>Future</Typography>
									<KeyboardArrowRight color="secondary"/>
								</StyledToggleButton>
								<StyledToggleButton value="extended">
									<Typography variant="body2" color={PWColors.dark.base['900']}>Extended</Typography>
									{/* <KeyboardArrowRight color="secondary"/> */}
									<KeyboardDoubleArrowRight color="secondary"/>
								</StyledToggleButton>
							</ToggleButtonGroup>
						</div>
					</Zoom>
				</Box>
			</div>
		</div>

	);
}

export default RadarSelectControl;