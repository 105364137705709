import { Box, Typography } from "@mui/material";
import { Popup } from "react-map-gl";
import { DateTimeFormatter, formatTimeStringFromMins } from "../../../../features/Time/TimeHelpers";

interface HardwarePopupViewProps {
  clickedInfo: any;
}

const openInNewTab = (url: string) => {
  window.open(url, "_blank", "noopener,noreferrer");
};

export function HardwarePopupView(props: HardwarePopupViewProps) {
  const { clickedInfo } = props;

  let hardwaresInfo = clickedInfo.feature.map((x: any) => {
    if (x.properties.ConnectedAt) {
      let hwDt =
        x.properties.hwAge !== "null"
          ? DateTimeFormatter(new Date(Date.now() - x.properties.hwAge * 1000).toString())
          : "";
      let wxDt =
        x.properties.wxAge !== "null"
          ? DateTimeFormatter(new Date(Date.now() - x.properties.wxAge * 1000).toString())
          : "";
      let link = "https://sa.perryweather.com/hardware/details/" + x.properties.Id;

      return (
        <>
          <Box onClick={() => openInNewTab(link)} textAlign="left" display="flex" flexDirection="column">
            <Typography variant="subtitle1">Name: {x.properties.Name}</Typography>
            <Typography variant="caption">Customer: {x.properties.CustomerName}</Typography>
            {/* <Typography variant='caption'>HW Updated: {dt} ({formatTimeStringFromMins(lastConnectedMins)})</Typography> */}
            <Typography variant="caption">
              HW Updated: {hwDt} ({formatTimeStringFromMins(x.properties.hwAge / 60)})
            </Typography>
            {x.properties.wxAge !== "null" && (
              <Typography variant="caption">
                WX Updated: {wxDt} ({formatTimeStringFromMins(x.properties.wxAge / 60)})
              </Typography>
            )}
            <Typography variant="caption">
              Voltage: {typeof x.properties.Voltage === "number" ? x.properties.Voltage.toFixed(2) : "--"}V
            </Typography>

            <>
              <br />
              <Typography variant="caption">
                Notes: {x.properties.Notes !== "null" ? x.properties.Notes : <i>No notes</i>}
              </Typography>
              <br />
            </>

            {x.properties.hwAge > 900 && (
              <div style={{ lineHeight: ".5em" }}>
                <div
                  style={{ height: 8, width: 8, backgroundColor: "#ff0000", display: "inline-block", marginRight: 3 }}
                ></div>
                <span>
                  <Typography variant="caption">
                    <i>Unhealthy</i>
                  </Typography>
                </span>
              </div>
            )}

            {x.properties.Voltage < 12 && (
              <div style={{ lineHeight: ".5em" }}>
                <div
                  style={{ height: 8, width: 8, backgroundColor: "#ffe37e", display: "inline-block", marginRight: 3 }}
                ></div>
                <span>
                  <Typography variant="caption">
                    <i>Low voltage</i>
                  </Typography>
                </span>
              </div>
            )}

            {x.properties.FlaggedByHardwareTeam && (
              <div style={{ lineHeight: ".5em" }}>
                <div
                  style={{ height: 8, width: 8, backgroundColor: "#7d46eb", display: "inline-block", marginRight: 3 }}
                ></div>
                <span>
                  <Typography variant="caption">
                    <i>Flagged by the hardware team</i>
                  </Typography>
                </span>
              </div>
            )}
          </Box>
          <hr />
        </>
      );
    }
  });

  return (
    <Popup captureScroll={true} longitude={clickedInfo.lngLat.lng} latitude={clickedInfo.lngLat.lat} anchor="bottom">
      <Box maxHeight={500} overflow="scroll">
        {hardwaresInfo}
      </Box>
    </Popup>
  );
}

export default HardwarePopupView;
