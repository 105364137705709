import { useTheme } from '@mui/styles';
import { animated, useSpring } from 'react-spring'

interface VectorComponentProps {
    value: number;
    direction?: number;
    max: number;
    min: number;
    color?: string;
}

export function VectorComponent(props: VectorComponentProps) {
    let direction = props.direction ? props.direction + 180 : 0; // Fix direction to same as Mobile app
    const theme = useTheme();
    const animatedStyle = useSpring({
        from: {
            transform: "rotate(0deg)",
        },
        to: {
            transform: `rotate(${direction}deg)`,
        },
        config: { duration: 500 }
    });


    return (
        <animated.svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" style={animatedStyle}>
            <path d="M17.0711 2.92893C13.1742 -0.967933 6.8258 -0.967933 2.92893 2.92893C-0.967933 6.8258 -0.967933 13.1742 2.92893 17.0711C6.8258 20.9679 13.1742 20.9679 17.0711 17.0711C20.9679 13.1742 20.9679 6.8258 17.0711 2.92893ZM15.6569 4.34315C18.7894 7.4757 18.7894 12.5243 15.6569 15.6569C12.5243 18.7894 7.4757 18.7894 4.34315 15.6569C1.21058 12.5243 1.21059 7.4757 4.34315 4.34315C7.47571 1.21058 12.5243 1.21058 15.6569 4.34315ZM10 4.34315L6.00046 8.34268L9.0001 8.34272L9.00008 14.5465H10.9999L10.9999 8.34272L13.9995 8.34268L10 4.34315Z"
                fill={props.color ? props.color : theme.palette.text.primary} />
        </animated.svg>
    );
}

export default VectorComponent;