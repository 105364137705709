import { Box, IconButton, Modal, styled, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { ModalState } from "../Base";

const Image = styled('img')({
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: '8px'
});

interface WxImageModalProps {
    modalState: ModalState,
    src?: string,
}

export function WxImageModal(props: WxImageModalProps){
    const { modalState, src }  = props;
    const theme = useTheme();
    const [imgSrc, setImgSrc] = useState<string | undefined>(src);

    useEffect(()=>{
        setImgSrc(src)
    }, [src])

    return(
        <Modal open={modalState.open} onClose={(e,r) => modalState.handleClose()}
            sx={{display: 'flex', alignItems: 'center', justifyContent: 'center',
                '& :focus': {
                    outline: 'none !important'
                }}}>
            <Box sx={{position: 'relative', width: '80%', height: '80%'}}>
                <IconButton sx={{position:'absolute', top: 5, right: 10, width: 32, height: 32}} onClick={() => modalState.handleClose()}>
                    <Typography variant='h5' color={theme.palette.primary.main}>X</Typography>
                </IconButton>
                <Image src={imgSrc} alt={''} onError={(e) => { setImgSrc(undefined) }}/>
            </Box>
        </Modal>
    );
}

export default WxImageModal;