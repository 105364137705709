/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react';
import { Editor, DrawLineStringMode, RENDER_STATE } from 'react-map-gl-draw';
import { FeatureOf, LineString } from '@nebula.gl/edit-modes/dist-types/geojson-types'
import { Popup } from 'react-map-gl';
import { LngLat } from 'maplibre-gl';
import { getDistance } from '../../../features/map/MapHelpers';

interface FeatureStyleProps {
    feature: any,
    state: any
}

export function MapEditor () {

    const [selected, setSelected] = useState<FeatureOf<LineString> | undefined>(undefined);
    const [lineLength, setLineLength] = useState<number>(0);

    useEffect(() => {
        getLineLength();
    },[selected])

    const ref:React.LegacyRef<Editor>  = useRef<Editor>() as React.MutableRefObject<Editor>;

    const handleSelect = (event: any) => {
        let selectedFeatureId = ref.current?._getSelectedFeatureIndex();
        let features: FeatureOf<LineString>[] = ref.current?.getFeatures();

        if(features !== undefined && selectedFeatureId !== undefined)
            setSelected(features[selectedFeatureId]);
    }

    const getLineLength = () => {
        if(selected){
            let disArray:number[] = [];
            let totalDis:number = 0;

            for (let i = 0; i < selected.geometry.coordinates.length -1; i++) {
                let coord1 = selected.geometry.coordinates[i];
                let coord2 = selected.geometry.coordinates[i+1];
                disArray.push(getDistance(new LngLat(coord1[0], coord1[1]), new LngLat(coord2[0], coord2[1])))
            }

            totalDis = disArray.reduce((a, b) => a + b, 0)

            setLineLength(totalDis);
        }else
            return 0;
    }

    function getEditHandleStyle(props: FeatureStyleProps) {
        switch (props.state) {
          case RENDER_STATE.SELECTED:
          case RENDER_STATE.HOVERED:
          case RENDER_STATE.UNCOMMITTED:
            return {
              fill: 'orange',
              fillOpacity: 1,
              stroke: 'white',
              strokeWidth: 2,
              r: 10,
            }

          default:
            return {
              fill: 'orange',
              fillOpacity: 1,
              stroke: 'white',
              strokeWidth: 2,
              r: 5,
            }
        }
      }

    function getFeatureStyle(props: FeatureStyleProps) {
        switch (props.state) {
          case RENDER_STATE.SELECTED:
          case RENDER_STATE.HOVERED:
          case RENDER_STATE.UNCOMMITTED:
            return {
              fill: 'none',
              stroke: 'orange',
              strokeWidth: 5
            }

          default:
            return {
              fill: 'none',
              stroke: 'orange',
              strokeWidth: 3,
              strokeDasharray: '4,2'
            }
        }
      }

    return (
        <>
            {selected &&
            <Popup latitude={selected.geometry.coordinates[0][1]} longitude={selected.geometry.coordinates[0][0]}>
                <div>
                    <p>
                        {'Total Distance: ' + lineLength.toString() + ' mi'}
                    </p>
                </div>
            </Popup>
            }
            <Editor
                featureStyle={getFeatureStyle}
                editHandleShape={'circle'}
                editHandleStyle={getEditHandleStyle}
                ref={ref}
                clickRadius={18}
                mode={new DrawLineStringMode()}
                selectable={true}
                onSelect={handleSelect}
            />
        </>
    )
}