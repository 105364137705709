import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

export interface MenuState {
  open: boolean;
  status: 'idle' | 'loading' | 'failed';
}

const initialState: MenuState = {
  open: false,
  status: 'idle',
};

export const menuSlice = createSlice({
  name: 'menu',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    toggle: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.open = !state.open;
    },
  },
});

export const { toggle } = menuSlice.actions;

export const selectMenu = (state: RootState) => state.menu.open;


export default menuSlice.reducer;
