import { api } from ".";

export interface User{
    id: string,
    userName: string,
    firstName: string,
    lastName: string,
    fullName: string,
    email: string | null,
    roleDisplayName?: string,
    roleHierarchy: number,
    roleName: string,
    roleId: string,
    siteId: number,
    siteName: string,
    phoneNumber: string | null,
    customerName?: string,
    customerId: number,
    timeZone: string,
    isActive?: boolean,
    smsNotifications?: boolean,
    emailNotifications?: boolean,
    orgLocations?: string[]
  };

  export interface Role{
    id: string,
    name: string,
    hierarchy: number,
    displayName?: string
  }
  
  export interface UserErrorModel{
      Email?: string[],
      RoleId?: string[],
      FirstName?: string[],
      UserName?: string[],
      TimeZone?: string[],
      SiteId?: string[],
      PhoneNumber?: string[],
      ServerError?: string[]
  }

const userApi = api.injectEndpoints({
    endpoints: (builder) => ({
        users: builder.query<User[], void>({
            query: () => 'user/customer',
            transformResponse: (response: { data: User[] }) => response.data,
            providesTags: ["Users"]
          }),
          user: builder.query<User, void>({
            query: () => 'user/current',
            transformResponse: (response: { data: User }) => response.data,
            providesTags: ["Users"]
          }),
          roles: builder.query<Role[], void>({
            query: () => 'user/roles',
            transformResponse: (response: { data: Role[] }) => response.data
          }),
          usersByRole: builder.query<User[], string[]>({
            query: (roles) => ({
              url: 'user/roles',
              method: 'POST',
              body: roles
            }),
            transformResponse: (response: { data: User[] }) => response.data,
            providesTags: ["Users"]
          }),
          updateUser: builder.mutation<string, User>({
            query: (updatedUser) => ({
              url: 'user/update',
              method: 'POST',
              body: updatedUser
            }),
            transformResponse: (response: { data: string}) => response.data,
            invalidatesTags: ["Users"]
          }),
          createUser: builder.mutation<{message: string, user: User}, User>({
            query: (newUser) => ({
              headers: {'ReturnUser': ''},
              url: 'user',
              method: 'POST',
              body: newUser
            }),
            transformResponse: (response: { data: {message: string, user: User}}) => response.data,
            invalidatesTags: ["Users"]
          }),
          deleteUser: builder.mutation<string, string>({
            query: (userId) => ({
              url: `user/${userId}`,
              method: 'DELETE'
            }),
            transformResponse: (response: { data: string}) => response.data,
            invalidatesTags: ["Users"]
          }),
          updateProfile: builder.mutation<string, User>({
            query: (profile) => ({
              url: `user/profile`,
              method: 'POST',
              body: profile
            }),
            transformResponse: (response: { data: string}) => response.data,
            invalidatesTags: ["Users"]
          })
    })
})

export const {
    useUsersQuery,
    useUserQuery,
    useUsersByRoleQuery,
    useRolesQuery,
    useUpdateUserMutation,
    useCreateUserMutation,
    useDeleteUserMutation,
    useUpdateProfileMutation
} = userApi;