import { BreakpointsOptions } from "@mui/material";

export const breakpointOptions: BreakpointsOptions = {
    values: {
      xs: 0,
      sm: 600,
      md: 1025,
      lg: 1200,
      xl: 1536
    }
}