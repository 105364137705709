import { api } from ".";

export interface Threat {
    threatType: string,
    threatDisplayName: string,
    threatLevel: number,
    threshold: number,
    unit: string,
    precision: number,
    isDesc: boolean,
    threatDescription: string,
    threatTime: Date,
    threatTimes: ThreatInterval[]
  }

  export interface ThreatInterval {
    startTime: Date,
    endTime: Date
  }

const threatsApi = api.injectEndpoints({
    endpoints: (builder) => ({
        threats: builder.query<Threat[], string>({
            query: (locationId) => ({url:`Threats/${locationId}`}),
            transformResponse: (response: { data: Threat[] }) => response.data
          })
    })
})

export const {
    useThreatsQuery
} = threatsApi;