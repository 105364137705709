import { BaseModal, ModalTooltip } from './BaseModal';
import Button from './Button'
import { Grid, Box, useTheme, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { DeleteOutline } from '@mui/icons-material';
import React from 'react';

interface DeleteDialogProps{
    item: string,
    identifier?: string,
    button?: JSX.Element,
    disabled?: boolean,
    onRemove?: React.MouseEventHandler,
    iconColor?: string,
    children?: JSX.Element,
    tooltip?: ModalTooltip
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header:{
        [theme.breakpoints.down('sm')]:{

        }
    },
    redCircle: {
      backgroundColor: theme.palette.error.main,
	  borderRadius: '50%',
      height: 40,
      width: 40
    }
}));

export function DeletionDialog(props: DeleteDialogProps)
{
    const theme = useTheme();
    const classes = useStyles(theme);
    const {item, button, disabled, iconColor, onRemove, identifier, children, tooltip} = props;

    const [dialogOpen, setDialogOpen] = React.useState(false);

      const handleClose = () => {
        setDialogOpen(false);
      };

      const handleOpen = () => {
        setDialogOpen(true);
      };

      const handleRemove = (e: React.MouseEvent<HTMLButtonElement>) => {
        if(onRemove)
            onRemove(e)
        handleClose()
      };

      const DeleteModal = (props: {children: React.ReactNode}) => {
          if(button)
          return(
             <BaseModal tooltip={tooltip} disabled={disabled} modalState={{open: dialogOpen, handleOpen: handleOpen, handleClose: handleClose}} modalButton={button}>
                {props.children}
            </BaseModal>
          )
          else
          return(
            <BaseModal tooltip={tooltip} disabled={disabled} modalState={{open: dialogOpen, handleOpen: handleOpen, handleClose: handleClose}} modalIcon={<DeleteOutline htmlColor={disabled ? theme.palette.action.disabled : iconColor}/>}>
                {props.children}
            </BaseModal>
          )
      }

    return(
        <DeleteModal>
            <Grid container>
                <Grid container item style={{marginBottom: 8}}>
                    <Grid item md={2} style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                        <Box display='flex' justifyContent='center' width='100%'>
                            <Box component='span' display='flex' flexDirection='column' justifyContent='center' className={classes.redCircle}>
                                <Box display='flex' justifyContent='center' width='100%'>
                                    <DeleteOutline />
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item md={10} xs={12}>
                        <Typography variant='h5'>Remove {item}</Typography>
                    </Grid>
                </Grid>
                <Grid container item style={{marginBottom: 16}}>
                    <Grid item md={2}/>
                    <Grid item md={10}>
                        {(identifier && !children) &&
                        <Typography variant='body1'>
                            Are you sure you want to remove {identifier}? This action cannot be undone.
                        </Typography>
                        }
                        {children &&
                            children
                        }
                    </Grid>
                </Grid>
                <Grid container item>
                    <Grid item md={6} xs={false}/>
                    <Grid item container spacing={1} md={6} xs={12}>
                        <Grid item xs={6}>
                            <Button type='clear' onClick={handleClose}>
                                <Typography variant='body1'>
                                    Cancel
                                </Typography>
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button type='delete' onClick={handleRemove}>
                                <Typography variant='body1'>
                                    Remove
                                </Typography>
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </DeleteModal>
    );
}