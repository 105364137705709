import { DataGrid as MUIDataGrid, GridApi, GridColDef, GridRowId, useGridSlotComponentProps } from '@mui/x-data-grid';
import { useTheme, Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import {Pagination, Select} from '../Base';
import { Box, Grid } from '@mui/material';
import {  Skeleton } from '@mui/material';
import { useState } from 'react';


//create custom data table
//takes rows and defines columns from row keys
//add custom prop to provide omitted columns
//add custom prop to size the columns evenly, auto, align left, align right

export interface RowMenuProps{
    api: GridApi,
    id: GridRowId
}

interface TableProps {
    cols: GridColDef[],
    rows: any[],
    rowHeight: number,
    rowSpace?: number,
    hasSearchBox?: boolean,
    rowsPerPageOptions?: number[],
    onSelectionModelChange?: any,
    loading?: boolean,
    checkboxSelection?: boolean
}

interface TableStyleProps {
    theme: Theme,
    dataConatinerHeight: number,
    rowContainerHeight: number,
    tableHeight: number,
    rowSpace: number
}

const useStyles = makeStyles( {
    table: (props: TableStyleProps) =>({
            backgroundColor: 'transparent',
            borderWidth: 0,
            height: props.tableHeight,

            '& .MuiDataGrid-row': {
                width: 'inherit',
                color: props.theme.palette.text.primary,
                backgroundColor: props.theme.palette.background.paper,
                borderRadius: 8,
                marginBottom: props.rowSpace,
                border: '1px solid',
                borderColor: props.theme.palette.border.input,
                boxSizing: 'border-box',
                '&:hover': {
                  backgroundColor: props.theme.palette.action.hoverSecondary
                },
                '&.Mui-selected': {
                    backgroundColor: props.theme.palette.background.default,
                    '&:hover': {
                        backgroundColor: props.theme.palette.background.default
                      },
                },
              },
            '& .MuiDataGrid-main': {
                minHeight: `${props.rowContainerHeight}px !important`,
                '& .MuiCheckbox-root svg': {
                    color: props.theme.palette.action.inactive,
                },
                [props.theme.breakpoints.down('md')]: {
                    width: 1280
                },
                margin: '0px 8px 0px 8px',
                '& .Mui-checked svg': {
                    fill: props.theme.palette.action.selected
                },
                '& .MuiDataGrid-columnHeader:focus':{
                    outline: 'none'
                },
                '& .MuiDataGrid-cell':{
                    borderBottomWidth: 0,
                    '&:focus-within': {
                        outline: 'none'
                    }
                }
            },
            '& .MuiDataGrid-columnSeparator':{
                display: 'none'
            },
            '& .MuiDataGrid-columnsContainer':{
                borderBottom: 0,
                paddingTop: 18,
                '& .MuiDataGrid-columnHeaderTitleContainer':{
                    padding : 0,
                    '& .MuiDataGrid-columnHeaderTitle':
                    {
                        color: props.theme.palette.text.primary
                    },
                    '& .MuiDataGrid-iconButtonContainer .MuiButtonBase-root':
                    {
                        color: props.theme.palette.action.hover
                    }
                }
            },
            '& .MuiDataGrid-menuIcon .MuiButtonBase-root':{
                color: props.theme.palette.action.hover
            },
            '& .MuiDataGrid-windowContainer':{
                height: `${props.rowContainerHeight}px !important`,
                maxHeight: `${props.rowContainerHeight}px !important`,
                '& .MuiDataGrid-window, .MuiDataGrid-dataContainer, .MuiDataGrid-viewport, .MuiDataGrid-renderingZone':{
                    height: `${props.dataConatinerHeight}px !important`,
                    maxHeight: `${props.dataConatinerHeight}px !important`
                }
            }
        })
    });

export default function Table (props: TableProps) {
	const { cols, rows, rowHeight, rowSpace, rowsPerPageOptions=[25,50,100], onSelectionModelChange, loading, checkboxSelection} = props;
    const theme = useTheme();
    const [pageSize, setPageSize] = useState(rowsPerPageOptions[0]);
    let rowTotalHeight = rowHeight+(rowSpace||0);

    let styleProps: TableStyleProps = {
        theme: theme,
        dataConatinerHeight: rowTotalHeight*pageSize,
        rowSpace: rowSpace||0,
        tableHeight: rowTotalHeight*(pageSize+2),
        rowContainerHeight: rowTotalHeight*(pageSize+1)
    };

    const classes = useStyles(styleProps);

    function loadingOverlay() {
        let skeleRows: JSX.Element[] = [];

        for(let i = 0; i < pageSize; i++)
            skeleRows.push(<Skeleton key={i} animation='wave' variant="rectangular" width='100%' height={rowHeight} style={{borderRadius: 8, marginBottom: rowSpace}}/>);

        return (
            <Grid container style={{marginTop: rowHeight}}>
                {skeleRows}
            </Grid>
        );
    };

    function CustomPagination() {
        const { state, apiRef } = useGridSlotComponentProps();

        return (
            <Grid container justifyContent='center' style={{display: 'flex'}}>
                {rowsPerPageOptions.length > 1 &&
                    <Grid item md={4}/>
                }
                <Grid item md={4} display='flex' justifyContent='center'>
                    <Pagination
                        count={state.pagination.pageCount}
                        page={state.pagination.page + 1}
                        onChange={(event, value) => apiRef.current.setPage(value - 1)}
                    />
                </Grid>
                {rowsPerPageOptions.length > 1 &&
                <Grid item xs={12} md={4} display='flex' justifyContent='flex-end'>
                    <Box width='30%'>
                        <Select options={rowsPerPageOptions.map(op => ({value: op, text: op.toString()}))} value={pageSize}
                            onChange={(v) => {apiRef.current.setPageSize(parseInt(v))}} />
                    </Box>
                </Grid>
                }
            </Grid>
        );
    }

    return (
        <Box component='div' width='100%' overflow='auto'>
            <MUIDataGrid
            loading={loading}
            className = {classes.table}
            columns = {cols}
            rows = {rows}
            disableSelectionOnClick
            checkboxSelection={checkboxSelection}
            rowHeight={rowHeight}
            onPageSizeChange={(pageSize) => setPageSize(pageSize)}
            pageSize = {pageSize}
            hideFooterSelectedRowCount
            onSelectionModelChange={onSelectionModelChange}
            rowsPerPageOptions={rowsPerPageOptions}
            components = {{
                Pagination: CustomPagination,
                LoadingOverlay: loadingOverlay
                }}
            />
        </Box>
    );
}