import { Coordinates } from '../../features/map/MapHelpers';
import { AirQuality } from './airQualityApi';
import { api, WeatherCode, WeatherModel } from './api';

export interface WeatherStationInfo {
    id?: string,
    name?: string,
    lastUpdated?: Date,
    type: number,
}

export interface MetarStationInfo {
    id?: string,
    name?: string,
    lastUpdated?: Date
}

export interface CurrentConditions {
    ambientTemperature?: WeatherModel,
    feelLike?: WeatherModel,
    precipitation?: WeatherModel,
    wgbt?: WeatherModel,
    windSpeed?: WeatherModel,
    windDirection: string,
    windGust?: WeatherModel,
    observationTime?: string,
    weather_code?: WeatherCode
}

export interface Observation {
    data?: CurrentConditions,
    weatherStation?: WeatherStationInfo,
    metarStation?: MetarStationInfo,
    airQuality?: AirQuality,
    type: number
}

export interface PolicyAlertModel {
    startedTime: Date,
    lastUpdateTime: Date,
    lastAlertTime: Date,
    allClearTime: Date,
    triggerId: string,
    value?: number,
    thresholdValue: number,
    ccSource: string,
    allClearMinutes: number,
    stationId: string,
    stationName: string,
    policyShortName: string,
    policyDisplayName: string,
    message: string,
    isDesc: boolean,
    unit: string,
    precision: number
}

const locationApi = api.injectEndpoints({
    endpoints: (builder) => ({
        observationsForLocation: builder.query<Observation, string>({
            query: (id) => ({url:`observations/ForLocation/${id}`}),
            transformResponse: (response: { data: Observation }) => response.data
        }),
        observations: builder.mutation<Observation, {coord: Coordinates, preferred: boolean}>({
            query: ({coord, preferred}) => ({url:`observations?latitude=${coord.latitude}&longitude=${coord.longitude}&preferred=${preferred}`}),
            transformResponse: (response: { data: Observation }) => response.data
        }),
        cachedPolicyAlertsForLocation: builder.query<PolicyAlertModel[], string>({
            query: (id) => ({url:`observations/Violations/${id}`}),
            transformResponse: (response: { data: PolicyAlertModel[] }) => response.data
        })
    })
});

export const {
    useObservationsForLocationQuery,
    useObservationsMutation,
    useCachedPolicyAlertsForLocationQuery
} = locationApi;