import {useState } from 'react';
import { animated, useSpring } from 'react-spring'
import { useTheme } from '@mui/material';


interface ProgressHalfCircleProps {
    value: number;
    max: number;
    min: number;
    color?: string;
}

export function ProgressHalfCircleComponent(props: ProgressHalfCircleProps) {
    const [value] = useState(props.value)
    const theme = useTheme();

    var currentValue = value;
    if(value <= props.min)
    {
        currentValue = props.min;
    }
    else if(value >= props.max)
    {
        currentValue = props.max;
    }

    var progressPercentage = (currentValue - props.min) / (props.max - props.min)
    var percentageInDeg = Math.round(progressPercentage * 170) + 5;
    var percentageReverseInDeg = -180 + Math.round(progressPercentage * 180);

    const animatedStyle= useSpring({
        from: {
            transform: "rotate(5deg)",
            transformOrigin: "8px 16px",
        },
        to: {
            transform: `rotate(${percentageInDeg}deg)`,
            transformOrigin: "8px 16px",
        },
        config: { duration: 1000 }
      });

      const animatedStyle2= useSpring({
        from: {
            transform: "rotate(-180deg)",
            transformOrigin: "16.5px 16.5px",
        },
        to: {
            transform: `rotate(${percentageReverseInDeg}deg)`,
            transformOrigin: "16.5px 16.5px",
        },
        config: { duration: 1000 }
      });




    return(
        <div style={{height:20, justifyContent:"center", display:"flex", alignItems:"flex-end"}}>
            <div style={{position:"absolute",display:"flex"}}>
                <svg width="33" height="17" viewBox="0 0 33 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M32 16C32 7.71573 25.0604 1 16.5 1C7.93959 1 1 7.71573 1 16" stroke={theme.palette.action.disabled} strokeWidth="2"/>
                </svg>
            </div>
            <div style={{position:"absolute", display:"flex"}}>
                <animated.svg width="33" height="17" viewBox="0 0 33 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <animated.path d="M32 16C32 7.71573 25.0604 1 16.5 1C7.93959 1 1 7.71573 1 16" stroke={props.color? props.color :theme.palette.success.main}
                        strokeWidth="2" style={animatedStyle2} />
                </animated.svg>
            </div>

            <div style={{position:"absolute", display:"flex"}}>
                <animated.svg width="45" height="28" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" style={{overflow: "visible"}}>
                    <animated.circle cx="-0.5" cy="16" r="2" fill={props.color? props.color :theme.palette.success.main} style={animatedStyle} />
                </animated.svg>
            </div>

        </div>

    );
}

export default ProgressHalfCircleComponent;