import { Modal, Theme, useTheme, IconButton, Typography, Box } from '@mui/material';
import { Tooltip } from '../Base';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, { forwardRef } from 'react';

export interface ModalState{
    open: boolean,
    handleOpen: React.MouseEventHandler,
    handleClose: () => void
}

export interface ModalTooltip {
    title: string,
    disableFocusListener?: boolean,
    disableTouchListener?: boolean,
    disableHoverListener?: boolean
}

interface ModalProps{
    children: React.ReactNode,
    modalIcon?: JSX.Element,
    modalButton?: JSX.Element,
    modalText?: string,
    modalObject?: JSX.Element,
    disabled?: boolean,
    disableHover?: boolean,
    tooltip?: ModalTooltip,
    modalState: ModalState
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    paper: {
        maxHeight:'80%',
        overflow:'auto',
        padding: '24px 32px',
        display: 'flex',
        width: 544,
        /* Base / 800 */
        background: theme.palette.background.paper,
        /* Base / 600 */
        border: '1px solid',
        borderColor: theme.palette.border.main,
        boxSizing: 'border-box',
        boxShadow: '0px 52px 57px -5px rgba(0, 0, 0, 0.5), 0px 10px 10px -5px rgba(0, 0, 0, 0.04)',
        borderRadius: '8px',
        [theme.breakpoints.down('sm')]:{
            width: '90%'
        },
        '&:focus':{
            outline: 'none'
        }
      }
  }),
);

export const BaseModal = forwardRef<HTMLDivElement, ModalProps>((props, ref) => {
    const {modalIcon, modalButton, modalObject, children, modalState, modalText, disabled, disableHover, tooltip } = props;
    const theme = useTheme();
    const classes = useStyles(theme);

    const handleOpen = (e: React.MouseEvent) => {
        if(modalState.handleOpen && !disabled){
            modalState.handleOpen(e);
        }
    }

    let opener: JSX.Element = (
        <>
        </>
    );

    if(modalText)
        opener = (
            <Typography onClick={handleOpen}>
                {modalText}
            </Typography>
        );

    else if(modalIcon)
        opener = (
            <IconButton disabled={disabled} disableFocusRipple={disableHover} disableTouchRipple={disableHover} onClick={handleOpen} style={{backgroundColor: disableHover ? 'transparent' : undefined}}>
                {modalIcon}
            </IconButton>
        );

    else if(modalButton)
        opener = (
            <div onClick={handleOpen}>
                {modalButton}
            </div>
        );

    else if(modalObject)
        opener = (
            <div onClick={handleOpen} style={{height: '100%', width: '100%'}}>
                {modalObject}
            </div>
        );

    if(tooltip)
            opener = (
                <Tooltip {...tooltip} >
                    <div>
                        {opener}
                    </div>
                </Tooltip>
            );

    return (
      <div style={modalObject ? {height: '100%', width: '100%'} : {}}>
        {opener}
        <Modal
          className={classes.modal}
          open={modalState.open}
          onClose={(e,r) => modalState.handleClose()}
        >
            <Box ref={ref} className={classes.paper}>
                <div style={{height: '100%', width: '100%'}}>
                    {children}
                </div>
            </Box>
        </Modal>
      </div>
    );
  }
)