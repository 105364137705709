import { api } from './api';


export interface Location {
    id?: string,
    label: string,
    locationName: string,
    latitude: number,
    longitude: number,
    radius1: number,
    radius2: number,
    radius3: number,
    allClearMinutes: number,
    type?: number,
    organizationLocationId?: string,
    userId?: string,
    flags: number,
    forecastLocation: boolean
}

export interface LocationErrorModel{
    ServerErrors?: string[]
    Label?: string[],
    LocationName?: string[]
}

export interface LightningDelay {
    item1: string,
    item2: string,
    item3: string,
}

interface AddLocationResponse {
    message: string,
    location: Location
}

const locationApi = api.injectEndpoints({
    endpoints: (builder) => ({
        locations: builder.query<Location[], void>({
            query: () => 'location',
            transformResponse: (response: { data: Location[] }) => response.data,
            providesTags: ["Locations"]
        }),
        userLocations: builder.query<Location[], {userId: string, isNotiOnly: boolean}>({
            query: (props) => ({
                headers: props.isNotiOnly ? {'NotificationOnlyUser': ''} : undefined,
                url:`location/user/${props.userId}`
            }),
            transformResponse: (response: { data: Location[] }) => response.data,
            providesTags: ["Locations"]
        }),
        updateLocation: builder.mutation<string, Location>({
            query: (location) => ({
            url: 'location/Update',
            method: 'POST',
            body: location
            }),
            transformResponse: (response: { data: string}) => response.data,
            invalidatesTags: ["Locations"]
        }),
        updateForecastLocation: builder.mutation<string, string>({
            query: (locationId) => ({
            url: `location/Update/ForecastLocation/${locationId}`,
            method: 'POST'
            }),
            transformResponse: (response: { data: string}) => response.data,
            invalidatesTags: ["Locations"]
        }),
        addLocation: builder.mutation<AddLocationResponse, Location>({
            query: (location) => ({
            headers: {'ReturnLocation': ''},
            url: 'location/Add',
            method: 'POST',
            body: location
            }),
            transformResponse: (response: { data: AddLocationResponse}) => response.data,
            invalidatesTags: ["Locations"]
        }),
        deleteLocation: builder.mutation<string, string>({
            query: (id) => ({
            url: `location/${id}`,
            method: 'DELETE'
            }),
            transformResponse: (response: { data: string}) => response.data,
            invalidatesTags: ["Locations"]
        }),
        updateUserOrganizationLocations: builder.mutation<string, {userId: string, locations: Location[]}>({
            query: ({userId, locations}) => ({
            url: `location/Update/OrganizationLocation/${userId}`,
            method: 'POST',
            body: locations
            }),
            transformResponse: (response: { data: string}) => response.data
        }),
        lightningStatus: builder.query<LightningDelay, string>({
            query: (id) => ({url:`location/Delay/${id}`}),
            transformResponse: (response: { data: LightningDelay }) => response.data
        })
    })
});

export const {
    useUpdateLocationMutation,
    useUpdateForecastLocationMutation,
    useAddLocationMutation,
    useDeleteLocationMutation,
    useUpdateUserOrganizationLocationsMutation,
    useLocationsQuery,
    useUserLocationsQuery,
    useLightningStatusQuery

} = locationApi;