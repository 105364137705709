import { useTheme, Grid, Typography, styled, Box, BoxProps } from "@mui/material";
import { Visibility, Gavel, Edit, People, WarningAmber } from '@mui/icons-material'
import { Policy, useSitesQuery } from "../../Services/API";
import { Card, Banner } from '../Base'
import { useAuth } from "oidc-react";

const LinkButton = styled(Box, {
	shouldForwardProp: (prop) => prop !== 'success',
  })<BoxProps>(({ theme = useTheme() }) => ({

    color:theme.palette.action.focus,

    '&:hover':{
      cursor: 'pointer',
      textDecoration: 'underline',
      color: theme.palette.primary.dark
    }
  }))

export function PolicyCard(props: {policy: Policy, onEdit: (policy: Policy) => void}){
    const {policy, onEdit} = props;
    const theme = useTheme();

    const {data: sites} = useSitesQuery();
    const {userData: user} = useAuth();

    return (
      <Grid item xs={12} sm={6} md={4}>
        <Card header={policy.policyTypeName}>

        {policy.sites.length < 1 && <Banner icon={<WarningAmber />} variant='warn' message='This policy is inactive because no user group(s) are assigned.'/>}
          <Grid container marginBottom='16px'>

          <Grid container item xs={12} spacing={1}>
              <Grid item xs={2} justifyContent='flex-end'>
                <People htmlColor={theme.palette.text.secondary}/>
              </Grid>
              <Grid item xs justifyContent='flex-start'>
                <Typography variant='subtitle1' color='textSecondary' textAlign='start'>
                  User Groups
                </Typography>
              </Grid>
            </Grid>
            <Grid container item xs={12}>
                <Grid item xs={2} />
                <Grid item xs>
                  <Typography textAlign='left'>
                    {policy.sites.length > 0 ? sites?.filter(site => policy.sites.some(s => s === site.id)).map(s => s.name).join(', ') || '' : 'None'}
                  </Typography>
                </Grid>
            </Grid>

            <Grid container item xs={12} spacing={1}>
              <Grid item xs={2} justifyContent='flex-end'>
                <Visibility htmlColor={theme.palette.text.secondary}/>
              </Grid>
              <Grid item xs justifyContent='flex-start'>
                <Typography variant='subtitle1' color='textSecondary' textAlign='start'>
                  Trigger
                </Typography>
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={2} />
              <Grid item xs>
                <Typography textAlign='left'>
                  {`Whenever the ${policy.policyTypeName} goes ${policy.isDesc ? 'below' : 'above'} ${policy.threshold} `}
                  <Typography component='span' textAlign='left' variant='overline'>
                    {policy.unit}
                  </Typography>
                </Typography>
              </Grid>
            </Grid>

            <Grid container item xs={12} spacing={1} marginTop={1}>
              <Grid item xs={2} justifyContent='flex-end'>
                <Gavel htmlColor={theme.palette.text.secondary}/>
              </Grid>
              <Grid item xs justifyContent='flex-start'>
                <Typography variant='subtitle1' color='textSecondary' textAlign='start'>
                  Actions
                </Typography>
              </Grid>
            </Grid>
            {policy.notificationActions && policy.notificationActions.length > 0 ?
              <Grid container item xs={12}>
                <Grid item xs={2} />
                <Grid item xs overflow=''>
                  <Typography textOverflow='ellipsis' textAlign='left'>
                    Send Notification
                  </Typography>
                  </Grid>
              </Grid>
              :
              <Grid container item xs={12}>
                <Grid item xs={2} />
                <Grid item xs overflow=''>
                  <Typography textOverflow='ellipsis' textAlign='left' fontStyle='italic'>
                    No Actions
                  </Typography>
                  </Grid>
              </Grid>
            }
          </Grid>
          {(user?.profile?.Role === 'Admin' || user?.profile?.Role === 'Super Admin') &&
            <LinkButton left='37%' bottom='8px' position='absolute' display='flex' onClick={e => onEdit(policy)}>
              <Typography variant='body2' >Edit Policy</Typography> <Edit style={{marginLeft: 8 ,width: 18, height: 18}}/>
            </LinkButton>
          }
        </Card>
      </Grid>
    )
  }