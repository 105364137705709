import { AppBar, Avatar, Container, Divider, useScrollTrigger } from '@mui/material';
import React, { useMemo } from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import { Box, Button, IconButton, Toolbar } from '@mui/material';
import { Link } from 'wouter';
import { styledLinkClass } from '../../Base';

import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import NotificationsOffOutlinedIcon from '@mui/icons-material/NotificationsOffOutlined';
import { useAuth } from 'oidc-react';
import MenuIcon from '@mui/icons-material/Menu';
import { useAppDispatch } from '../../../app/hooks';
import { toggle } from '../../../features/menu/menuSlice';
import { Menu as PWMenu } from '../../Base/Menu';
import { PWColors } from '../../../Theme/PWColors';
import { useNotificationSettingsQuery, useNotificationsQuery } from '../../../Services/API';
import UserNotiPreviewItem from './UserNotiPreviewItem';
import { clientUsTimezone, CorrectUTC } from '../../../utils/utils';
import { PWMenuItem } from '../../Base';
import { ThemeType } from '../../../App';


import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(1),
      color: theme.palette.text.primary
    },
    title: {
      flexGrow: 0,
      justifyItems: 'space-around',
      justifyContent: 'space-around',
    },
    titleBar: {
      color: theme.palette.text.primary,
      justifyItems: 'space-around',
      justifyContent: 'space-around',
    },
    appbar: {
    },
    avatar: {
      background: 'linear-gradient(124deg, #ff2400, #e81d1d, #e8b71d, #e3e81d, #1de840, #1ddde8, #2b1de8, #dd00f3, #dd00f3)',
      backgroundSize: '1800% 1800%',
      animation: `$rainbow 18s ease infinite`
    },
    '@keyframes rainbow': {
      '0%': {
        backgroundPosition: '0% 90%'
      },
      '50%': {
        backgroundPosition: '100% 19%'
      },
      '100%': {
        backgroundPosition: '0% 82%'
      }
    }

  }),
);


interface MainMenuProps {
  toggleTheme: Function
}

export default function MainMenu(props: MainMenuProps) {
  const { toggleTheme } = props;
  const theme = useTheme();
  const linkClass = styledLinkClass(theme);

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [notiAnchorEl, setNotiAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const dispatch = useAppDispatch();
  /// Annoyingly calling signoutRedirect from oidc-react does not work.
  /// So we have to call the signoutRedirect directly from usermanager,
  /// This means it doesn't trigger onSignout() from oidc-react
  const { userData, userManager } = useAuth();
  const { data: notiSettings } = useNotificationSettingsQuery();

  const handleThemeChange = () => {
    if (theme.palette.mode === 'dark')
      toggleTheme(ThemeType.LIGHT)
    else
      toggleTheme(ThemeType.DARK);
  }


  // const handleChange = (event: any) => {
  // setAuth(event.target.checked);
  // };

  const handleMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleNotiMenu = (event: any) => {
    setNotiAnchorEl(event.currentTarget);
  };

  const handleNotiClose = () => {
    setNotiAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  let trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 20,
  });

  var initials = (userData?.profile.given_name || '').substring(0, 1) + (userData?.profile.family_name || '').substring(0, 1);

  const NotificationStateIcon = () => {
    if (notiSettings?.declinesNotifications)
      return (<NotificationsOffOutlinedIcon htmlColor={theme.palette.error.main} />);
    else
      return (<NotificationsNoneIcon />);

  };


  return <>
    <AppBar position="absolute"
      elevation={trigger ? 24 : 0}
      enableColorOnDark={true}
      style={{
        backgroundColor: trigger ? theme.palette.background.default : "transparent",
        boxShadow: trigger
          ? "5px 0px 27px -5px rgba(0, 0, 0, 0.3) !important"
          : undefined
      }}
    >
      <Container>

      <Toolbar style={{ backgroundColor: trigger ? theme.palette.background.default : 'transparent' }}>

        <Box sx={{flexGrow: 1}} display="flex" flexDirection="row" justifyContent="space-evenly">
          <a  className={linkClass.link} href='https://sa.perryweather.com'>Home</a>
          <a  className={linkClass.link} href='https://sa.perryweather.com/Customer'>Customers</a>
          <a  className={linkClass.link} href='https://sa.perryweather.com/User'>Users</a>
          <a  className={linkClass.link} href='https://sa.perryweather.com/Hardware'>Hardware</a>
        </Box>
        <div className={classes.title} />
        <div className={classes.titleBar}>
          <Button
            sx={{ textTransform: 'none' }}
            color="inherit"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Avatar sx={{ width: 32, height: 32 }} className={classes.avatar}>{initials}</Avatar>
              <Typography sx={{ marginLeft: '10px' }} variant="subtitle1">{userData?.profile.name}</Typography>
            </Box>
          </Button>
          <PWMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
            <Link to="/Profile" onClick={handleClose} style={{ textDecoration: 'none', color: theme.palette.text.primary }}>
              <PWMenuItem>
                My Profile
              </PWMenuItem>
            </Link>
            {/* <Link to="/NotificationSettings" onClick={handleClose} style={{ textDecoration: 'none', color: theme.palette.text.primary }}>
              <PWMenuItem>
                Notification Settings
              </PWMenuItem>
            </Link> */}
            <PWMenuItem onClick={handleThemeChange}>
              <Box width="100%" display="flex" justifyContent="space-between">
                <Typography style={{ textDecoration: 'none', color: theme.palette.text.primary }}>Theme: </Typography><Typography variant="subtitle1">{theme.palette.mode === 'dark' ? "Dark" : "Light"}</Typography>
                {theme.palette.mode === 'dark' ? <DarkModeIcon /> : <LightModeIcon />}
              </Box>
            </PWMenuItem>
            <Divider />
            <PWMenuItem onClick={() => userManager.signoutRedirect()}>Sign Out</PWMenuItem>
          </PWMenu>
        </div>
      </Toolbar>
      </Container>

    </AppBar>
  </>;
}