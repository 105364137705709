/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import {useAuth} from 'oidc-react'
import { Box, BoxProps, Divider, Grid, RadioGroup, styled, Theme, Typography, useTheme, Link } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import MailOutlined from '../Assets/mailOutlined.svg';
import Phone from '../Assets/phone.svg';
import CellularNetworkIcon from '../Assets/cellularNetwork.svg';
import PWLogo from '../Assets/pwlogo.svg';
import {Card, TextField, TextArea, Page, Button, SubCardContainer, Radio, MultiSelect, SelectOption } from '../Components/Base'
import { useSubmitSupportRequestMutation, SupportDto, PWError, useUsersByRoleQuery } from '../Services/API';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { openToast } from '../features/toast/toastSlice';
import { Redirect } from 'wouter';

interface IconCircleProps {
  children: JSX.Element
}

const useStyles = makeStyles((theme:Theme) =>
  createStyles({
    iconContainer:{
      borderRadius:'50%',
      height:55, width:55,
      display:'flex',
      paddingTop: '2px',
      flexDirection:'column',
      justifyContent:'center',
      background:'linear-gradient(42.4deg, rgba(69, 193, 195,.1) -11.76%, rgba(203, 217, 146,.1) 105.23%)',
      backgroundColor: 'rgba(0,0,0,.1)'
    }
  })
);

const IconBox = styled(Box, {
	shouldForwardProp: (prop) => prop !== 'success',
})<BoxProps>(({ theme }) => ({
  opacity: .2,
  width:'50%',
  height: '35%',
  position: 'absolute',
  marginLeft: '40%',
  bottom: '10%',

  [theme.breakpoints.down("lg")]:{
    bottom: 0
  }
}));


function CircledIcon (props: IconCircleProps){
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <div className={classes.iconContainer}>
      <span>{props.children}</span>
    </div>
  );
}

export function Consultation() {
  const {userData} = useAuth();
  const theme = useTheme();
  const dispatch = useDispatch()
  const [roleOptions, setRoleOptions] = useState<SelectOption[]>([]);
  const [supportRequest, setSupportRequest] = useState<SupportDto>({email: userData?.profile.email || '', message: '', phone: userData?.profile.phone_number || '', preferredContact: '', ccdEmails: []});
  const [ccdIds, setCcdIds] = useState<string[]>([]);

  const [submitSupportRequest, {isSuccess: requestSuccess, error, isError:requestError, data: requestData}] = useSubmitSupportRequestMutation();
  const {data: users, isLoading: isUsersLoading} = useUsersByRoleQuery(['Admin', 'Assistant Admin']);

  var hasMetSupport = (userData?.profile?.permissions?.includes('met.support') && (userData?.profile?.Role === 'Assistant Admin' || userData?.profile?.Role === 'Admin' || userData?.profile?.Role === 'Super Admin'));

  const handleSubmit = () => {
    submitSupportRequest(supportRequest);
  };

  const handleCcdEmailsAdd = (userIds: string[]) => {
    if(users){
      setCcdIds(userIds);

      let ccEmails: string[] = users.filter(x => userIds.some(y => y === x.id) && x.email !== null).map(x => x.email!);

      setSupportRequest({...supportRequest, ccdEmails: ccEmails})
    }
  }

  useEffect(() => {
    if(!isUsersLoading){
        if(users)
        setRoleOptions(
          users.map(user => ({value: user.id, text: `${user.fullName} <${user.email}>`}))
        )
    }
  },[isUsersLoading, users])

  useEffect(() =>{
    if(requestSuccess && requestData){
      dispatch(openToast({variant: 'success', header: requestData}));
      setSupportRequest({...supportRequest, message:''})
    }
    else if(requestError && error){
      dispatch(openToast({variant: 'error', header: (error as PWError)?.data.responseException.message}));
    }
  },[requestSuccess, requestError, requestData, error])

  if(!hasMetSupport)
  {
    return(
      <Redirect to="/Unauthorized"/>
    );
  }

	return (
      <Page docTitle='Consultation' title={<Typography variant="h3" color="textPrimary" noWrap>Ask a Meteorologist</Typography>} subheader={<Typography variant="body1" color="textSecondary">Need a forecast for an upcoming event? How long will this lightning delay last? Ask our full-time meteorologists.</Typography>}>
        <Grid container spacing={4}>
          <Grid item md={7} sm={12}>
            <Card>
              <SubCardContainer title='Your Question'>
                <TextArea value={supportRequest.message} placeholder='Enter your question here...' onChange={e => setSupportRequest({...supportRequest, message: e.target.value})}/>
              </SubCardContainer>
              <Divider style={{margin:'24px 0'}}/>
              <SubCardContainer title='Response Preference' desc={<Typography textAlign='left' variant='body2' color='textSecondary'>Let us know how to reach you.</Typography>}>
                <RadioGroup onChange={(e,v) => setSupportRequest({...supportRequest, preferredContact: v as ('email' | 'phone' | 'text')})}>
                <Grid container spacing={1}>
                  <Grid container item md sm={12} alignItems="center" onTouchStart={(e) => setSupportRequest({...supportRequest, preferredContact: 'email'})}>
                    <Radio value='email' checked={supportRequest.preferredContact === 'email'}/>
                    <Typography>Email</Typography>
                  </Grid>
                  <Grid container item md sm={12} alignItems="center" onTouchStart={(e) => setSupportRequest({...supportRequest, preferredContact: 'phone'})}>
                    <Radio value='phone' checked={supportRequest.preferredContact === 'phone'}/>
                    <Typography>Phone</Typography>
                  </Grid>
                  <Grid container item md sm={12} alignItems="center" onTouchStart={(e) => setSupportRequest({...supportRequest, preferredContact: 'text'})}>
                    <Radio value='text' checked={supportRequest.preferredContact === 'text'}/>
                    <Typography>Text</Typography>
                  </Grid>
                </Grid>
                </RadioGroup>
              </SubCardContainer>
              {supportRequest.preferredContact === 'email' &&
              <>
                <Divider style={{margin:'24px 0'}}/>
                <SubCardContainer title='Your Email'>
                  <TextField value={supportRequest.email} placeholder='perry@weather.com' onChange={e => setSupportRequest({...supportRequest, email: e.target.value})}/>
                </SubCardContainer>
                <Divider style={{margin:'24px 0'}}/>
                <SubCardContainer title='Include Others?' desc={<Typography textAlign='left' variant='body2' color='textSecondary'>Optionally include other admins or limited admins on our response.</Typography>}>
                  <MultiSelect options={roleOptions} value={ccdIds} onChange={handleCcdEmailsAdd}/>
                </SubCardContainer>
              </>
              }
              {(supportRequest.preferredContact === 'phone' || supportRequest.preferredContact === 'text' )&&
                <>
                  <Divider style={{margin:'24px 0'}}/>
                  <SubCardContainer title='Your Number'>
                    <TextField value={supportRequest.phone} placeholder='123-456-7890' onChange={e => setSupportRequest({...supportRequest, phone: e.target.value})}/>
                  </SubCardContainer>
                </>
              }
              <Box marginTop="16px" display='flex' justifyContent='flex-end'>
                <Button onClick={handleSubmit}>
                  <Typography variant='body1'>
                    Send Question
                  </Typography>
                </Button>
              </Box>
            </Card>
          </Grid>


          <Grid container item md={5} sm={12} style={{overflow: 'hidden'}}>
            <Card>
              <Grid container style={{marginBottom: 24}}>
                <Grid item xs={12} style={{display: 'flex', justifyContent: 'start'}}>
                  <Typography variant='body1' color='textPrimary'>Have another question?</Typography>
                </Grid>
                <Grid item xs={12} style={{display: 'flex', justifyContent: 'start', textAlign: 'left', marginBottom: 24}}>
                  <Typography variant='body1' color='textSecondary'>You can find product help or additional resources using the links below.</Typography>
                </Grid>
                <Grid container item style={{marginBottom: 16}}>
                  <Link underline='hover' style={{display: 'flex'}}>
                    <CircledIcon>
                      <img src={MailOutlined} alt='Email'/>
                    </CircledIcon>
                    <Typography alignSelf='center' variant="subtitle1" marginLeft={2}>support@perryweather.com</Typography>
                  </Link>
                </Grid>
                <Grid container item style={{marginBottom: 16}}>
                  <Link underline='hover' style={{display: 'flex'}}>
                    <CircledIcon>
                      <img src={Phone} alt=''/>
                    </CircledIcon>
                    <Typography alignSelf='center' variant="subtitle1" marginLeft={2}>(469) 546-5082</Typography>
                  </Link>
                </Grid>
                <Grid container item style={{marginBottom: 16}}>
                  <Link href='https://perryweather.com/outdoor-warning-system' underline='hover' style={{display: 'flex'}}>
                    <CircledIcon>
                      <img src={CellularNetworkIcon} alt='hardware'/>
                    </CircledIcon>
                    <Typography alignSelf='center' textAlign='left' variant="subtitle1" marginLeft={2}>Learn more about adding on-site hardware</Typography>
                  </Link>
                </Grid>
              </Grid>

              <IconBox theme={theme}>
                <img src={PWLogo} width='100%' alt='Perry Weather logo'/>
              </IconBox>

            </Card>
          </Grid>
        </Grid>
      </Page>
	);
}
export default Consultation;
