import { api } from ".";

export interface PA {
  id: number,
  name: string,
  ipAddress: string,
}

export interface PAMessage {
  pAList: string[],
  message: string,
  repeatCount: string,
}

export interface PAStoredMessage {
  id?: number,
  title: string,
  message: string,
  customerId?: number,
}

export interface StoredPaMessageErrorsModel{
  Title?: string[],
  Message?: string[],
  ServerError?: string[]
}

export interface PAMessageErrorModel{
  message?: string[],
  PAList?: string[],
  ServerError?: string[]
}

const paApi = api.injectEndpoints({
  endpoints: (builder) => ({
    pa: builder.query<PA[], void>({
      query: () => `PA`,
      transformResponse: (response: { data: PA[] }) => response.data
    }),
    sendPAMessage: builder.mutation<void, PAMessage>({
      query: (message) => ({
        url: 'PA/SendMessage',
        method: 'POST',
        body: message
      }),
    }),
    paStoredMessages: builder.query<PAStoredMessage[], void>({
      query: () => `PA/StoredMessages`,
      transformResponse: (response: { data: PAStoredMessage[] }) => response.data
    }),
    addPAStoredMessage: builder.mutation<PAStoredMessage, PAStoredMessage>({
      query: (message) => ({
        url: 'PA/StoredMessage',
        method: 'POST',
        body: message
      }),
      transformResponse: (response: { data: PAStoredMessage }) => response.data
    }),
    updatePAStoredMessage: builder.mutation<PAStoredMessage, PAStoredMessage>({
      query: (message) => ({
        url: 'PA/StoredMessage',
        method: 'PATCH',
        body: message
      }),
      transformResponse: (response: { data: PAStoredMessage }) => response.data
    }),
    deletePAStoredMessage: builder.mutation<string, number>({
      query: (id) => ({
        url: `PA/StoredMessage/${id}`,
        method: 'DELETE'
      })
    }),
  })
})

export const {
  usePaQuery,
  useSendPAMessageMutation,
  usePaStoredMessagesQuery,
  useAddPAStoredMessageMutation,
  useUpdatePAStoredMessageMutation,
  useDeletePAStoredMessageMutation,
} = paApi;