import { useAppSelector } from "../../../../app/hooks";
import { getSelectedLocation } from "../../../../features/dash/dashSlice";
import { CircleLayer } from "../Layers/CircleLayer";
import LocationMarkerIcon from '../../../../Assets/locationMarker.svg';
import { PWColors } from "../../../../Theme/PWColors";
import { Marker } from "react-map-gl";

export function LocationMarker() {
    const selectedLocation = useAppSelector(getSelectedLocation);

    return (
        selectedLocation ? (
            <div style={{pointerEvents: 'none'}}>
                <Marker
                    offsetLeft={-20}
                    offsetTop={-30}
                    latitude={selectedLocation!.latitude}
                    longitude={selectedLocation!.longitude}
                >
                    <img style={{pointerEvents: 'none'}} height={40} width={40} src={LocationMarkerIcon} onDragStart={(event) => event.preventDefault()} alt="Location Marker" />
                </Marker>
                <CircleLayer index={1} latitude={selectedLocation!.latitude} longitude={selectedLocation!.longitude} radius={selectedLocation.radius1} color={PWColors.dark.danger['500']} sourceId={'radii-1'} />
                {selectedLocation!.radius2 > 0 ? <CircleLayer index={2} latitude={selectedLocation!.latitude} longitude={selectedLocation!.longitude} radius={selectedLocation.radius2} color={PWColors.dark.warning['500']} sourceId={'radii-2'} /> : null}
                {selectedLocation!.radius3 > 0 ? <CircleLayer index={3} latitude={selectedLocation!.latitude} longitude={selectedLocation!.longitude} radius={selectedLocation.radius3} color={PWColors.dark.success['600']} sourceId={'radii-3'} /> : null}
            </div>
        ) :
        (<> </>)
    );
}

export default LocationMarker;