import { Divider, Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { useAuth } from 'oidc-react';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ChangePasswordModel, ChangePasswordModelError, useChangePasswordMutation } from '../../Services/API/identApi';
import { BaseModal, InputRow, ModalState, TextField, Button } from '../Base'
import { openToast } from '../../features/toast/toastSlice';

interface ChangePasswordModalProps{
    modalState: ModalState
}

const defaultPwData: ChangePasswordModel = {userId: '', currentPassword: '', newPassword: '', confirmedNewPassword: ''};

export function ChangePasswordModal(props: ChangePasswordModalProps){
    const {modalState} = props;
    const {userData: user} = useAuth();
    const theme = useTheme();
    const dispatch = useDispatch();

    const [pwData, setPwData] = useState<ChangePasswordModel>(defaultPwData);
    const [errorData, setErrorData] = useState<string[]>([]);

    const [changePassword, {data, error, isSuccess, isError}] = useChangePasswordMutation();

    useEffect(() => {
        if(user)
            setPwData(p => ({...p, userId: user.profile.sub}))
    },[user])

    useEffect(() => {
        if(isSuccess){
            dispatch(openToast({variant: 'success', header: data || ''}));
            setPwData(defaultPwData);
            modalState.handleClose();
        }
        else if(isError){
            dispatch(openToast({variant: 'error', header: 'Error changing password'}));
            if(error)
                setErrorData(
                    [
                        ...(error as ChangePasswordModelError).data?.IdentityError || [''],
                        ...(error as ChangePasswordModelError).data?.CurrentPassword || [''],
                        ...(error as ChangePasswordModelError).data?.NewPassword || [''],
                        ...(error as ChangePasswordModelError).data?.ConfirmedNewPassword || ['']
                    ]);
        }
    },[isSuccess, isError, error, data, dispatch, modalState])

    return (
        <BaseModal modalState={modalState}>
            <Typography variant='h6'>
                Change Password
            </Typography>
            <Divider style={{margin: '12px 0'}}/>
            <InputRow label=''>
                <ul style={{color: theme.palette.error.main, listStyle: 'circle', marginLeft: '16px'}}>
                {errorData.length > 0 &&
                    errorData.map(err => err !== '' &&
                    <li>
                        <Typography variant='subtitle1'>
                            {err}
                        </Typography>
                    </li>
                    )
                }
                </ul>
            </InputRow>
            <form>
                <input style={{display:'none'}} autoComplete='username' type='text' value={user?.profile.email || ''}/>
                <InputRow label='Current Password'>
                    <TextField type='password' placeholder='123Abc!' value={pwData.currentPassword} onChange={e => setPwData({...pwData, currentPassword: e.target.value})} autoComplete='current-password'/>
                </InputRow>
                <InputRow label='New Password'>
                    <TextField disablePassPreview type='password' placeholder='123Abc!' value={pwData.newPassword} onChange={e => setPwData({...pwData, newPassword: e.target.value})} autoComplete='new-password'/>
                </InputRow>
                <InputRow label='Confirm New Password'>
                    <TextField disablePassPreview type='password' placeholder='123Abc!' value={pwData.confirmedNewPassword} onChange={e => setPwData({...pwData, confirmedNewPassword: e.target.value})} autoComplete='new-password'/>
                </InputRow>
            </form>
            <Box display='flex' justifyContent='flex-end' >
                <Button type='clear' onClick={e => {setPwData(defaultPwData); modalState.handleClose()}}>
                    Cancel
                </Button>
                <Box marginLeft='16px'>
                    <Button onClick={e => changePassword(pwData)}>
                        Save
                    </Button>
                </Box>
            </Box>
        </BaseModal>
    )
}