import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError
 } from '@reduxjs/toolkit/query/react';
import { UserErrorModel, PAMessageErrorModel, SiteErrorModel, PolicyErrorsModel, LocationErrorModel } from '.';
import { store, RootState } from '../../app/store';
import { setNeedsHardLogin } from '../Auth/authSlice';
import { CommunicationErrorModel } from './communicationsApi';

export interface WeatherModel {
  value: number,
  unit: string
}

export interface WeatherCode {
  value: string,
  text: string
}

// export interface UserResponse {
//   user: User,
//   token: string
// }

// export interface LoginRequest {
//   username: string
//   password: string
// }

export type PWError = {
  status: number;
  data: {
    isError: boolean;
    responseException: {message: string, errors: string};
  };
}

export interface ModelStateErrorResponse {
  data:{
    responseException?: {
      errors?: (PAMessageErrorModel | UserErrorModel | SiteErrorModel | PolicyErrorsModel | LocationErrorModel | CommunicationErrorModel)
    }
  }
}

export const API_URL = process.env.REACT_APP_CLIENT_API_URL;
export const LEGACY_REDIRECT_URL = process.env.REACT_APP_LEGACY_REDIRECT_URL;

const baseQuery = fetchBaseQuery({
    baseUrl: API_URL,
    prepareHeaders: (headers, { getState }) => {
      // By default, if we have a token in the store, let's use that for authenticated requests
      const token = (getState() as RootState).auth.token
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }
      return headers
    },
});


const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions)
  if (result.error && result.error.status === 401) {
    // try to get a new token
  // console.debug('should try to resign in')
  // store.auth
  store.dispatch(setNeedsHardLogin(true));
  // let user = PWUserManager.signinSilent();
    /*let user = PWUserManager.signinSilentCallback();
    if (user) {
      console.debug('should have a sign in.');
      // (await user).access_token.
      result = await baseQuery(args, api, extraOptions)
    } else {
      console.debug('should try to to be logged out.');
      /// Probably want to log user out, but for some reason always goes through this part.
      // api.dispatch(loggedOut())
      console.error('should be logged out');
    }
    // return result*/
  }
    return result
}


// fetchQuery as BaseQueryFn<string | FetchArgs, unknown, YourErrorType, {}>.

export const api = createApi({
  baseQuery: baseQueryWithReauth as BaseQueryFn<string | FetchArgs, unknown, PWError | ModelStateErrorResponse, {}>,
  tagTypes: ["Policies","Sites", "CommunicationHistory", "Users", "NotiUsers", "Hardware", "Locations"],
  // baseQuery: baseQueryWithReauth,
  // refetchOnFocus: true,
  // refetchOnReconnect: true,
  refetchOnMountOrArgChange: 60,
  endpoints: () => ({})
})


