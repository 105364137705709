import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import { Grow, styled, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { baseLayerType, getBaseLayer, selectBaseLayer } from '../../../../features/map/mapSlice';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import BaseControl from './BaseControl';
import { PWColors } from '../../../../Theme/PWColors';


const StyledToggleButton = styled(ToggleButton)(() => ({
	border: 'none',
    borderRadius:'8px',
	'&:hover': {
		backgroundColor: '#E9E9E9'
	}
}))

interface BaseLayerControlProps {
	onClick?: React.MouseEventHandler,
	show: boolean
}

export function BaseLayerControl (props: BaseLayerControlProps) {
	const {onClick, show} = props;

	const dispatch = useAppDispatch();

	const baseLayer = useAppSelector(getBaseLayer);

	const handleLayerChange = (event: React.MouseEvent<HTMLElement>, newBaseLayer: string) => {
		if (newBaseLayer !== null)
			dispatch(selectBaseLayer(newBaseLayer as baseLayerType));
	};


	return (
		<div>
			<Grow in={show} >
				<div className="overlayMenu right">
					<ToggleButtonGroup
						color="secondary"
						exclusive
						orientation="vertical"
						value={baseLayer as string}
						onChange={handleLayerChange}
					>
						<StyledToggleButton value="dark">
							<Typography color={PWColors.dark.base['900']}>Dark</Typography>
						</StyledToggleButton>
						<StyledToggleButton value="light">
							<Typography color={PWColors.dark.base['900']}>Light</Typography>
						</StyledToggleButton>
						<StyledToggleButton value="satellite">
							<Typography color={PWColors.dark.base['900']}>Satellite</Typography>
						</StyledToggleButton>
					</ToggleButtonGroup>
				</div>
			</Grow>
			<BaseControl label="Base Layer Style" onClick={onClick}>
				<LanguageOutlinedIcon/>
			</BaseControl>
		</div>
	);
}

export default BaseLayerControl;