/* eslint-disable react-hooks/exhaustive-deps */
import { Notifications, NotificationsOutlined } from "@mui/icons-material";
import { Box, Grid, IconButton, Skeleton, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { getSelectedLocation, addSevereLocation, removeSevereLocation } from "../../features/dash/dashSlice";
import TimeParser from "../../features/Time/TimeHelpers";
import { useLightningStatusQuery, useUpdateLocationMutation } from "../../Services/API";
import LightningTimerComponent from "../Animated/LightningTimerComponent";
import Card from '../Base/Card';


export function LightningStatus (){
	const dispatch = useAppDispatch();
	const theme = useTheme();
	const selectedLocation = useAppSelector(getSelectedLocation);

	const { data: delay, isFetching, isLoading } = useLightningStatusQuery(selectedLocation ? (selectedLocation.id || ""): "", { pollingInterval: 30000, skip: !selectedLocation, refetchOnFocus: true, refetchOnReconnect: true });
	const [isInDelay, setIsInDelay] = useState(false);
	useEffect(() => {
	}, [isFetching]);

	let skeltelAnimation: boolean = false;

	useEffect(() =>{
        if(delay)
        {
            var delay1 = TimeParser(delay.item1);
            var delay2 = TimeParser(delay.item2);
            var delay3 = TimeParser(delay.item3);

			var shouldAdd = false;

            if(delay1[0] > 0 || delay1[1] > 0)
            {
                setIsInDelay(true);
				shouldAdd = true;
            }
            else if(delay2[0] > 0 || delay2[1] > 0)
            {
                setIsInDelay(true);
				shouldAdd = true;
            }
            else if(delay3[0] > 0 || delay3[1] > 0)
            {
                setIsInDelay(true);
				shouldAdd = true;
            }
			else
			{
				setIsInDelay(false);
				shouldAdd = false;

			}

            if(selectedLocation && selectedLocation.id)
            {
                if(shouldAdd)
                {
					dispatch(addSevereLocation({locationId: selectedLocation.id, threatType: {shortName: 'LR1', longName: ["Lightning Policy Warning"]}}))
                }
                else
                {
					dispatch(removeSevereLocation({locationId: selectedLocation.id, threatType:  {shortName: 'LR1', longName: []}}))
                }
            }
        }
    }, [delay, selectedLocation]);

	return (
		<Card header="Lightning Status" scary={isInDelay}>
			{(isLoading && isFetching) || delay === undefined ?
			(<Grid container wrap="nowrap">
				<Grid item xs={4}>
				<Box display="flex" justifyContent="space-around">
					<Skeleton animation={skeltelAnimation} variant="circular" width={75} height={75} />
					<Box alignSelf="center" >
						<Skeleton animation={skeltelAnimation} width="100%" height={50}/>
						<Skeleton animation={skeltelAnimation} width="100%" height={20}/>
					</Box>
				</Box>
				</Grid>
				<Grid item xs={4}>
				<Box display="flex" justifyContent="space-around">
					<Skeleton animation={skeltelAnimation} variant="circular" width={75} height={75} />
					<Box alignSelf="center" >
						<Skeleton animation={skeltelAnimation} width="100%" height={50}/>
						<Skeleton animation={skeltelAnimation} width="100%" height={20}/>
					</Box>
				</Box>
				</Grid>
				<Grid item xs={4}>
				<Box display="flex" justifyContent="space-around">
					<Skeleton animation={skeltelAnimation} variant="circular" width={75} height={75} />
					<Box alignSelf="center" >
						<Skeleton animation={skeltelAnimation} width="100%" height={50}/>
						<Skeleton animation={skeltelAnimation} width="100%" height={20}/>
					</Box>
				</Box>
				</Grid>
			</Grid>)
			: selectedLocation && (!(delay.item1 === '00:00:00' && delay.item2 === '00:00:00' && delay.item3 === '00:00:00') ?
				<Grid display="flex" justifyContent="space-around" flex='1 1 auto' alignItems='center'>
					<LightningTimerComponent time={delay.item1} allClearMinutes={selectedLocation.allClearMinutes} radiusDisplay={
						            <Typography variant="body1" style={{ color: theme.palette.text.primary }}>
									{`${0}-${selectedLocation.radius1} mi`}
								</Typography>
					}/>
					{selectedLocation.radius2 > 0 &&
						<LightningTimerComponent time={delay.item2} allClearMinutes={selectedLocation.allClearMinutes} radiusDisplay={
							<Typography variant="body1" style={{ color: theme.palette.text.primary }}>
							{`${selectedLocation.radius1}-${selectedLocation.radius2} mi`}
						</Typography>
					}/>}
					{selectedLocation.radius3 > 0 &&
						<LightningTimerComponent time={delay.item3} allClearMinutes={selectedLocation.allClearMinutes} radiusDisplay={
							<Typography variant="body1" style={{ color: theme.palette.text.primary }}>
							{`${selectedLocation.radius2}-${selectedLocation.radius3} mi`}
						</Typography>
					}/>}
				</Grid>
				:
				<Grid display="flex" justifyContent="space-around" flex='1 1 auto' alignItems='center'>
					<LightningTimerComponent time={delay.item1} allClearMinutes={selectedLocation.allClearMinutes} radiusDisplay={
								<>
						            <Typography variant="body1" style={{ color: theme.palette.text.primary }}>
										{`${0}-${selectedLocation.radius1} mi`}
									</Typography>
									{selectedLocation.radius2 > 0 &&
									<Typography variant="body1" style={{ color: theme.palette.text.primary }}>
										{`${selectedLocation.radius1}-${selectedLocation.radius2} mi`}
									</Typography>}
									{selectedLocation.radius3 > 0 &&
									<Typography variant="body1" style={{ color: theme.palette.text.primary }}>
										{`${selectedLocation.radius2}-${selectedLocation.radius3} mi`}
									</Typography>}
								</>
					}/>
				</Grid>)
			}
		</Card>
    );
}

export default LightningStatus;