import { api } from "./api";

export interface OrganizationLocation {
    id: string,
    name: string,
    address: string,
    latitude: number,
    longitude: number
  }

const orgLocationApi = api.injectEndpoints({
    endpoints: (builder) => ({
        organizationLocations: builder.query<OrganizationLocation[], void>({
            query: () => 'organizationLocation',
            transformResponse: (response: { data: OrganizationLocation[] }) => response.data
          }),
          customerOrgLocations: builder.query<OrganizationLocation[], number>({
            query: (customerId) => `organizationLocation/customer/${customerId}`,
            transformResponse: (response: { data: OrganizationLocation[] }) => response.data
          }),
          notiUserOrgLocations: builder.query<OrganizationLocation[], string>({
            query: (userId) => `organizationLocation/NotiUser/${userId}`,
            transformResponse: (response: { data: OrganizationLocation[] }) => response.data
          })
    })
});

export const {
    useOrganizationLocationsQuery,
    useCustomerOrgLocationsQuery,
    useNotiUserOrgLocationsQuery
} = orgLocationApi;