import { BaseModal, ModalTooltip } from './BaseModal';
import Button from './Button'
import { Grid, Box, useTheme, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { WarningAmberOutlined } from '@mui/icons-material';
import React from 'react';

interface WarningDialogProps{
    warningTitle: string,
    warningText: string,
    button: JSX.Element,
    disabled?: boolean,
    onConfirm?: React.MouseEventHandler,
    children?: JSX.Element,
    tooltip?: ModalTooltip
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header:{
        [theme.breakpoints.down('sm')]:{

        }
    },
    yellowCircle: {
      backgroundColor: theme.palette.background.warning,
      borderColor: theme.palette.warning.main,
      borderWidth: '2px',
      borderStyle: 'solid',
	  borderRadius: '50%',
      height: 40,
      width: 40
    }
}));

export function WarningDialog(props: WarningDialogProps)
{
    const theme = useTheme();
    const classes = useStyles(theme);
    const {warningTitle, button, disabled, onConfirm, warningText, children, tooltip} = props;

    const [dialogOpen, setDialogOpen] = React.useState(false);

      const handleClose = () => {
        setDialogOpen(false);
      };

      const handleOpen = () => {
        setDialogOpen(true);
      };

      const handleConfirm = (e: React.MouseEvent<HTMLButtonElement>) => {
        if(onConfirm)
            onConfirm(e)
        handleClose()
      };

      const DeleteModal = (props: {children: React.ReactNode}) => {
          return(
             <BaseModal tooltip={tooltip} disabled={disabled} modalState={{open: dialogOpen, handleOpen: handleOpen, handleClose: handleClose}} modalButton={button}>
                {props.children}
            </BaseModal>
          )
      }

    return(
        <DeleteModal>
            <Grid container>
                <Grid container item style={{marginBottom: 8}}>
                    <Grid item md={2} style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                        <Box display='flex' justifyContent='center' width='100%'>
                            <Box component='span' display='flex' flexDirection='column' justifyContent='center' className={classes.yellowCircle}>
                                <Box display='flex' justifyContent='center' width='100%'>
                                    <WarningAmberOutlined htmlColor={theme.palette.warning.main}/>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item md={10} xs={12}>
                        <Typography variant='h5'>{warningTitle}</Typography>
                    </Grid>
                </Grid>
                <Grid container item style={{marginBottom: 16}}>
                    <Grid item md={2}/>
                    <Grid item md={10}>
                        {(warningText && !children) &&
                        <Typography variant='body1'>
                            {warningText}
                        </Typography>
                        }
                        {children &&
                            children
                        }
                    </Grid>
                </Grid>
                <Grid container item>
                    <Grid item md={6} xs={false}/>
                    <Grid item container spacing={1} md={6} xs={12}>
                        <Grid item xs={6}>
                            <Button type='clear' onClick={handleClose}>
                                <Typography variant='body1'>
                                    Cancel
                                </Typography>
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button type='primary' onClick={handleConfirm}>
                                <Typography variant='body1'>
                                    Confirm
                                </Typography>
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </DeleteModal>
    );
}