import { api, WeatherCode, WeatherModel } from ".";
import { Coordinates } from "../../features/map/MapHelpers";

export interface Forecast {
    observationTime: Date,
    ambientTemperature?: WeatherModel,
    temperatureHigh?: WeatherModel,
    temperatureLow?: WeatherModel,
    dayLight: boolean,
    feelLike?: WeatherModel,
    precipitation?: WeatherModel,
    precipitationChance?: WeatherModel,
    wgbt?: WeatherModel,
    windSpeed?: WeatherModel,
    windDirection: string,
    weather_code?: WeatherCode,
  }

const forecastApi = api.injectEndpoints({
    endpoints: (builder) => ({
        forecastForLocation: builder.query<Forecast, string>({
            query: (id) => `forecast/Today/ForLocation/${id}`,
            transformResponse: (response: { data: Forecast }) => response.data
          }),
          forecastHourlyForLocation: builder.query<Forecast[], string>({
            query: (id) => `forecast/Hourly/ForLocation/${id}`,
            transformResponse: (response: { data: Forecast[] }) => response.data
          }),
          forecastTodayHourly: builder.query<Forecast[], Coordinates>({
            query: ({latitude, longitude}) => `forecast/Today/Hourly?latitude=${latitude}&longitude=${longitude}`,
            transformResponse: (response: { data: Forecast[] }) => response.data
          }),
          forecastTodayHourlyForLocation: builder.query<Forecast[], string>({
            query: (id) => `forecast/Today/Hourly/ForLocation/${id}`,
            transformResponse: (response: { data: Forecast[] }) => response.data
          }),
          forecastDailyForLocation: builder.query<Forecast[], string>({
            query: (id) => `forecast/Daily/ForLocation/${id}`,
            transformResponse: (response: { data: Forecast[] }) => response.data
          })
    })
})

export const {
    useForecastForLocationQuery,
    useForecastHourlyForLocationQuery,
    useForecastTodayHourlyQuery,
    useForecastTodayHourlyForLocationQuery,
    useForecastDailyForLocationQuery
} = forecastApi;