/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button as MuiButton, Divider, Grid, styled, Typography, useMediaQuery } from "@mui/material";
import BaseTimeChart from "../../Base/BaseTimeChart";
import { useTheme } from '@mui/styles';
import { Datum, Serie } from '@nivo/line';
import { Forecast, useForecastDailyForLocationQuery, useForecastHourlyForLocationQuery } from "../../../Services/API";
import { Globe, RainDropOutline, Thermometer, Wind } from "../../../Assets";
import { useRef, useState, useEffect } from "react";
import Link from "../../Base/Link";
import { DaysOfWeek, Month } from "../../../features/Time/TimeConstant";
import { Vector } from "../../../Assets/Vector";
import WeatherIcon from "./WeatherIcon";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { Card } from "../../Base";
import { useAppSelector } from "../../../app/hooks";
import { getSelectedLocation } from "../../../features/dash/dashSlice";
import { SxProps } from "@mui/system";


const ScrollButtonRight = styled(MuiButton)(({ theme }) => ({
    height: '100%',
    borderRadius: 0,
    color: 'transparent',
    '&:hover': {
        borderTopRightRadius: '4px',
        borderBottomRightRadius: '4px',
        background: `linear-gradient(90deg, ${theme.palette.background.default}00 00%, ${theme.palette.background.default}FF 80%)`,
        color: theme.palette.text.primary
    },
    '&.Mui-disabled': {
        color: 'transparent'
    }
}));

const ScrollButtonLeft = styled(MuiButton)(({ theme }) => ({
    height: '100%',
    borderRadius: 0,
    color: 'transparent',
    '&:hover': {
        borderTopLeftRadius: '4px',
        borderBottomLeftRadius: '4px',
        background: `linear-gradient(270deg, ${theme.palette.background.default}00 00%, ${theme.palette.background.default}FF 80%)`,
        color: theme.palette.text.primary
    },
    '&.Mui-disabled': {
        color: 'transparent'
    }
}));
// background: "linear-gradient(196.4deg, #FFE37E -79.78%, #22BACF 134.73%), linear-gradient(207.43deg, #FFE37E -10.71%, #22BACF 131.76%)",

interface ScrollProps {
    scrollRight: boolean,
    scrollLeft: boolean
}

interface ScrollingState {
    isScrolling: boolean,
    clientX: number,
    scrollX: number
}

export default function ForecastBreakdown() {
    const theme = useTheme();
    const selectedLocation = useAppSelector(getSelectedLocation);
    const isXs = useMediaQuery(theme.breakpoints.down('sm'));


    const { data: hourlyData } = useForecastHourlyForLocationQuery(selectedLocation ? (selectedLocation.id || "") : "", { skip: selectedLocation === undefined });
    const { data: dailyData } = useForecastDailyForLocationQuery(selectedLocation ? (selectedLocation.id || "") : "", { skip: selectedLocation === undefined });
    const [forecastType, setForecastType] = useState('hourly');
    const [maxBound, setMaxBound] = useState(100);
    const [minBound, setMinBound] = useState(0);

    const [scrollProps, setScrollProps] = useState<ScrollProps>({ scrollLeft: false, scrollRight: false });
    const [scrollingState, setScrollingState] = useState<ScrollingState>({ isScrolling: false, clientX: 0, scrollX: 0 });

    const ref = useRef<HTMLDivElement>(null);

    const value: string = "1";
    const shadowTextSxProp: SxProps = { textShadow: `-${value}px -${value}px 0 ${theme.palette.background.paper},  ${value}px -${value}px 0 ${theme.palette.background.paper}, -${value}px ${value}px 0 ${theme.palette.background.paper}, ${value}px ${value}px 0 ${theme.palette.background.paper};` };
    // const shadowTextSxProp: SxProps  = { textShadow: `-${value}px -${value}px 0 ${theme.palette.background.paper},  ${value}px -${value}px 0 ${theme.palette.background.paper}, -${value}px ${value}px 0 ${theme.palette.background.paper}, ${value}px ${value}px 0 ${theme.palette.background.paper};` };

    const series = () => {
        let forecastData: Forecast[] = []
        if (forecastType === 'hourly' && hourlyData) {
            forecastData = hourlyData;
        }
        else if (forecastType === 'daily' && dailyData) {
            forecastData = dailyData;
        }

        let datumArr: Datum[] = [];


        if (forecastType === 'hourly') {
            datumArr = forecastData.map((forecast) => {
                let dateStr = '';
                var date = new Date(forecast.observationTime.toString());
                dateStr = date.getFullYear() + "-" + (date.getMonth() + 1).toString().padStart(2, '0') + "-" + date.getDate().toString().padStart(2, '0');
                dateStr += ' ' + date.toLocaleTimeString('en-US');

                return { x: dateStr, y: Math.round(forecast.ambientTemperature!.value) }
            })
        }
        if (forecastType === 'daily') {
            datumArr = forecastData.map((forecast) => {
                let dateStr = '';
                var d = new Date(forecast.observationTime.toString());
                dateStr = d.getFullYear() + "-" + (d.getMonth() + 1).toString().padStart(2, '0') + "-" + d.getDate().toString().padStart(2, '0');

                return { x: dateStr, y: Math.round(forecast.ambientTemperature!.value) }
            })
        }

        let serie: Serie = { id: 'Temp', data: datumArr };

        return ([serie])
    }

    const weatherData = () => {
        let forecastData: Forecast[] = []
        if (forecastType === 'hourly' && hourlyData) {
            forecastData = hourlyData;
        }
        else if (forecastType === 'daily' && dailyData) {
            forecastData = dailyData;
        }

        let data: JSX.Element[] = [];

        if (forecastType === 'hourly') {
            forecastData.forEach((forecast) => {
                var date = new Date(forecast.observationTime.toString());
                var dayOfWeek = date.getDay();
                var hours = date.getHours();
                var hourStr = '';
                var meridianStr = '';

                if (hours === 0) {
                    hourStr = '12';
                    meridianStr = ' AM';
                }
                else if (hours > 12) {
                    hourStr = (hours - 12).toString();
                    meridianStr = ' PM';
                }
                else if (hours === 12) {
                    hourStr = '12';
                    meridianStr = ' PM';
                }
                else {
                    hourStr = hours.toString();
                    meridianStr = ' AM';
                }

                data.push(
                    <Grid key={forecast.observationTime.toString()} item display='flex' flexDirection='column' height={'100%'} justifyContent='space-between' width={'60px'}>
                        <Grid item>
                            <Grid item display='flex' alignItems='center' justifyContent='center'>
                                <Typography variant='h5' sx={shadowTextSxProp}>{DaysOfWeek[dayOfWeek]}</Typography>
                            </Grid>
                            <Grid item display='flex' alignItems='center' justifyContent='center'>
                                <Typography variant='caption' sx={shadowTextSxProp}>{hourStr + meridianStr}</Typography>
                            </Grid>
                            <Grid item display='flex' alignItems='center' justifyContent='center' marginTop={1}>
                                <WeatherIcon code={forecast.weather_code!.value} isDaylight={forecast.dayLight} />
                            </Grid>
                        </Grid>
                        <Grid item display='flex' flexDirection='column' height={150} justifyContent='space-between' marginBottom={1}>
                            <Grid item display='flex' alignItems='center' justifyContent='center'>
                                {/* <Typography sx={{ WebkitTextStroke: `1px ${theme.palette.background.paper}`}}>{Math.round(forecast.ambientTemperature!.value)}°</Typography> */}
                                <Typography sx={shadowTextSxProp}>{Math.round(forecast.ambientTemperature!.value)}°</Typography>
                            </Grid>
                            <Grid item display='flex' alignItems='center' justifyContent='center'>
                                <Typography sx={shadowTextSxProp}>{Math.round(forecast.precipitationChance!.value)}%</Typography>
                            </Grid>
                            <Grid item display='flex' alignItems='center' justifyContent='center'>
                                <Typography sx={shadowTextSxProp}>{Math.round(forecast.feelLike!.value)}°</Typography>
                            </Grid>
                            <Grid item display='flex' alignItems='center' justifyContent='center'>
                                <Typography sx={shadowTextSxProp}>{Math.round(forecast.wgbt!.value)}</Typography>
                            </Grid>
                            <Grid item display='flex' alignItems='center' justifyContent='center'>
                                <Vector transform={`rotate(${forecast.windDirection} 5 5.5)`} />
                                <Typography marginLeft={0.25}>{Math.round(forecast.windSpeed!.value)}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                );
            });
        }
        else if (forecastType === 'daily') {
            forecastData.forEach((forecast) => {
                var date = new Date(forecast.observationTime.toString());
                var month = date.getMonth() + 1;
                var day = date.getDate();
                var dayOfWeek = date.getDay();

                data.push(
                    <Grid key={forecast.observationTime.toString()} item display='flex' flexDirection='column' height={'100%'} justifyContent='space-between' width={'60px'}>
                        <Grid item>
                            <Grid item display='flex' alignItems='center' justifyContent='center'>
                                <Typography variant='h5' sx={shadowTextSxProp}>{DaysOfWeek[dayOfWeek]}</Typography>
                            </Grid>
                            <Grid item display='flex' alignItems='center' justifyContent='center'>
                                <Typography variant='caption' sx={shadowTextSxProp}>{Month[month] + ' ' + day}</Typography>
                            </Grid>
                            {forecast.precipitationChance && forecast.precipitationChance.value > 0 ?
                                <Grid item display='flex' alignItems='center' justifyContent='center'>
                                    <RainDropOutline width={10} height={10} color={theme.palette.text.primary} />
                                    <Typography sx={shadowTextSxProp}>{Math.round(forecast.precipitationChance!.value)}%</Typography>
                                </Grid>
                                :
                                <Grid item display='flex' height={24}>
                                </Grid>
                            }
                            <Grid item display='flex' alignItems='center' justifyContent='center' marginTop={1}>
                                <WeatherIcon code={forecast.weather_code!.value} isDaylight={true} />
                            </Grid>
                        </Grid>
                        <Grid item display='flex' flexDirection='column' height={185} justifyContent='space-between' marginBottom={1}>
                            <Grid item display='flex' alignItems='center' justifyContent='center'>
                                <Typography sx={shadowTextSxProp}>{Math.round(forecast.temperatureHigh!.value)}°</Typography>
                            </Grid>
                            <Grid item display='flex' alignItems='center' justifyContent='center'>
                                <Typography sx={shadowTextSxProp}>{Math.round(forecast.temperatureLow!.value)}°</Typography>
                            </Grid>
                            <Grid item display='flex' alignItems='center' justifyContent='center'>
                                <Typography sx={shadowTextSxProp}>{Math.round(forecast.precipitation!.value * 100) / 100}"</Typography>
                            </Grid>
                            <Grid item display='flex' alignItems='center' justifyContent='center'>
                                <Typography sx={shadowTextSxProp}>{Math.round(forecast.feelLike!.value)}°</Typography>
                            </Grid>
                            <Grid item display='flex' alignItems='center' justifyContent='center'>
                                <Typography sx={shadowTextSxProp}>{Math.round(forecast.wgbt!.value)}</Typography>
                            </Grid>
                            <Grid item display='flex' alignItems='center' justifyContent='center'>
                                <Vector transform={`rotate(${forecast.windDirection} 5 5.5)`} />
                                <Typography sx={shadowTextSxProp} marginLeft={0.25}>{Math.round(forecast.windSpeed!.value)}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                );
            });

        }

        return (
            data
        )

    }

    const onScroll = () => {
        if (ref !== null && ref.current !== null) {
            var shouldScrollLeft = ref.current.scrollLeft !== 0;
            var shouldScrollRight = ref.current.scrollWidth - ref.current.clientWidth !== ref.current.scrollLeft;

            if (scrollProps.scrollLeft !== shouldScrollLeft || scrollProps.scrollRight !== shouldScrollRight)
                setScrollProps({ scrollLeft: shouldScrollLeft, scrollRight: shouldScrollRight })
        }
    }

    const scroll = (scrollOffset: number) => {
        if (ref !== null && ref.current !== null) {
            ref.current.scrollLeft += scrollOffset;
        }
    }

    const onMouseDown = (event: any) => {
        setScrollingState({ ...scrollingState, isScrolling: true, clientX: event.clientX });
    };

    const onMouseUp = (event: any) => {
        setScrollingState({ ...scrollingState, isScrolling: false });
    };

    const onMouseMove = (event: any) => {
        const { clientX, scrollX } = scrollingState;

        if (scrollingState.isScrolling && ref !== null && ref.current !== null) {
            let scrollLeft = scrollX + clientX - event.clientX;

            if (scrollLeft < 0)
                scrollLeft = 0;

            if (ref.current.scrollWidth - ref.current.clientWidth < scrollLeft)
                scrollLeft = ref.current.scrollWidth - ref.current.clientWidth;

            ref.current.scrollLeft = scrollLeft;
            setScrollingState({ ...scrollingState, scrollX: scrollLeft, clientX: event.clientX });

        }
    };

    useEffect(() => {
        if (ref !== null && ref.current !== null) {
            var shouldScrollLeft = ref.current.scrollLeft !== 0;
            var shouldScrollRight = ref.current.scrollWidth - ref.current.clientWidth !== ref.current.scrollLeft;

            if (shouldScrollLeft !== scrollProps.scrollLeft || shouldScrollRight !== scrollProps.scrollRight)
                setScrollProps({ scrollLeft: shouldScrollLeft, scrollRight: shouldScrollRight })
        }
    }, [dailyData, hourlyData])

    useEffect(() => {
        if (forecastType === 'hourly' && hourlyData) {
            let temps: number[] = hourlyData.map((value) => {
                return value.ambientTemperature?.value as number;
            })

            let minTemp = Math.min.apply(Math, temps) - 30;
            let maxTemp = Math.max.apply(Math, temps) + 10;

            setMinBound(minTemp);
            setMaxBound(maxTemp);

        }
        else if (forecastType === 'daily' && dailyData) {
            let temps: number[] = dailyData.map((value) => {
                return value.ambientTemperature?.value as number;
            })

            let minTemp = Math.min.apply(Math, temps) - 30;
            let maxTemp = Math.max.apply(Math, temps) + 10;

            setMinBound(minTemp);
            setMaxBound(maxTemp);
        }

    }, [hourlyData, dailyData, forecastType]);

    return (
        <Card header="Forecast Breakdown">
            <Grid marginBottom={1} marginTop={-6} display='flex' justifyContent='flex-end'>
                <Link inactive={forecastType !== 'hourly'} onClick={() => { setForecastType('hourly') }}><Typography>Hourly</Typography></Link>
                <Divider style={{ height: 16, width: 1, borderColor: theme.palette.divider, margin: 16 }} />
                <Link inactive={forecastType !== 'daily'} onClick={() => { setForecastType('daily') }}><Typography>Daily</Typography></Link>
            </Grid>
            {(hourlyData && forecastType === 'hourly') || (dailyData && forecastType === 'daily') ?
                <Grid container height={340} position='relative'>
                    <Grid item display='flex' maxWidth='80px' width='80px' flexDirection='row' height='100%'>
                        <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
                            {forecastType === 'hourly' ?
                                <Box marginBottom={1} display='flex' justifyContent='flex-start' alignItems='center'>
                                    <Box width={20} height={20}>
                                        <Thermometer width={20} height={20} color={theme.palette.action.inactive} />
                                    </Box>
                                    <Typography variant="body1" marginLeft={1} marginRight={1}>
                                        Temp
                                    </Typography>
                                </Box>
                                : <>
                                    <Box marginBottom={1} display='flex' justifyContent='flex-start' alignItems='center'>
                                        <Box width={20} height={20}>
                                            <Thermometer width={20} height={20} color={theme.palette.action.inactive} />
                                        </Box>
                                        <Typography variant="body1" marginLeft={1} marginRight={1}>
                                            High
                                        </Typography>
                                    </Box>
                                    <Box marginBottom={1} display='flex' justifyContent='flex-start' alignItems='center'>
                                        <Box width={20} height={20}>
                                            <Thermometer width={20} height={20} color={theme.palette.action.inactive} />
                                        </Box>
                                        <Typography variant="body1" marginLeft={1} marginRight={1}>
                                            Low
                                        </Typography>
                                    </Box>
                                </>}
                            <Box marginBottom={1} display='flex' justifyContent='flex-start' alignItems='center'>
                                <Box width={20} height={20}>
                                    <RainDropOutline width={20} height={20} color={theme.palette.action.inactive} />
                                </Box>
                                <Typography variant="body1" marginLeft={1} marginRight={1}>
                                    Precip
                                </Typography>
                            </Box>
                            <Box marginBottom={1} display='flex' justifyContent='flex-start' alignItems='center'>
                                <Box width={20} height={20}>
                                    <Thermometer width={20} height={20} color={theme.palette.action.inactive} />
                                </Box>
                                <Typography variant="body1" marginLeft={1} marginRight={1}>
                                    Feels
                                </Typography>
                            </Box>
                            <Box marginBottom={1} display='flex' justifyContent='flex-start' alignItems='center'>
                                <Box width={20} height={20}>
                                    <Globe width={20} height={20} color={theme.palette.action.inactive} />
                                </Box>
                                <Typography variant="body1" marginLeft={1} marginRight={1}>
                                    WBGT
                                </Typography>
                            </Box>
                            <Box marginBottom={1} display='flex' justifyContent='flex-start' alignItems='center'>
                                <Box width={20} height={20}>
                                    <Wind width={20} height={20} color={theme.palette.action.inactive} />
                                </Box>
                                <Typography variant="body1" marginLeft={1} marginRight={1}>
                                    Wind
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>

                    {(!scrollingState.isScrolling && !isXs) &&
                        <Box position='absolute' width='50px' left='78px' height='100%' zIndex={5}>
                            <ScrollButtonLeft disabled={!scrollProps.scrollLeft} onClick={() => scroll(-240)}>
                                <KeyboardArrowLeftIcon sx={{ width: 50, height: 50 }} />
                            </ScrollButtonLeft>
                        </Box>}

                    {(!scrollingState.isScrolling && !isXs) &&
                        <Box position='absolute' width='50px' right='16px' height='100%' zIndex={5}>
                            <ScrollButtonRight disabled={!scrollProps.scrollRight} onClick={() => scroll(240)}>
                                <KeyboardArrowRightIcon sx={{ width: 50, height: 50 }} />
                            </ScrollButtonRight>
                        </Box>
                    }

                    <Grid ref={ref} className="hidden-scrollbar noselect " item
                        sx={{ flexGrow: 1, position: 'relative', height: 'inherit', overflowX: 'hidden', overflowY: 'hidden', scrollbarWidth: 'none', '&::webkit-scrollbar-thumb': {display: 'none'},
                                '&:hover':{ overflowX: 'scroll'}, '&::-webkit-scrollbar': {display: 'none !important'}, cursor: scrollingState.isScrolling ? 'grabbing' : 'grab'
                            }}
                        onMouseDown={onMouseDown}
                        onMouseUp={onMouseUp}
                        onMouseMove={onMouseMove}
                        onMouseLeave={onMouseUp}
                        onScroll={() => onScroll()}>
                        <BaseTimeChart
                            style={{
                                margin: 0,
                                height: 225,
                                width: forecastType === 'hourly' ? '2160px' : '420px',
                                position: 'absolute',
                                bottom: 0
                            }}
                            chartMargin={{ top: 50, bottom: 15 }}
                            curve={'natural'}
                            min={minBound}
                            max={maxBound}
                            lineWidth={3}
                            data={series()}
                            enableAxes={false}
                            enableGridX={false}
                            enableActivePoint={false}
                            colors={['#EFE58B']}
                            formatType={forecastType === 'hourly' ? 'hour' : 'day'} />
                        <Grid container width={forecastType === 'hourly' ? '2160px' : '420px'} height={'inherit'} flexDirection='row' position='absolute'>
                            {weatherData()}
                        </Grid>
                    </Grid>
                </Grid>
                :
                <Typography>No Data</Typography>}
        </Card>
    )
}