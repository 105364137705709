import { api } from ".";

export interface Communication{
    recipients: string[],
    message: string,
    channel: number
  }

interface Recipient {
    messageId: string,
    recipientId: string,
    email: string,
    phoneNumber: string,
    recipientName: string
}

export interface MassMessageHistory {
    id: string,
    message: string,
    created: string,
    channel: number,
    deliveredRecipients: Recipient[],
    undeliveredRecipients: Recipient[]
}

export interface CommunicationErrorModel{
    Recipients?: string[],
    Message?: string[],
    Channel?: string[],
    ServerError?: string[]
}

const alertTimesApi = api.injectEndpoints({
    endpoints: (builder) => ({
        massMessageHistory: builder.query<MassMessageHistory[], void>({
            query: () => ({
                url:'Communication/Mass/History',
                method: 'GET'
            }),
            providesTags: ["CommunicationHistory"],
            transformResponse: (response: { data: MassMessageHistory[] }) => response.data
        }),
        sendMassMessage: builder.mutation<string, Communication>({
            query: (communication) => ({
              url: 'Communication/Mass/Send',
              method: 'POST',
              body: communication
            }),
            invalidatesTags: ["CommunicationHistory"],
            transformResponse: (response: { data: string}) => response.data
        })
    })
})

export const {
    useMassMessageHistoryQuery,
    useSendMassMessageMutation
} = alertTimesApi;