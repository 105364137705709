import { Box, Card, useTheme, Typography, CardActions, CardHeader, CardContent, Grid, useMediaQuery } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { BatteryIcon } from '../../Assets/Battery';
import { CellularNetworkIcon } from '../../Assets/CellularNetwork';
import { WifiIcon } from '../../Assets/Wifi';
import { Button, Checkbox, Link, Menu, PWMenuItem, Tooltip, WxImage } from '../Base';
import { LoadingButton } from '../Base/LoadingButton';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import { HardwareFormModal } from "./HardwareFormModal";
import StaticMap from "../Base/StaticMap";
import { useState } from 'react';
import { Hardware, useOwsQuery, useWeatherStationQuery, OWS, useOwsTestAllClearMutation, useOwsTestDelayMutation, useOwsTestLightMutation, useOwsTurnOffMutation } from '../../Services/API';
import { HardwareFilterTypes, HardwareTypes, SirenTests } from '../../Constants/Constants';
import { getTimeDifferenceInMins, formatTimeStringFromMins, ConvertToUtc } from '../../features/Time/TimeHelpers';
import { Checkmark } from '../../Assets/Checkmark';
import HardwareStatus from './HardwareStatus';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

interface CardProps {
    hardware: Hardware;
    selectHardware: (isSelected: boolean, siren: OWS) => void;
    isSelected: (sirenId: string) => boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        card: {
            height: '100%',
            border: '1px solid',
            borderColor: theme.palette.border.main
        },
        header: {
            padding: '16px 16px 8px 16px',
            width: '100%'
        },
        headerContent: {
            alignItems: 'flex-start',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column-reverse'
        },
        media: {
            display: 'flex',
            width: '100%',
            objectFit: 'cover',
            marginLeft: 'auto',
            marginRight: 'auto',
            borderRadius: 8,
            filter: 'drop-shadow(0px 8px 36px rgba(0, 0, 0, 0.25))'
        },
        content: {
            padding: '16px 16px 8px 16px',
        },
        hrVertical: {
            border: '0.75px solid',
            borderColor: theme.palette.divider,
            height: 'auto'
        },
        buttonAction: {
            justifyContent: 'center',
            padding: '8px 16px 0px 16px',
        },
        linkAction: {
            justifyContent: 'center',
            paddingTop: '20px',
            '& .MuiIconButton-root:hover': {
                background: 'none'
            }
        },
        turnOffButton: {
            width: '100%',
        },
    }));

export function HardwareCard(props: CardProps) {
    const { selectHardware, isSelected, hardware } = props;

    const { data: siren } = useOwsQuery(hardware.id ? hardware.id : '');
    const { data: wx } = useWeatherStationQuery(hardware.id ? hardware.id : '', {pollingInterval: 15 * 60000, refetchOnReconnect: true} );

    const [testDelay] = useOwsTestDelayMutation();
    const [testAllClear] = useOwsTestAllClearMutation();
    const [testLight] = useOwsTestLightMutation();
    const [turnOff] = useOwsTurnOffMutation();

    const theme = useTheme();

    const isXs = useMediaQuery(theme.breakpoints.down('sm'));

    const classes = useStyles(theme);

    const [anchorEl, setAnchorEl] = useState(null);
    const [isTesting, setTesting] = useState(false);
    const [testType, setTestType] = useState<SirenTests | null>(null);

    const open = Boolean(anchorEl);

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (event: any) => {
        setAnchorEl(null);
        var testName = event.target.textContent;
        if (siren) {
            switch (testName) {
                case SirenTests.Delay:
                    setTesting(true);
                    setTestType(event.target.textContent);
                    testDelay([siren.id])
                    break;
                case SirenTests.AllClear:
                    setTesting(true);
                    setTestType(event.target.textContent);
                    testAllClear([siren.id])
                    break;
                case SirenTests.Light:
                    setTesting(true);
                    setTestType(event.target.textContent);
                    testLight([siren.id])
                    break;
            }
        }
    };

    const handleSelect = () => {
        if (siren) {
            let shouldSelect = isSelected(siren.id)
            selectHardware(!shouldSelect, siren);
        }
    }

    const handleFinishLoading = () => {
        if(testType === SirenTests.AllClear)
        {
            setTesting(false);
            setTestType(null);
        }
    }

    const resetTesting = () => {
        if(siren)
        {
            turnOff([siren.id])
        }
        setTesting(false);
        setTestType(null);
    };

    const HardwareTypeContainer = () => {
        return (
            <Grid xs={3} item>
                <Box display='flex' alignItems='center' justifyContent='center'>
                    <CellularNetworkIcon color={theme.palette.text.secondary} />
                    <Typography variant='overline' style={{ marginLeft: 8, color: theme.palette.text.secondary }}>Type</Typography>
                </Box>
                {(hardware.modelId === HardwareTypes.OWX || hardware.modelId === HardwareTypes.OWS) &&
                    <Box padding='4px'>
                        <Tooltip title={<Typography>{HardwareFilterTypes.OWS}</Typography>} arrow placement="bottom" enterDelay={300} leaveDelay={200}>
                            <Typography>{HardwareTypes.OWS}</Typography>
                        </Tooltip>
                    </Box>
                }
                {(hardware.modelId === HardwareTypes.OWX || hardware.modelId === HardwareTypes.WXS) &&
                    <Box padding='4px'>
                        <Tooltip title={<Typography>{HardwareFilterTypes.WXS}</Typography>} arrow placement="bottom" enterDelay={300} leaveDelay={200}>
                            <Typography>{HardwareTypes.WXS}</Typography>
                        </Tooltip>
                    </Box>
                }
            </Grid>
        )
    };

    const HardwareConnectedContainer = () => {
        let lastConnectedMins = Math.round(getTimeDifferenceInMins(ConvertToUtc(hardware.connectedAt)));
        let lastUpdatedMins = wx && wx.lastUpdated ? Math.round(getTimeDifferenceInMins(ConvertToUtc(wx.lastUpdated))) : undefined;

        let date = new Date();
        let utcHour = date.getUTCHours();

        let isScheduledOffline = (lastConnectedMins >= 30 || (lastUpdatedMins !== undefined && lastUpdatedMins >= 15)) && utcHour >= 5 && utcHour < 11;

        let lastConnectedStr = formatTimeStringFromMins(lastConnectedMins);
        let lastUpdatedStr = lastUpdatedMins !== undefined ? formatTimeStringFromMins(lastUpdatedMins) : undefined;

        return (
            <Grid xs={4} item>
                <Box display='flex' alignItems='center' justifyContent='center'>
                    <WifiIcon color={theme.palette.text.secondary} />
                    <Typography variant='overline' style={{ marginLeft: 8, color: theme.palette.text.secondary }}>Connected</Typography>
                </Box>
                {(hardware.modelId === HardwareTypes.OWX || hardware.modelId === HardwareTypes.OWS) ?
                    !isScheduledOffline ?
                        <Box padding='4px'>
                            <HardwareStatus
                                gridProps={{display:'flex', alignItems:'center', justifyContent:'space-evenly'}}
                                tooltipText={<Typography>Last connected {lastConnectedStr}</Typography>}
                                status={lastConnectedMins < 30 ? 'Healthy' : 'Unhealthy'} />
                        </Box>
                        :
                        <Typography>Scheduled Offline</Typography>
                    : <></>
                }

                {(hardware.modelId === HardwareTypes.OWX || hardware.modelId === HardwareTypes.WXS) && lastUpdatedMins !== undefined ?
                    !isScheduledOffline ?
                        <Box padding='4px'>
                            <HardwareStatus
                                gridProps={{display:'flex', alignItems:'center', justifyContent:'space-evenly'}}
                                tooltipText={<Typography>Last connected {lastUpdatedStr}</Typography>}
                                status={lastUpdatedMins < 15 ? 'Healthy' : 'Unhealthy'} />
                        </Box>
                        :
                        <Typography>Scheduled Offline</Typography>
                    :
                    <></>
                }
            </Grid>
        )
    };

    const HardwareBatteryContainer = () => {

        return (
            <Grid xs={4} item>
                <Box display='flex' alignItems='center' justifyContent='center'>
                    <BatteryIcon color={theme.palette.text.secondary} />
                    <Typography variant='overline' style={{ marginLeft: 8, color: theme.palette.text.secondary }}>Battery</Typography>
                </Box>
                {hardware.voltage !== 0 &&
                    <Box padding='4px'>
                        <HardwareStatus
                            gridProps={{display:'flex', alignItems:'center', justifyContent:'space-evenly'}}
                            tooltipText={<Typography>{Math.round(hardware.voltage * 10) / 10} volts (rechargeable)</Typography>}
                            status={hardware.voltage >= 12.1 ? 'Healthy' : 'Unhealthy'} />
                    </Box>
                }
            </Grid>
        );
    };

    const activeContainer = () => {
        if (hardware.enabled) {
            return (
                <>
                    <Checkmark />
                    <Typography ml={1} variant='overline' style={{ color: theme.palette.text.secondary }}>Enabled</Typography>
                </>
            );
        }
        else {
            return (
                <>
                    <Typography component='span' variant='subtitle1' style={{ color: theme.palette.error.main }}>X</Typography>
                    <Typography ml={1} variant='overline' style={{ color: theme.palette.error.main }}>Disabled</Typography>
                </>
            );
        }
    }

    const GetTestDuration = () => {
        let duration = 10;
        if (siren && testType != null) {
            switch (testType) {
                case SirenTests.Delay:
                    return duration + siren.duration * siren.frequency;
                case SirenTests.AllClear:
                    return duration + siren.allClearDuration * siren.allClearFrequency;
                case SirenTests.Light:
                    return 3;
            }
        }
        return undefined;
    }

    return (
        <Card className={classes.card} sx={{ width: isXs ? 300 : 370 }}>
            <CardHeader
                classes={{
                    root: classes.header,
                    content: classes.headerContent,
                }}
                title={<Typography variant='h5' sx={{ textOverflow: 'ellipsis' }} noWrap>{hardware.name}</Typography>}
                subheader={activeContainer()}
                action={siren ? <Checkbox onChange={handleSelect} checked={isSelected(siren.id)} /> : <></>}>
            </CardHeader>
            {wx?.id ?
                <Box sx={{ display: 'flex', justifyContent: 'center', margin: '0px 16px' }}>
                    <WxImage
                        style={{ border: '1px solid', borderColor: theme.palette.border.input, backgroundColor: theme.palette.background.disabled, width: 360, height: 150 }}
                        showStatus={false}
                        weatherStationId={wx.id} />
                </Box>
                :
                <Box style={{ height: 150, margin: '0px 16px', borderRadius: 8, border: 'solid 1px', borderColor: theme.palette.border.main }}>
                    {hardware.latitude !== undefined && hardware.longitude !== undefined &&
                        <StaticMap latitude={hardware.latitude} longitude={hardware.longitude} />}
                </Box>}
            <CardContent className={classes.content}>
                <Grid container direction='row' style={{ display: 'flex' }}>
                    {HardwareTypeContainer()}
                    <hr className={classes.hrVertical} />
                    {HardwareConnectedContainer()}
                    <hr className={classes.hrVertical} />
                    {HardwareBatteryContainer()}
                </Grid>
            </CardContent>
            {siren && <CardActions className={classes.buttonAction} disableSpacing>
                <LoadingButton onClick={handleClick} isLoading={isTesting} duration={GetTestDuration()}
                    loadingText={`Running Test of ${testType}...`}
                    finishedLoading={handleFinishLoading}>
                    <Typography variant='body1'>
                        Select Test
                    </Typography>
                    <ArrowDropDownOutlinedIcon />
                </LoadingButton>
                <Menu open={open} anchorEl={anchorEl} onClose={handleClose}>
                    <PWMenuItem onClick={handleClose}>{SirenTests.Delay}</PWMenuItem>
                    <PWMenuItem onClick={handleClose}>{SirenTests.AllClear}</PWMenuItem>
                    <PWMenuItem onClick={handleClose}>{SirenTests.Light}</PWMenuItem>
                </Menu>
            </CardActions>
            }
            {siren && testType !== null &&
                <CardActions className={classes.buttonAction} disableSpacing>
                    <Button style={{ width: '100%' }} onClick={resetTesting} type='clearBlue' disabled={testType == null}>
                        <Typography>
                            Stop Test
                        </Typography>
                    </Button>
                </CardActions>
            }
            <CardActions className={classes.linkAction} disableSpacing>
                <Box display='flex' justifyContent='space-evenly' width='100%'>
                    {siren && <HardwareFormModal
                        hardwareId={hardware.id}
                        voltage={hardware.voltage}
                        lastConnected={hardware.connectedAt}
                        timeZone={hardware.timeZoneId}
                        enabled={hardware.enabled}
                        />
                    }
                    {wx &&
                        <Link href={`/Historical/${wx.id}`}>
                            <Typography noWrap>Weather Data</Typography>
                            <ArrowForwardIcon sx={{width: 20, height: 16}}/>
                        </Link>
                    }
                </Box>
            </CardActions>

        </Card>

    )
}
export default HardwareCard