import React from 'react';
import Page from '../Components/Base/Page';
import { Grid, Typography } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

export function Help() {
	return (

      <Page docTitle='Help' title={<Typography variant="h3" color="textPrimary" noWrap>Help</Typography>}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
			  <Skeleton>

			  </Skeleton>
          </Grid>
        </Grid>

      </Page>
	);
}

export default Help;