/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Page, Card, Button, SubCardContainer, TextField,  styledLinkClass} from '../Components/Base';
import { Box, Grid, Typography, useTheme } from '@mui/material';
import { ChangePasswordModal } from '../Components/Profile/ChangePasswordModal';
import { PWError, User, useUpdateProfileMutation, useUserQuery } from '../Services/API';
import { useDispatch } from 'react-redux';
import { openToast } from '../features/toast/toastSlice';

export function Profile() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const linkClass = styledLinkClass(theme);

  const {data: userState} = useUserQuery();
  const [openChangePw, setChangePwOpen] = useState(false);

  const [profile, setProfile] = useState<User>({id: '',userName: '', firstName: '', lastName: '', fullName: '', email: '', roleId: '', roleName: '', roleHierarchy: 4, siteId: 0, siteName: '', phoneNumber: '', customerId: 0, timeZone: ''});

  useEffect(() => {
    if(userState){
      setProfile(userState)
    }
  },[userState]);

  const [updateProfile, {data, isSuccess, isError, error}] = useUpdateProfileMutation();

  useEffect(() => {
      if(isSuccess){
        dispatch(openToast({variant: 'success', header: data || ''}));
      }
      else if(isError)
        dispatch(openToast({variant: 'error', header: (error as PWError).data.responseException.errors || ''}))
  },[isSuccess, isError, error])

	return (

      <Page docTitle='My Profile' title={<Typography variant="h3" color="textPrimary" noWrap>My Profile</Typography>}>
        <Grid item md={7} sm={12}>
            <Card>
              {userState && userState.userName !== userState.email &&
                <>
                <SubCardContainer title='User Name'>
                  <TextField value={profile.userName} disabled/>
                </SubCardContainer>
                <hr style={{margin:'24px 0'}}/>
              </>
              }
              <SubCardContainer title='First Name'>
                <TextField value={profile.firstName} onChange={e => setProfile({...profile, firstName: e.target.value})} placeholder='Perry Weather'/>
              </SubCardContainer>
              <hr style={{margin:'24px 0'}}/>
              <SubCardContainer title='Last Name'>
                <TextField value={profile.lastName} onChange={e => setProfile({...profile, lastName: e.target.value})} placeholder='Perry Weather'/>
              </SubCardContainer>
              <hr style={{margin:'24px 0'}}/>
              <SubCardContainer title='Email'>
                <TextField value={profile.email === null ? undefined : profile.email} onChange={e => setProfile({...profile, email: e.target.value, userName: userState ? userState.userName === userState.email ? e.target.value : userState.userName : profile.userName})} placeholder='perry@weather.com'/>
              </SubCardContainer>
              <hr style={{margin:'24px 0'}}/>
              <SubCardContainer title='Phone Number' desc={
                  <Typography textAlign='left' variant='body2' color='textSecondary'>
                    Used for text (SMS) messages, if selected in your <a className={linkClass.link} rel='noreferrer' target='_blank' href='/NotificationSettings'> settings</a>.
                  </Typography>
              }>
                <TextField value={profile.phoneNumber === null ? undefined : profile.phoneNumber} onChange={e => setProfile({...profile, phoneNumber: e.target.value})} placeholder='1234567890'/>
              </SubCardContainer>
              <hr style={{margin:'24px 0'}}/>
              <SubCardContainer title='Password'>
                <Grid container justifyContent='center'>
                  <Button type='clear' onClick={e => setChangePwOpen(true)}>
                    <Typography variant='body1'> Change Password </Typography>
                  </Button>
                </Grid>
              </SubCardContainer>
              <Box display='flex' justifyContent='flex-end' marginTop='24px'>
                <Button>
                  <Typography variant='body1' onClick={e => updateProfile(profile)}>
                    Save Changes
                  </Typography>
                </Button>
              </Box>
            </Card>
          </Grid>
          <ChangePasswordModal modalState={{open: openChangePw, handleClose: () => setChangePwOpen(false), handleOpen: () => false}}/>
      </Page>
	);
}

export default Profile;