import { ArrowDropDown } from "@mui/icons-material";
import { Skeleton, styled, Typography, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import { Margin } from "@nivo/core";
import { Datum, Point } from "@nivo/line";
import React, { CSSProperties, useEffect, useRef, useState } from "react";

import { Forecast, useForecastTodayHourlyQuery, useHistoricalQuery, WeatherStationAvg } from "../../Services/API";
import { Card, Menu as PWMenu, PWMenuItem} from "../Base";
import BaseTimeChart from "../Base/BaseTimeChart";

type Conditions = 'WBGT' | 'WIND' | 'TEMP' ;

interface ConditionsTimelineCardProps{
    stationId: string,
    latitude: number,
    longitude: number,
    hours?: number,
    bottomTickValues?: number,
    style?: CSSProperties,
    chartMargin?: Partial<Margin>,
    children?:  React.ReactNode
}

const ForecastContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    position: 'absolute',
    flexDirection: 'column',
    justifyContent: 'space-around',
    backgroundColor: theme.palette.background.focus,
    padding: '4px 16px',
    right: 10,
    top: 5,
    borderRadius: 8,
    border: '1px solid',
    borderColor: theme.palette.border.main
}));


const setUpChartData = (historicalData: {value: number, date: Date}[]) => {
    var histDatum: Datum[] = [];

    if(historicalData.length < 2)
        return [];

    historicalData.forEach((p) => {
        let dateStr = '';
        var date =  new Date(p.date.toString() + 'Z');
        dateStr = date.getFullYear()  + "-" + (date.getMonth()+1).toString().padStart(2, '0') + "-" + date.getDate().toString().padStart(2, '0');
        dateStr += ' ' + date.toLocaleTimeString('en-US');
        histDatum.push({x: dateStr, y: p.value});
    });

    return [{id: 'Actual', data: histDatum}];
}

const getMax = (historicalData: number[]) => {
    let max = Math.max.apply(Math, historicalData);
    return max;
}

const getMin = (historicalData: number[]) => {
    let min = Math.min.apply(Math, historicalData);
    return min;
}

const renderMaxForecast = (historicalData: number[], data: {value: number, observationTime: Date}[]) => {
    var forecast = data.reduce(function (p, v) {
        return (p.value > v.value ? p : v );
    });

    var maxValue = forecast.value;
    var forecastHours = new Date(forecast.observationTime).getHours();
    var currentHours = new Date().getHours();
    var histMax = getMax(historicalData);
    if(maxValue > histMax && forecastHours > currentHours)
    {
        return(
            <ForecastContainer>
                <div>
                    <Typography textAlign='left'>Forecast Max</Typography>
                </div>
                <div>
                    <Typography textAlign='left'>
                        {maxValue + ' @ ' +  new Date(forecast.observationTime).toLocaleString('en-US', {hour12: true, hour: 'numeric'})}
                    </Typography>
                </div>
            </ForecastContainer>
        );
    }

    return <></>;
}

const generateCustomPointLabel = (datum: Point['data'], data: {value: number, date: Date}[], precision: number = 0) => {
    var numOfPoints = data.length;
    var remainder = numOfPoints % 2;

    var minsList = data.map((value) =>
    {
        var date =  new Date(value.date.toString() + 'Z');
        return date.getHours() * 60 + date.getMinutes();
    })

    var d = new Date(datum.x);
    var curMins = d.getHours() * 60 + d.getMinutes();

    var index = minsList.findIndex(val => curMins === val)
    var indexRemainder = (index + 1) % 2;
    return remainder === indexRemainder ? Number(datum.yFormatted).toFixed(precision) : '';
}

export function ConditionsTimeline(props: ConditionsTimelineCardProps)
{
    const {stationId, latitude, longitude, style, chartMargin, children, bottomTickValues, hours = 6} = props;
    const theme = useTheme();

    const {data, isLoading: historicalDataLoading, isFetching: historicalFetching}  = useHistoricalQuery({id: stationId, hours: hours});
    const {data: forecastData}  = useForecastTodayHourlyQuery({latitude: latitude, longitude: longitude});
    // const {data: policyData}  = useSitePoliciesQuery();

    const [anchor, setAnchor] = useState(null);
    const [selectedCondition, selectCondition] = useState<Conditions>(localStorage.getItem('sel_cond') === null ? 'WBGT' : localStorage.getItem('sel_cond') as Conditions);
    const [selectedCondDis, setSelectedConDis] = useState<string>('');
    const [selectedData, setSelectedData] = useState<{value: number, date: Date}[]>([])
    const [selectedForecast, setSelectedForecast] = useState<{value: number, observationTime: Date}[]>([])
    const [color, setColor] = useState(['white']);
    const open = Boolean(anchor);
    const chartRef = useRef(null);

    const handleClose = (event: any) => {
        setAnchor(null); 
        if(event.target.id){
            selectCondition(event.target.id as Conditions || selectedCondition);
            setSelectedConDis(event.target.textContent as string);
        }
    };

    useEffect(() => 
    {
        if(data){
            setSelectedData(data.map(x => {
                let curCond = localStorage.getItem('sel_cond');
                if(curCond !== selectedCondition)
                    localStorage.setItem('sel_cond', selectedCondition);

                switch (selectedCondition) {
                    case 'TEMP':
                        setSelectedConDis('Temperature (°F)');
                        setColor([theme.palette.warning.main]);
                        return {value: Math.round(x.avgAmbientTemperature!), date: x.date};
                    case 'WIND':
                        setSelectedConDis('Wind Speed (mph)');
                        setColor([theme.palette.primary.main]);
                        return {value: Math.round(x.maxWindSpeed!), date: x.date};
                    case 'WBGT':
                    default:
                        setSelectedConDis('WBGT');
                        setColor(['#EE610C']);
                        return {value: (Math.round(x.maxWetBulbGlobalTemp! * 10) /10), date: x.date};
                }
            }));
        }
    },[data,selectedCondition]);

    useEffect(() => 
    {
        if(forecastData){
            setSelectedForecast(forecastData.map(x => 
                {
                    switch (selectedCondition) {
                        case 'WIND':
                            return {value: Math.round(x.windSpeed?.value!), observationTime: x.observationTime};
                        case 'TEMP':
                            return {value: Math.round(x.ambientTemperature?.value!), observationTime: x.observationTime};
                        case 'WBGT':
                        default:
                            return {value: Math.round(x.wgbt?.value! * 10) / 10, observationTime: x.observationTime};
                    }
                }
            ))
        }
    },[forecastData, selectedCondition])

    return(
        <Card fullContent>
            {(!historicalFetching && !historicalDataLoading && data )?
                <>
                    <BaseTimeChart
                        title={
                            <div style={{display: 'flex', justifyContent: 'flex-start'}}>
                                <Box sx={{display: 'inline-flex', '&:hover':{cursor: 'pointer'}}} onClick={e => setAnchor(chartRef.current)}>
                                    <div ref={chartRef}>
                                        <Typography variant='subtitle1' textAlign='left'>{selectedCondDis}</Typography>
                                    </div>
                                    <ArrowDropDown />
                                </Box>
                                <PWMenu open={open} anchorEl={anchor} onClose={handleClose}>
                                    <PWMenuItem id="WBGT" onClick={handleClose}>WBGT</PWMenuItem>
                                    <PWMenuItem id="WIND" onClick={handleClose}>Wind Speed (mph)</PWMenuItem>
                                    <PWMenuItem id="TEMP" onClick={handleClose}>Temperature (°F)</PWMenuItem>
                                </PWMenu>
                            </div>
                        }
                        style={style}
                        curve='natural'
                        leftTickValue={4}
                        bottomTickValue={bottomTickValues}
                        chartMargin={chartMargin}
                        data={setUpChartData(selectedData)} max={getMax(selectedData.map(x => x.value)) + 10} min={getMin(selectedData.map(x => x.value)) - (selectedCondition === 'WIND' ? 0 : 5)}
                        // markers={getMarkers(policyData)}
                        customPointLabel={(d) => generateCustomPointLabel(d, selectedData, (selectedCondition === 'WBGT' ? 1 : 0))}
                        enableActivePoint={false}
                        enablePoints={true}
                        pointSize={8}
                        colors={color}
                        formatType={'minute'}/>
                    {selectedForecast.length > 0 && renderMaxForecast(selectedData.map(x => x.value), selectedForecast)}
                    {children}
                </>
                :
                <Skeleton height='100%' style={{minHeight: 200}}/>
            }
        </Card>
    )
}

export default ConditionsTimeline;