import { Viewport } from "../Util";
import { Map } from 'maplibre-gl';
import { useStormVectorsGeoJsonQuery } from "../../../../Services/API";
import { Layer, LayerProps, Source } from "react-map-gl";

export interface MapProps {
	viewport: Viewport,
	mapRef: Map
}

export function StormVectorLayer () {

    const { data } = useStormVectorsGeoJsonQuery(null, { pollingInterval: 1000 * 60 * 5});

    const isBaron = ['to-boolean', ['get', 'Type']];
    const isTvs = ['get', 'Tvs', ['get', 'Flags']];
    const isMeso = ['get', 'Meso', ['get', 'Flags']];
    const isSevereHail = ['get', 'SevereHail', ['get', 'Flags']];
    const isHail = ['get', 'Hail', ['get', 'Flags']];

    const isTornadoHighProb = ['==', 'Tornado – High Probability', ['get', 'Type']];
    const isTornadoLowProb = ['==', 'Tornado – Low Probability', ['get', 'Type']];
    const isHighWinds = ['==', 'High Winds', ['get', 'Type']];
    const isExtremeHail = ['==', 'Extreme Hail', ['get', 'Type']];
    const isBaronHail = ['==', 'Hail', ['get', 'Type']];

    const pointLayer: LayerProps = {
        type: 'circle',
        id: 'storm-vector-point',
        paint: {
            'circle-radius': 8,
            'circle-color': ['case',
                isBaron,
                ['case',
                    isTornadoHighProb, '#FD0001',
                    isTornadoLowProb, '#7F0001',
                    isHighWinds, '#FE9900',
                    isExtremeHail, '#00007E',
                    isBaronHail, '#0000FE',
                    '#878787'],
                ['case',
                    isTvs, '#FF0018',
                    isMeso, '#8E00FF',
                    isSevereHail, '#0C0875',
                    isHail, '#2DA4FE',
                    '#878787']
            ]
        }
	};

    const vectorLayer: LayerProps = {
        type: 'symbol',
        id: 'vector-symbol',
        layout: {
            'icon-image': 'triangle-outline',
            'icon-size': 0.7,
            'icon-padding': 0,
            'icon-rotate': ['+', 180, ['get', 'Value', ['get', 'Heading', ['get', 'Movement']]]],
            'icon-offset': [0, -30],
            'icon-allow-overlap': true,
        },
        paint: {
            'icon-color': ['case', isBaron, '#4169e1', '#000']
        }
	};


    return(data ?
        <Source id="storm-vectors" type="geojson" data={data} maxzoom={15}>
            <Layer {...vectorLayer} />
            <Layer {...pointLayer} />
        </Source>
        : <></>
    );
}

export default StormVectorLayer;
