export enum SirenTests {
    Delay = 'Start Delay',
    AllClear = 'Start All Clear',
    Light = 'Turn On Light',
    PAMessage = 'Send PA Message'
}

export enum HardwareFilterTypes {
    ALL = 'All',
    OWS = 'Outdoor Warning System',
    WXS = 'Weather Station'
  }

  export enum HardwareTypes {
    OWX = 'OWX',
    OWS = 'OWS',
    WXS = 'WXS'
  }

export enum ThreatLevel {
    NONE = 0,
    MODERATE = 1,
    SEVERE = 2
}

export type PolicyTypeName = 'WBGT' | 'WIND' | 'GUST' | 'AQI' | 'CHILL' | 'HEAT' | 'PRECIP' | 'AQI' | 'LR1' | 'LR2' | 'LR3';

export type ThreatTypes = PolicyTypeName | 'NWS' | 'NONE';
