import {
	Dashboard,
	Hardware,
	Historical,
	Notifications,
	Consultation,
	Users,
	NotificationSettings,
	Policies,
	Help,
	Profile,
	Unauthorized,
	// Ian,
	PAStoredMessages,
	MassNotify,
	AirQuality
} from './Pages'
import { Counter } from './features/counter/Counter';
import { Redirect, Route, useLocation } from 'wouter';
import Menu from './Components/Shared/MainMenu/MainMenu';
import Callback from './Pages/Callback';
import SilentRenew from './Pages/SilentRenew';
import { useAuth } from 'oidc-react';
import { useDispatch } from 'react-redux';
import { getNeedsLogin, setCredentials } from './Services/Auth/authSlice';
import { HasPermissionToView } from './utils/utils';

import { useAppSelector } from './app/hooks';
import { LEGACY_REDIRECT_URL } from './Services/API/api';
import { useEffect, useMemo } from 'react';
import { HardwareMap } from './Pages/HardwareMap';
import { LightningMapBeta } from './Pages/LightningMapBeta';

interface RoutesProps {
	toggleTheme: Function
}


export default function Routes(props: RoutesProps) {
	const { toggleTheme } = props;
	let auth = useAuth();
	let dispatch = useDispatch();
	const needsLogin: Boolean = useAppSelector(getNeedsLogin);

	const [location] = useLocation();

	useEffect(() => {
		if(!auth.isLoading) return;

        let signInTimeout = setTimeout(() => {
            console.debug('sign in timed out');
            window.location.replace('/');
        }, 30000);

		return () => {
			clearTimeout(signInTimeout);
		}

	},[auth])


	// window.location.hash
	if (auth.isLoading) {
		return (<>Loading...</>);
	}
	else if (auth.userData && !needsLogin) {
		// if (auth.userData.profile && auth.userData.profile.Role === 'Super Admin') // Super Admin Check for now...
		if (auth.userData.profile && HasPermissionToView(auth.userData)) {
			dispatch(setCredentials({ token: auth.userData!.access_token }))
			return (
				<>
					<Menu toggleTheme={toggleTheme} />
					<Route path="/Locations/:name">
						{(params: any) => <div>Hello, {params.name}!</div>}
					</Route>
					{/* <Route path="/" component={} /> */}
					<Route path="/" component={() => {
						window.location.href = 'https://sa.perryweather.com';
						return null;
					}} />
					<Route path="/map" component={HardwareMap} />
					<Route path="/lightning" component={LightningMapBeta} />
					{/* <Route path="/Notifications" component={Notifications} />
					<Route path="/NotificationSettings" component={NotificationSettings} /> */}
					<Route path="/Callback" component={Callback} />
					<Route path="/SilentRenew" component={SilentRenew} />
					{/* <Route path="/Users" component={Users} /> */}
					<Route path="/Help" component={Help} />
					<Route path="/Profile" component={Profile} />
					<Route path="/Unauthorized" component={Unauthorized} />
				</>);
		}
		else {
			// return (<Redirect to="https://localhost:5005"/>);
			// return (
			// 	<Route path="/" component={() => {
			// 		window.location.href = "https://pp-prod-dashboard-staging.azurewebsites.net";
			// 		// window.location.href = "https://localhost:5005";
			// 		return null;
			// 	}}/>
			// );
			return (
				<>
					<Route path="/Unauthorized" component={() => {
						window.location.href = process.env.REACT_APP_LEGACY_REDIRECT_URL ?? '';
						return null;
					}} />
					{/* <Route path="/Unauthorized" component={Unauthorized} /> */}
					<Route path="/Callback" component={Callback} />
				</>
			);
			// return (
			// 	<div style={{ position: 'absolute', left: 0, top: 0, height: '100vh', width: '100%', display: 'flex', alignItems: 'center' }} >
			// 		<Grid container margin={1} alignContent="center" alignItems="center" display='flex' flexDirection="column" justifyContent="center" spacing={1}>
			// 			<Grid item>
			// 				<LockIcon style={{width: 85, height: 85}} htmlColor={theme.palette.primary.main} />
			// 			</Grid>

			// 			<Grid item>
			// 				<Typography variant="h4" noWrap>
			// 					Unauthorized
			// 				</Typography>
			// 			</Grid>

			// 			<Grid item width='90%'>
			// 				<Typography variant="body1">
			// 					If you have been granted access recently, you may have to sign out and sign back in.
			// 				</Typography>
			// 			</Grid>

			// 			<Grid item>
			// 				<Button onClick={() => auth.userManager.signoutRedirect()}>Sign Out</Button>
			// 			</Grid>
			// 		</Grid>
			// 	</div>);
		}
	}
	else {
		if (needsLogin) {
			// console.debug('attempting to relogin.');
		}

		if(location.toLocaleLowerCase().includes('historical'))
		{
			return (
				<>
					<Redirect to={"/Public" + location}/>
				</>);
		}

		if (window.location.pathname !== '/callback' && window.location.pathname !== '/silentrenew')
			window.sessionStorage.setItem("auth:redirect", window.location.pathname);
			
		auth.signIn();

		return (<></>);
	}
}
