import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';

export interface ChangePasswordModel{
    userId: string,
    currentPassword: string,
    newPassword: string,
    confirmedNewPassword: string
}

export interface ChangePasswordModelError{
    data?:{
        IdentityError?: string[],
        UserId?: string[],
        NewPassword?: string[],
        CurrentPassword?: string[],
        ConfirmedNewPassword?: string[]
    }
}

interface ActivateEmailResponse {
    isSuccess: boolean,
    message: string
}

const baseUrl = process.env.REACT_APP_IDENTITY_SERVER_URL;
export const identApi = createApi({
    reducerPath: 'identApi',
    baseQuery: fetchBaseQuery({ baseUrl }) as BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
  >,

    endpoints: (builder) => ({
        changePassword: builder.mutation<string, ChangePasswordModel>({
            query: (changePwModel) => ({
                url: 'account/change',
                method: 'POST',
                body: changePwModel
            }),
            transformResponse: (response: { data: string}) => response.data
        }),
        sendActivationEmail: builder.mutation<ActivateEmailResponse, string>({
            query: (userId) => ({
                url: `account/sendactivateemail?id=${userId}`
            })
        })
    }),
})

export const {
    useChangePasswordMutation,
    useSendActivationEmailMutation
} = identApi;