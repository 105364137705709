import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { ThreatTypes } from '../../Constants/Constants';
import { Location } from '../../Services/API';

export interface DashState {
  selectedLocation?: Location;
  editedLocation: Location;
  status: 'idle' | 'loading' | 'failed';
  severeLocations: {[key: string]: SevereThreat[]};
}

interface LocationThreat {
  locationId: string,
  threatType: SevereThreat
}

export interface SevereThreat {
  shortName: ThreatTypes,
  longName: string[]
}

const initialState: DashState = {
  selectedLocation: undefined,
  editedLocation: {id: undefined, label:'', locationName:'', latitude:0, longitude:0, radius1: 10, radius2: 0, radius3: 0, allClearMinutes: 30, flags: 2, forecastLocation: false},
  status: 'idle',
  severeLocations: {},
};

export const dashSlice = createSlice({
  name: 'dash',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    selectLocation: (state, actions: PayloadAction<Location | undefined>) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.selectedLocation = actions.payload;
    },
    editedLocation: (state, actions: PayloadAction<Location>) => {
      state.editedLocation = actions.payload;
    },
    addSevereLocation: (state, actions: PayloadAction<LocationThreat>) => {
      var threats = state.severeLocations[actions.payload.locationId]
      if(threats && threats.length > 0)
      {
        var exists = threats?.find((x) => {
          return x.shortName === actions.payload.threatType.shortName;
        });

        if(!exists)
        {
          threats.push(actions.payload.threatType)
          if(threats)
            state.severeLocations[actions.payload.locationId] = threats;

        }
        else if(actions.payload.threatType.shortName === 'NWS'){
          let nwsThreat = threats.find(x => x.shortName === 'NWS');
          if(nwsThreat)
            nwsThreat.longName = actions.payload.threatType.longName
        }
      }
      else
      {
        state.severeLocations[actions.payload.locationId] = [actions.payload.threatType];
      }

    },
    removeSevereLocation: (state, actions: PayloadAction<LocationThreat>) => {
      var threats = state.severeLocations[actions.payload.locationId]

      if(threats && threats.length > 0)
      {
        var exists = threats?.find((x) => {
          return x === actions.payload.threatType;
        });

        if(exists)
        {
          var newArr = threats.filter(x => x !== actions.payload.threatType);
          if(newArr)
          {
            state.severeLocations[actions.payload.locationId] = newArr;
          }
        }

      }
    },
  },
});

export const {
  editedLocation,
  selectLocation,
  addSevereLocation,
  removeSevereLocation,
 } = dashSlice.actions;

export const getSelectedLocation = (state: RootState) => state.dash.selectedLocation
export const getEditedLocation = (state: RootState) => state.dash.editedLocation
export const getSevereLocations = (state: RootState) => state.dash.severeLocations
export const getSevereLocation = (state: RootState, actions: PayloadAction<string>) => state.dash.severeLocations[actions.payload]


export default dashSlice.reducer;
