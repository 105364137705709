import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import BaseControl from './BaseControl';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { getRadarPlaying, setRadarPlaying } from '../../../../features/map/mapSlice';

interface PlayButtonProps {

}

export  function PlayButtonControl (props: PlayButtonProps) {
	const radarPlaying = useAppSelector(getRadarPlaying);
	const dispatch = useAppDispatch();

	return (
		<div>
		{/* <div onMouseOver={onMouseOver} onMouseOut={onMouseOut} style={style} className={className}> */}
			<BaseControl onClick={() => {dispatch(setRadarPlaying(!radarPlaying))}} label="Play Button">
				{!radarPlaying ? <PlayArrowIcon/> : <PauseIcon/> }
			</BaseControl>
		</div>
	);
}

export default PlayButtonControl;