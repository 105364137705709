import { Source, Layer, LayerProps } from 'react-map-gl';
import circle from '@turf/circle';

export interface CircleProps {
	latitude: number,
	longitude: number,
	radius: number,
	index: number,
	color: string,
	sourceId: string
}

export function CircleLayer (props: CircleProps) {
	const { latitude, longitude, radius, color, index, sourceId } = props;
	var center = [longitude, latitude];

	// Use Memo here?
	const layerStyle: LayerProps = {
		id: `radii-${index}`,
		type: 'line',
		paint: {
			'line-color': color,
			'line-width': 2
		}
	};

	var circ = circle(center, radius, { steps: 32, units: "miles", properties: null});

	return (
		<Source id={sourceId} type="geojson" data={circ}>
			<Layer beforeId="admin_sub" {...layerStyle} />
		</Source>
	)
}

export default CircleLayer;
