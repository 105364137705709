import { Box, Fade, Typography } from "@mui/material";
import { LngLat, LngLatBounds } from "maplibre-gl";
import { useEffect, useState } from "react";
import { PrecipPointType, usePrecipByPointQuery } from "../../../../Services/API";

interface PrecipProps {
    clickedInfo: any,
    // precipPoint: PrecipPointType
    // lngLat: LngLat
}

interface PrecipTextLineProps {
    lngLat: LngLat,
    precipPointType: string
}

function PrecipTextLine(props: PrecipTextLineProps) {
    const { lngLat, precipPointType } = props;
    
    const {data} = usePrecipByPointQuery({product: precipPointType, mask: 'Standard-Mercator', longitude: lngLat.lng, latitude: lngLat.lat});
    return (
        <Box display="flex" justifyContent="space-between">
            
            <Box>
                <Typography>{precipPointType}</Typography>
            </Box>
            <Box>
                {data?.text ? 
                (<Typography>{data?.text}</Typography>) :
                (<Typography>{data?.value} {data?.units}</Typography>)}
            </Box>
        </Box>
    );
}

export function PrecipView(props: PrecipProps) {
    const {clickedInfo} = props;

    const [lngLat, setLngLat] = useState<LngLat>();

    useEffect( () => {
        if (clickedInfo.lngLat)
            setLngLat(clickedInfo.lngLat);
    }, [clickedInfo]);
    // const lngLat = new LngLat(clickedInfo.lng, clickedInfo.lat);

    return(
        <Fade in={lngLat !== undefined}>
        {lngLat !== undefined ? (
            <Box style={{ borderRadius: 8, position: 'absolute', padding: 4, background: 'rgba(0,0,0,0.8)', color: '#fff', minWidth: 180, fontSize: 10, zIndex: 9, pointerEvents: 'none', left: clickedInfo.x - 70,  top: clickedInfo.y - (5 * 24 + 24)}}>
                <PrecipTextLine lngLat={lngLat!} precipPointType="precip-liquidaccum-1hr" />
                <PrecipTextLine lngLat={lngLat!} precipPointType="precip-liquidaccum-3hr" />
                <PrecipTextLine lngLat={lngLat!} precipPointType="precip-liquidaccum-6hr" />
                <PrecipTextLine lngLat={lngLat!} precipPointType="precip-liquidaccum-12hr" />
                <PrecipTextLine lngLat={lngLat!} precipPointType="precip-liquidaccum-24hr" />
            </Box>)
            : 
            (<></>)}
        </Fade>
    );
}

export default PrecipView;
