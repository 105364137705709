import { useWxsMapQuery } from '../../../../Services/API';
import { Map, Marker } from 'maplibre-gl';
import { Source, Layer } from 'react-map-gl';
import { Viewport } from '../Util';
import { useAppSelector } from '../../../../app/hooks';
import { getWXSLayer } from '../../../../features/map/mapSlice';
import { useEffect } from 'react';
import { useState } from 'react';

// Don't love the params we have to pass in. Could cause issues


// const geojson:  GeoJSON.FeatureCollection<GeoJSON.Geometry>  = {
//     type: 'FeatureCollection',
//     features: [
// 	  {type: 'Feature', geometry: {type: 'Point', coordinates: [-96.8518, 32.80366]}, properties: {
// 		  Age: 250,
// 	  }},
// 	  {type: 'Feature', geometry: {type: 'Point', coordinates: [-96.8418, 32.80366]}, properties: {
// 		  Age: 899,
// 	  }},
// 	  {type: 'Feature', geometry: {type: 'Point', coordinates: [-96.8318, 32.80366]}, properties: {
// 		  Age: 1200,
// 	  }},
//     ]
// };

export interface WXSLayerProps {
	viewport: Viewport,
	mapRef: Map
}

const iconSize: number = 1.5;

export function WXSLayer (props: WXSLayerProps) {

    const { data } = useWxsMapQuery(null, { refetchOnReconnect: true, refetchOnFocus: true, pollingInterval: 1000 * 60 * 2});
	var chosenWXSLayer = useAppSelector(getWXSLayer);
    const [feature, setFeature] = useState('RainToday');
    useEffect( () => {
        switch(chosenWXSLayer) {
            case 'raintotal':
                setFeature('RainToday');
                break;
            case 'rain1hr':
                setFeature('Rain1Hr');
                break;
            case 'humidity':
                setFeature('RelativeHumidity');
                break;
            case 'wbgt':
                setFeature('WetBulbGlobalTemp');
                break;
            case 'windspeed':
                setFeature('WindSpeed');
                break;
            case 'temp':
                setFeature('AmbientTemperature');
                break;
            default:
                setFeature('RainToday');
                
        }
    }, [chosenWXSLayer]);
    //
    // const wxLayer: any= {
    //     id: 'wx-layer',
    //     type: 'symbol',
    //     source: 'wxs',
    //     filter: [
    //         'all',
    //     ],
    //     layout: {
    //         'icon-image': 'h_wx',
    //         'icon-size': iconSize,
    //         'icon-padding': 0,
    //         // 'icon-rotate': ["get", "WindDirection"],
    //         'icon-allow-overlap': false,
    //
    //     }
    // };
    const textLayer: any = {
        id: 'text-prop-layer',
        type: 'symbol',
        source: 'wxs',
        layout: {
            // 'text-field': ['round', ['get', 'AmbientTemperature']],
            'text-field': ['to-string', ['/', ['round', ['*', ['number', ['get', feature]], 100]], 100]],
            // 'text-field': ['to-string', ['/', ['round', ['*', ['number', ['get', 'RainToday']], 100]], 100]],
            // 'text-field': ['to-string', ['round', ['number', ['get', 'AmbientTemperature']]]],
            'text-font': ['Proxima Nova'],
            'text-offset': [0, -1.2],
            'text-size': 15,
            'text-allow-overlap': false
        }
    };

    const circleLayer: any = {
        type: 'circle',
        id: 'circles-wxs-all',
        source: 'wxs',
        paint: {
		  'circle-radius': 15,
		  'circle-color': '#efefef',
          'circle-stroke-opacity': 0.3,
		  'circle-stroke-width': 2,
          'circle-translate': [0, -18]
		//   'circle-stroke-color': '#000',
		//   'circle-radius': ['step', ['get', 'point_count'], 10, 100, 15, 750, 20],
		//   'circle-color': ['step', ['get', 'point_count'], '#51bbd6', 100, '#f1f075', 750, '#f28cb1'],


            //   'circle-color': ['step', ['get', 'point_count'], '#FF8C00', 100, '#DFE566', 750, '#8BCE93'],
            //   'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40]
        }
	  };


    return ( data ?
        <Source id="wxs" type="geojson" data={data} >
        {/*
            <Layer {...clusterLayer} />
            <Layer {...circleClusterLayer} />
            <Layer {...clusterCountLayer} />

            <Layer {...owsLayer} />
            <Layer {...owxLayer} />
        */}
            <Layer {...circleLayer} />
            <Layer {...textLayer} />
        </Source>
        : <></>
   )
}

export default WXSLayer;
