import { GridSize, Grid, Box } from "@mui/material";
import { Label } from '../BaseLabel';

interface InputRowProps{
    children: JSX.Element,
    label: string,
    labelSize?: boolean | GridSize,
    fieldSize?: boolean | GridSize,
    style?: React.CSSProperties,
    labelSuffix?: JSX.Element
}

export function InputRow(props:InputRowProps){
    const {children, label, labelSize, fieldSize, style, labelSuffix} = props;

    return(
        <Grid container style={{...style,marginBottom: 16, }}>
            <Grid item md={labelSize || 4} xs={12} style={{display:'flex', flexDirection: 'column', justifyContent:'center'}}>
                <Box style={{display:'flex', flexDirection: 'row', justifyContent:'flex-start'}}>
                    <Label text={label} labelVariant='body1'/>
                    {labelSuffix}
                </Box>
            </Grid>
            <Grid item md={fieldSize || 8} xs={12}>
                {children}
            </Grid>
        </Grid>
    );
}