import { Theme, useTheme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { CSSProperties } from 'react';

interface StatusComponentProps {
    children: React.ReactNode,
    style?: CSSProperties,
    status?: 'success' | 'warning' | 'danger',
}

const useStyles = makeStyles((theme:Theme) =>
    createStyles({
        container: {
            borderRadius: 6,
            padding: '2px 8px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            zIndex: 1,
        },
        success: {
            backgroundColor: theme.palette.success.light,
            color: theme.palette.success.dark
        },
        warning: {
            backgroundColor: theme.palette.warning.main,
        },
        danger: {
            backgroundColor: theme.palette.error.main,
            color: theme.palette.common.white
        }
    })
);

export function StatusComponent(props: StatusComponentProps) {
    const {children, style, status} = props;
    var theme = useTheme();
    const classes = useStyles(theme)

    var classStyle = status === 'success' ? classes.success : classes.danger;

    return(
        <div style={style} className={[classes.container, classStyle].join(' ')}>
            {children}
        </div>
    );
}