import { useLightningMapGeoJsonQuery } from '../../../../Services/API';
import { Map } from 'maplibre-gl';
import { Source, Layer } from 'react-map-gl';
import React from 'react';

interface Viewport {
	zoom: number,
	latitude: number,
	longitude: number
}

// Don't love the params we have to pass in. Could cause issues

export interface LightningLayerProps {
	viewport: Viewport,
	mapRef: Map
}


export function LightningLayer (props: LightningLayerProps) {

    const { data } = useLightningMapGeoJsonQuery(null, { refetchOnReconnect: true, refetchOnFocus: true, pollingInterval: 1000 * 60 * 1});

    const greenLayer: any= {
        id: 'unclustered-point-green-all',
        type: 'symbol',
        source: 'lightning-all',
        // filter: ['<', ['get', 'Age'], 60*10],
        filter: [
            'all',
            ['!', ['has', 'point_count']],
            ['>=', ['get', 'Age'], 900], ['<', ['get', 'Age'], 1800]
        ],
        layout: {
            'icon-image': 'map-lightning-outline-green-alt',
            'icon-size': 0.6,
            'icon-padding': 0,
            "icon-allow-overlap": true,
        },
    };
    const yellowLayer: any= {
        id: 'unclustered-point-yellow-all',
        type: 'symbol',
        source: 'lightning-all',
        // filter: ['<', ['get', 'Age'], 60*10],
        filter: [
            'all',
            ['!', ['has', 'point_count']],
            ['>=', ['get', 'Age'], 300], ['<', ['get', 'Age'], 900]
        ],
        layout: {
            'icon-image': 'map-lightning-outline-yellow-alt',
            'icon-size': 0.6,
            'icon-padding': 0,
            "icon-allow-overlap": true,
        },
    };
    const redLayer: any= {
        id: 'unclustered-point-red-all',
        type: 'symbol',
        source: 'lightning-all',
        // filter: ['<', ['get', 'Age'], 60*10],
        filter: [
            'all',
            ['!', ['has', 'point_count']],
            ['>=', ['get', 'Age'], 0], ['<', ['get', 'Age'], 300]
        ],
        layout: {
            'icon-image': 'map-lightning-outline-red-alt',
            'icon-size': 0.6,
            'icon-padding': 0,
            "icon-allow-overlap": true,
        },
    };

    const circleClusterLayer: any = {
        type: 'circle',
        id: 'clusters-circle-all',
        source: 'lightning-all',
        filter: ['has', 'point_count'],
        paint: {
		  'circle-radius': 11,
		  'circle-color': '#efefef',
          'circle-stroke-opacity': 0.3,
		  'circle-stroke-width': 2,
		//   'circle-stroke-color': '#000',
		//   'circle-radius': ['step', ['get', 'point_count'], 10, 100, 15, 750, 20],
		//   'circle-color': ['step', ['get', 'point_count'], '#51bbd6', 100, '#f1f075', 750, '#f28cb1'],


            //   'circle-color': ['step', ['get', 'point_count'], '#FF8C00', 100, '#DFE566', 750, '#8BCE93'],
            //   'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40]
        }
	  };

	  const clusterCountLayer: any = {
        id: 'cluster-count-all',
        type: 'symbol',
        source: 'lightning-all',
        filter: ['has', 'point_count'],
        layout: {
		  'text-field': '{point_count_abbreviated}',
		  'text-font': ['Proxima Nova'],
		  'text-size': 10,
		  'text-allow-overlap': true
        }
	  };


    const clusterLayer: any = {
        layout: {
		  'icon-image': 'map-lightning',
		  'icon-size': 2.2,
		  'icon-padding': 0,
          "icon-allow-overlap": true,
          ///Comment out to remove text.
		//   'text-field': '{point_count_abbreviated}',
		//   'text-font': ['Proxima Nova'],
		//   'text-size': 10
          ///
        },
        type: 'symbol',
        id: 'clusters-icon-all',
        source: 'lightning-all',
        filter: ['has', 'point_count'],
        paint: {
		  'icon-color': '#116799',
		  'icon-halo-color': '#000',
		  'icon-halo-width': 5,
		//   'icon-halo-blur': 0,
        }
	  };

    /// If I turn this into geojson should be able to add points to map as a layer and thus allow for below text labels.


        return ( data ?
        // <Source id="lightning" type="geojson" data={'https://docs.mapbox.com/mapbox-gl-js/assets/earthquakes.geojson'}>
        // <Source id="lightning" type="geojson" data={geojson}>
            <Source id="lightning-all" type="geojson" data={data} cluster clusterRadius={50} clusterMaxZoom={6}>
                <Layer {...clusterLayer} />
                <Layer {...circleClusterLayer} />
                <Layer {...clusterCountLayer} />
                {/* <Layer {...layerStyle} /> */}
                <Layer {...redLayer} />
                <Layer {...yellowLayer} />
                <Layer {...greenLayer} />
            </Source>
            : <></>
        )
}

export default LightningLayer;
