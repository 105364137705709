import WxImageModal from '../../../WeatherStation/WxImageModal';
import { WxImage as WxComponent } from '../../../Base'
import { LocationModal } from '../LocationFormModal';
import { IconButton, styled, Typography, useMediaQuery, useTheme } from '@mui/material';
import TimeParser, { ConvertToUtc, formatTimeStringFromMins, getTimeDifferenceInMins } from '../../../../features/Time/TimeHelpers';
import { useEffect, useState } from 'react';
import { Observation, useCachedPolicyAlertsForLocationQuery, useLightningStatusQuery, useNwsFeaturesQuery, useWXImageLastModifiedQuery } from '../../../../Services/API';
import { Expand } from '../../../../Assets';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { addSevereLocation, getSelectedLocation, removeSevereLocation } from '../../../../features/dash/dashSlice';
import { PolicyTypeName } from '../../../../Constants/Constants';

interface SelectedLocationCardProps {
    observation?: Observation
}

/// Could use an sx property below, but this is a good example for using styled components.
const WxImageContainer = styled('div')(({ theme }) => ({
    // height: '15em',
    position: 'relative',
    height: '100%',
    borderRadius: '10px',
    overflow: 'hidden',
    border: "2px solid",
    borderColor: theme.palette.action.selected,
    maxHeight: 240,
    minHeight: 225
  }));
  
  const WxImage = styled('img')({
    width: '100%',
    height: '100%',
    objectFit: 'cover'
  });
  
  const WxImageDetails = styled('div')(({ theme }) => ({
    overflow: 'hidden',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 8,
    width: '100%',
    height: 40,
    background: theme.palette.background.default + 'A0',
  }));
  

export function SelectedLocationCard(props: SelectedLocationCardProps){
    const { observation } = props;

	const dispatch = useAppDispatch();
    const theme = useTheme();
    
    const selectedLocation = useAppSelector(getSelectedLocation);
    
    const wx = observation?.weatherStation;
    const curDate = new Date();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));


    const [expandModalOpen, setExpandModalOpen] = useState(false);
    const [imageSrc, setImageSrc] = useState<any>(null);

    const {data: imageLastModified} = useWXImageLastModifiedQuery(wx?.id || '', { skip: selectedLocation === undefined, pollingInterval: 300000});
    const { data: nwsFeatures } = useNwsFeaturesQuery(selectedLocation ? (selectedLocation.id || "") : "", { skip: selectedLocation === undefined });
	const { data: delay } = useLightningStatusQuery(selectedLocation ? (selectedLocation.id || "") : "", { skip: selectedLocation === undefined });
    const { data: policyViolations } = useCachedPolicyAlertsForLocationQuery(selectedLocation?.id || '', { skip: selectedLocation?.id === undefined});

    const lastUpdatedMins = wx && imageLastModified ? Math.round(getTimeDifferenceInMins(ConvertToUtc(imageLastModified))) : undefined;

	useEffect(() => {
		if (delay) {
			var delay1 = TimeParser(delay.item1);
			var delay2 = TimeParser(delay.item2);
			var delay3 = TimeParser(delay.item3);

			var shouldAdd = false;

			if (delay1[0] > 0 || delay1[1] > 0) {
				shouldAdd = true;
			}
			else if (delay2[0] > 0 || delay2[1] > 0) {
				shouldAdd = true;
			}
			else if (delay3[0] > 0 || delay3[1] > 0) {
				shouldAdd = true;
			}
			else {
				shouldAdd = false;
			}

			if (selectedLocation && selectedLocation.id) {
				if (shouldAdd) {
					dispatch(addSevereLocation({ locationId: selectedLocation.id, threatType:  {shortName: 'LR1', longName: ["Lightning Policy Alert"]} }))
				}
				else {
					dispatch(removeSevereLocation({ locationId: selectedLocation.id, threatType:  {shortName: 'LR1', longName: []} }))
				}
			}
		}
	}, [delay]);

	useEffect(() => {
		if (selectedLocation && selectedLocation.id && nwsFeatures) {
			// NWS Bulletin
			if (nwsFeatures.length > 0)
				dispatch(addSevereLocation({ locationId: selectedLocation.id, threatType: {shortName: 'NWS', longName: [...(nwsFeatures.map(x => x.properties.Event))]} }));
			else
				dispatch(removeSevereLocation({ locationId: selectedLocation.id, threatType: {shortName: 'NWS', longName: []} }));
		}
	}, [nwsFeatures]);

	useEffect(()=> {
		if(policyViolations && selectedLocation && selectedLocation.id !== undefined)
		{
			const alertSuffix = " Policy Warning"
			const policyTypes: PolicyTypeName[] = ['WBGT' , 'WIND' , 'GUST' , 'AQI' , 'CHILL' , 'HEAT' , 'PRECIP' , 'AQI']

			policyTypes.forEach((policyType) => {
				let exists = policyViolations.find(x => x.policyShortName === policyType);
				if(exists)
					dispatch(addSevereLocation({ locationId: selectedLocation.id!, threatType: {shortName: policyType, longName: [exists.policyDisplayName + alertSuffix]} }));
				else
					dispatch(removeSevereLocation({ locationId: selectedLocation.id!, threatType: {shortName: policyType, longName: []} }));

			});
		}
	}, [policyViolations])

    useEffect(() => {
        var weatherStationInfo = observation?.weatherStation;
        if (weatherStationInfo)
          setImageSrc(`https://functionstestin9947.blob.core.windows.net/images/${weatherStationInfo!.id}.jpg?${curDate}`)
        else
          setImageSrc(null);
    
      }, [observation]);

    return (
        <WxImageContainer>
            <div style={{ position: 'absolute', bottom: 0, left: 5, zIndex:1 }}>
                <LocationModal formType='edit' />
            </div>
            {imageSrc != null ?
            <>
                <WxImageDetails>
                <Typography textOverflow='ellipsis' mr={1} noWrap>{wx?.name}</Typography>
                {lastUpdatedMins !== undefined &&
                    <Typography variant='caption' overflow='visible' noWrap>{'Image updated ' + formatTimeStringFromMins(lastUpdatedMins)}</Typography>
                }
                </WxImageDetails>
                <WxImage draggable='false' src={imageSrc} alt={'Weather Station Image'} onError={(e) => { setImageSrc(null) }} />
                {(!isMobile && imageSrc) && <IconButton sx={{position:'absolute', bottom: 5, right: 5}} onClick={() => setExpandModalOpen(true)}><Expand color={theme.palette.primary.main}/></IconButton>}
                {(!isMobile && imageSrc) && <WxImageModal src={imageSrc} modalState={{open: expandModalOpen, handleClose: () => setExpandModalOpen(false), handleOpen: () => { }}}/>}
            </>
            :
            <WxComponent style={{height:'100%'}} showStatus={true}/>
            }
        </WxImageContainer>
    )
}