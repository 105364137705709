import { useMemo } from "react";
import {_useMapControl as useMapControl} from "react-map-gl";

interface BottomLayerGroupProps {
	className: any,
	style: any,
	children: React.ReactNode
}

export function BottomLayerGroup (props: BottomLayerGroupProps) {
	const {children, className } = props;
	const style = useMemo(() => ({
		position: 'absolute',
		width: '100%',
	...props.style}), [props.style]);

	const {containerRef} = useMapControl({
		onDragStart: (evt: any) => {
			evt.stopPropagation();
		},
		onDoubleClick: (evt: any) => {
			evt.stopPropagation();
		},
		// onClick: (evt: any) => {
		// },
	});

	return (
		<div style={style} className={className}>
			<div style={{display: 'flex', justifyContent: 'space-around'}} ref={containerRef}>
			{/* <div className="mapboxgl-ctrl mapboxgl-ctrl-group" ref={containerRef}> */}
				{children}
			</div>
		</div>
	);
}

export default BottomLayerGroup;