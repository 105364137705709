import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

export type baseLayerType = 'satellite' | 'dark' | 'light';
export type overlayTypes =
'radar' | 'lightning' | 'nws-alerts' | 'storm-vectors' | 'surface-analysis' | 'tropical-models' | 'hardware' | 'lightning-special' |
'precip-layer' | 'wxs';


export type layerToggleDirection = 'prev' | 'next';
export type wxsSelection =  
    'temp' | 
    'humidity' |
    'wbgt' |
    'windspeed' |
    'rain1hr' |
    'raintotal';

export type precipLayers = 
    'precip-liquidaccum-1hr' | 
    'precip-liquidaccum-3hr' |
    'precip-liquidaccum-6hr' |
    'precip-liquidaccum-12hr' |
    'precip-liquidaccum-24hr';

export type radarTypes = 'past' | 'future' | 'extended';
export type speedTypes = 'slow' | 'normal' | 'fast';

export type mapActionTypes = 'observation' | 'measure';

export interface MapState {
  baseLayerStyle: baseLayerType;
  overlays: overlayTypes[];
  status: 'idle' | 'loading' | 'failed';
  futureToggled: boolean;
  radarStyle: radarTypes;
  radarPlaying: boolean;
  opacity: number;
  speed: speedTypes;
  actionType?: mapActionTypes;
  progress: number;
  currentTime: RadarTimeDisplay;
  currentWXSSelection: wxsSelection;
  currentPrecipSelection: precipLayers;
  // currentTime: string;
}

export interface RadarTimeDisplay {
  time: string;
  displayDate: boolean;
}

const initialState: MapState = {
  baseLayerStyle: 'light',
  overlays: [ 'hardware', ],
  status: 'idle',
  futureToggled: false,
  radarStyle: 'past',
  radarPlaying: false,
  opacity: 1.0,
  speed: 'normal',
  progress: 0,
  currentTime: {time: '', displayDate: false},
  currentPrecipSelection: 'precip-liquidaccum-1hr',
  currentWXSSelection: 'raintotal'
};

export const mapSlice = createSlice({
  name: 'map',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    selectWXSLayer: (state, actions: PayloadAction<layerToggleDirection>) => {
        if (actions.payload === 'prev')
        {
            switch (state.currentWXSSelection)
            {
                case 'temp':
                    state.currentWXSSelection = 'humidity';
                    break;
                case 'humidity':
                    state.currentWXSSelection = 'wbgt';
                    break;
                case 'wbgt':
                    state.currentWXSSelection = 'windspeed';
                    break;
                case 'windspeed':
                    state.currentWXSSelection = 'rain1hr';
                    break;
                case 'rain1hr':
                    state.currentWXSSelection = 'raintotal';
                    break;
                case 'raintotal':
                    state.currentWXSSelection = 'temp';
                    break;
                default:
                    state.currentWXSSelection = 'temp';
            };
        }
        else 
        {
            switch (state.currentWXSSelection)
            {
                case 'temp':
                    state.currentWXSSelection = 'raintotal';
                    break;
                case 'humidity':
                    state.currentWXSSelection = 'temp';
                    break;
                case 'wbgt':
                    state.currentWXSSelection = 'humidity';
                    break;
                case 'windspeed':
                    state.currentWXSSelection = 'wbgt';
                    break;
                case 'rain1hr':
                    state.currentWXSSelection = 'windspeed';
                    break;
                case 'raintotal':
                    state.currentWXSSelection = 'rain1hr';
                    break;
                default:
                    state.currentWXSSelection = 'temp';
            };
        }
    },
    selectPrecipLayer: (state, actions: PayloadAction<layerToggleDirection>) => {
        if (actions.payload === 'prev')
        {
            switch (state.currentPrecipSelection)
            {
                case 'precip-liquidaccum-1hr':
                    state.currentPrecipSelection = 'precip-liquidaccum-24hr';
                    break;
                case 'precip-liquidaccum-3hr':
                    state.currentPrecipSelection = 'precip-liquidaccum-1hr';
                    break;
                case 'precip-liquidaccum-6hr':
                    state.currentPrecipSelection = 'precip-liquidaccum-3hr';
                    break;
                case 'precip-liquidaccum-12hr':
                    state.currentPrecipSelection = 'precip-liquidaccum-6hr';
                    break;
                case 'precip-liquidaccum-24hr':
                default:
                    state.currentPrecipSelection = 'precip-liquidaccum-12hr';
            };
        }
        else 
        {
            switch (state.currentPrecipSelection)
            {
                case 'precip-liquidaccum-1hr':
                    state.currentPrecipSelection = 'precip-liquidaccum-3hr';
                    break;
                case 'precip-liquidaccum-3hr':
                    state.currentPrecipSelection = 'precip-liquidaccum-6hr';
                    break;
                case 'precip-liquidaccum-6hr':
                    state.currentPrecipSelection = 'precip-liquidaccum-12hr';
                    break;
                case 'precip-liquidaccum-12hr':
                    state.currentPrecipSelection = 'precip-liquidaccum-24hr';
                    break;
                case 'precip-liquidaccum-24hr':
                default:
                    state.currentPrecipSelection = 'precip-liquidaccum-1hr';
            };

        }
    },
    selectBaseLayer: (state, actions: PayloadAction<baseLayerType>) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.baseLayerStyle = actions.payload;
    },
    selectOverlays: (state, actions: PayloadAction<overlayTypes>) => {
      if (state.overlays.includes(actions.payload))  {
        state.overlays = state.overlays.filter(layer => layer !== actions.payload);
      }
      else {
        state.overlays = [...state.overlays, actions.payload];
      }
    },
    setOverlays: (state, actions: PayloadAction<string[]>) => {
      state.overlays = actions.payload as overlayTypes[];
    },
    selectRadarStyle: (state, actions: PayloadAction<radarTypes>) => {
      state.radarStyle = actions.payload;
    },
    setOpacity: (state, actions: PayloadAction<number>) => {
      state.opacity = actions.payload;
    },
    setSpeed: (state, actions: PayloadAction<speedTypes>) => {
      state.speed = actions.payload;
    },
    setRadarPlaying: (state, actions: PayloadAction<boolean>) => {
      state.radarPlaying = actions.payload;
    },
    setActionType: (state, actions: PayloadAction<mapActionTypes | undefined>) => {
      state.actionType = actions.payload;
    },
    setProgress: (state, actions: PayloadAction<number>) => {
      state.progress = actions.payload;
    },
    setCurrentTime: (state, actions: PayloadAction<RadarTimeDisplay>) => {
      state.currentTime = actions.payload;
    }
  },
});

export const {
  selectBaseLayer,
  selectOverlays,
  setOverlays,
  setRadarPlaying,
  setOpacity,
  setSpeed,
  setActionType,
  setProgress,
  setCurrentTime,
  selectRadarStyle,
  selectWXSLayer,
  selectPrecipLayer
} = mapSlice.actions;

export const getRadarStyle = (state: RootState) => state.map.radarStyle;
export const getOpacity = (state: RootState) => state.map.opacity;
export const getCurrentTime = (state: RootState) => state.map.currentTime;
export const getSpeedText = (state: RootState) => state.map.speed;
export const getWXSLayer = (state: RootState) => state.map.currentWXSSelection;
export const getPrecipLayer = (state: RootState) => state.map.currentPrecipSelection;
export const getAnimSpeed = (state: RootState) => {
  switch (state.map.speed) {
    case 'fast':
      return 500;
    case 'normal':
      return 750;
    case 'slow':
      return 1000;
  }
}
export const getProgress = (state: RootState) => state.map.progress;
export const getBaseLayer = (state: RootState) => state.map.baseLayerStyle;
export const getOverlays = (state: RootState) => state.map.overlays;
export const getRadarPlaying = (state: RootState) => state.map.radarPlaying;
export const getActionType = (state: RootState) => state.map.actionType;


export default mapSlice.reducer;
