import { api } from ".";

export interface NotificationAction {
  id: string,
  triggerId?: string,
  message: string,
  allClear: boolean
}

export interface Policy{
    id: string,
    policyType: number,
    policyTypeName: string,
    policyTypeShortName?: string,
    unit?: string,
    threshold: number,
    reAlertMinutes: number,
    allClearMinutes: number,
    customerId: number,
    sites: number[],
    isDesc?: boolean,
    notificationActions: NotificationAction[],
    precision: number,
    limit: number,
    minAllClear: number,
    allGroups: boolean
  }

  export interface PolicyType {
    id: number,
    unit?: string,
    displayName: string,
    name: string,
    precision: number,
    limit: number,
    minAllClear: number,
    isDesc: boolean
  }

  export interface PolicyErrorsModel {
    Threshold? : string[],
    ServerErrors?: string[],
    AllClearMinutes?: string[],
    Sites?: string[]
  }

const policyApi = api.injectEndpoints({
    endpoints: (builder) => ({
        policies: builder.query<Policy[], void>({
            query: () => `policy`,
            transformResponse: (response: { data: Policy[]}) => response.data,
            providesTags: ["Policies"]
          }),
        sitePolicies: builder.query<Policy[], void>({
            query: () => `policy/site`,
            transformResponse: (response: { data: Policy[]}) => response.data,
            providesTags: ["Policies"]
          }),
          policyTypes: builder.query<PolicyType[], void>({
            query: () => `policy/types`,
            transformResponse: (response: { data: PolicyType[]}) => response.data
          }),
          updatePolicy: builder.mutation<Policy, Policy>({
            query: (request) => ({
              url: 'policy',
              method: 'PATCH',
              body: request
            }),
            transformResponse: (response: { data: Policy}) => response.data,
            invalidatesTags: ["Policies"]
          }),
          addPolicy: builder.mutation<Policy, Policy>({
            query: (request) => ({
              url: 'policy',
              method: 'POST',
              body: request
            }),
            transformResponse: (response: { data: Policy}) => response.data,
            invalidatesTags: ["Policies"]
          }),
          deletePolicy: builder.mutation<void, string>({
            query: (id) => ({
              url: `policy/${id}`,
              method: 'DELETE'
            }),
            invalidatesTags: ["Policies"]
          })
    })
})

export const {
  usePoliciesQuery,
  usePolicyTypesQuery,
  useSitePoliciesQuery,

  useUpdatePolicyMutation,
  useAddPolicyMutation,
  useDeletePolicyMutation
} = policyApi;