import { Grid, Typography, useTheme } from "@mui/material";
import React from "react";
import { Page } from "../Components/Base";
import Map from '../Components/Lightning/Map/Map';

export function LightningMapBeta() {

  const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	return (
		<Page docTitle="Lightning Map Beta" title={<Typography variant="h3" color="textPrimary">Lightning Map Beta</Typography>}>
			<Grid container>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid minHeight={700} item xs={12} sm={12} md={12} lg={12} order={{ xs: 2, sm: 2, md: 2, lg: 1, xl: 1 }}>
                        <Map />
                      </Grid>
					</Grid>
				</Grid>
			</Grid>
		</Page>
	);
}