import { _useMapControl as useMapControl } from "react-map-gl";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { Thermometer } from "../../../../Assets";
import { getActionType, setActionType } from "../../../../features/map/mapSlice";
import { PWColors } from "../../../../Theme/PWColors";

function renderButton(callback: any) {
	return (
	  <button
		key='center-btn'
		className={`mapboxgl-ctrl-icon mapboxgl-ctrl-geolocate`}
		type="button"
		onClick={callback}
	  >
        <Thermometer height={24} width={12} color={PWColors.dark.base['900']}/>
	  </button>
	);
  }


export function ObservationsControl (props: any) {
	const {containerRef} = useMapControl(props);

	const actionType = useAppSelector(getActionType);
	var observationActive = actionType === 'observation';
	const dispatch = useAppDispatch();

    const onSetObservation = () => {
		if(observationActive)
			dispatch(setActionType(undefined))
		else
        	dispatch(setActionType('observation'));
    };

	return (
		<div style={{width:'100%', display: 'flex', flexDirection: 'column'}}>
            <div style={{marginBottom: 10, backgroundColor: observationActive ? PWColors.dark.primary[500] : '#fff'}}
				className="mapboxgl-ctrl mapboxgl-ctrl-group" ref={containerRef}>
                {renderButton(onSetObservation)}
            </div>
		</div>
	);
}

export default ObservationsControl;