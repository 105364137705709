import { useLightningMapQuery } from '../../../../Services/API';
import { Map } from 'maplibre-gl';
import { Source, Layer } from 'react-map-gl';
import { BBox, getBBox } from '../Util';
import { useEffect, useState } from 'react';

interface Viewport {
	zoom: number,
	latitude: number,
	longitude: number
}

// Don't love the params we have to pass in. Could cause issues


// const geojson:  GeoJSON.FeatureCollection<GeoJSON.Geometry>  = {
//     type: 'FeatureCollection',
//     features: [
// 	  {type: 'Feature', geometry: {type: 'Point', coordinates: [-96.8518, 32.80366]}, properties: {
// 		  Age: 250,
// 	  }},
// 	  {type: 'Feature', geometry: {type: 'Point', coordinates: [-96.8418, 32.80366]}, properties: {
// 		  Age: 899,
// 	  }},
// 	  {type: 'Feature', geometry: {type: 'Point', coordinates: [-96.8318, 32.80366]}, properties: {
// 		  Age: 1200,
// 	  }},
//     ]
// };

export interface LightningLayerProps {
	viewport: Viewport,
	mapRef: Map
}



export function LightningLayer (props: LightningLayerProps) {
    // let bbox = getBBox(props.viewport, props.mapRef);
    const [bboxFixed, setBBoxFixed] = useState<BBox>(getBBox(props.viewport, props.mapRef));

    useEffect(() => {
		let lightningQueryTimeout = setTimeout(() =>{
			// console.debug('sign in timed out');
            let bbox = getBBox(props.viewport, props.mapRef);
            setBBoxFixed(bbox);
			// window.location.replace('/');
		},300);

		return () => {
			clearTimeout(lightningQueryTimeout);
		}

	},[props.viewport, props.mapRef])

    const { data } = useLightningMapQuery(bboxFixed, { refetchOnReconnect: true, refetchOnFocus: true, pollingInterval: 1000 * 60 * 1});

    // const { data } = useLightningMapGeoJsonQuery(null, { refetchOnReconnect: true, refetchOnFocus: true, pollingInterval: 1000 * 60 * 1});

    // const mag1 = ['all', ['>=', ['get', 'Age'], 0], ['<', ['get', 'Age'], 300]];
    // const mag2 = ['all', ['>=', ['get', 'Age'], 300], ['<', ['get', 'Age'], 900]];
    // const mag3 = ['all', ['>=', ['get', 'Age'], 900], ['<', ['get', 'Age'], 1800]];

    const greenLayer: any= {
        id: 'unclustered-point-green',
        type: 'symbol',
        source: 'lightning',
        // filter: ['<', ['get', 'Age'], 60*10],
        filter: [
            'all',
            ['!', ['has', 'point_count']],
            ['==', ['get', 'Count'], 1],
            ['>=', ['get', 'Age'], 900], ['<', ['get', 'Age'], 1800]
        ],
        layout: {
            'icon-image': 'map-lightning-outline-green',
            'icon-size': 0.6,
            'icon-padding': 0,
            "icon-allow-overlap": true,
        },
    };
    const yellowLayer: any= {
        id: 'unclustered-point-yellow',
        type: 'symbol',
        source: 'lightning',
        // filter: ['<', ['get', 'Age'], 60*10],
        filter: [
            'all',
            ['!', ['has', 'point_count']],
            ['==', ['get', 'Count'], 1],
            ['>=', ['get', 'Age'], 300], ['<', ['get', 'Age'], 900]
        ],
        layout: {
            'icon-image': 'map-lightning-outline-yellow',
            'icon-size': 0.6,
            'icon-padding': 0,
            "icon-allow-overlap": true,
        },
    };
    const redLayer: any= {
        id: 'unclustered-point-red',
        type: 'symbol',
        source: 'lightning',
        // filter: ['<', ['get', 'Age'], 60*10],
        filter: [
            'all',
            ['!', ['has', 'point_count']],
            ['==', ['get', 'Count'], 1],
            ['>=', ['get', 'Age'], 0], ['<', ['get', 'Age'], 300]
        ],
        layout: {
            'icon-image': 'map-lightning-outline-red',
            'icon-size': 0.6,
            'icon-padding': 0,
            "icon-allow-overlap": true,
        },
    };

    // const layerStyle: any= {
    //     id: 'unclustered-point',
    //     type: 'symbol',
    //     source: 'lightning',
    //     // filter: ['<', ['get', 'Age'], 60*10],
    //     filter: ['!', ['has', 'point_count']],
    //     layout: {
    //         'icon-image': 'map-lightning',
    //         'icon-size': 1.3,
    //         'icon-padding': 0,
    //         "icon-allow-overlap": true,

    //     },
    //     paint: {
    //         // 'icon-color': '#FF8C00',
    //         'icon-color':
	// 		[
	// 		    'case',
	// 		    mag1,
	// 		    '#FF8C00',
	// 		    mag2,
	// 		    '#DFE566',
	// 		    mag3,
	// 		    '#8BCE93',
	// 		    '#DFE566',

	// 		],
    //         'icon-halo-color': '#000',
    //         'icon-halo-width': 5,
    //         'icon-halo-blur': 0,
    //     }
    // };

    const circleClusterLayer: any = {
        type: 'circle',
        id: 'clusters-circle',
        source: 'lightning',
        filter: ['any',
        ['has', 'point_count'],
        ['>', ['get', 'Count'], 1]],
        paint: {
		  'circle-radius': 10,
		  'circle-color': '#efefef',
          'circle-stroke-opacity': 0.3,
		  'circle-stroke-width': 2,
		//   'circle-stroke-color': '#000',
		//   'circle-radius': ['step', ['get', 'point_count'], 10, 100, 15, 750, 20],
		//   'circle-color': ['step', ['get', 'point_count'], '#51bbd6', 100, '#f1f075', 750, '#f28cb1'],


            //   'circle-color': ['step', ['get', 'point_count'], '#FF8C00', 100, '#DFE566', 750, '#8BCE93'],
            //   'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40]
        }
	  };

	  const clusterCountLayer: any = {
        id: 'cluster-count',
        type: 'symbol',
        source: 'lightning',
        filter: ['any',
        ['has', 'point_count'],
        ['>', ['get', 'Count'], 1]],
        layout: {
		//   'text-field': '{point_count_abbreviated}',
		//   'text-field': ['get', 'sum'],
        "text-field": [ "case", 
            ["has", "Count"],
            ["get", "Count"],
            ['all', [">=", ["abs", ["get", "sum"]], 1.0e+9]],
            ["concat", ["to-string", ["round", ["/", ["number", ["get", "sum"]], ["number", 1000000000] ]]], 'B'],
            ['all', [">=", ["abs", ["get", "sum"]], 1.0e+6]], 
            ["concat", ["to-string", ["round", ["/", ["number", ["get", "sum"]], ["number", 1000000] ]]], "M"],
            ['all', [">=", ["abs", ["get", "sum"]], 1.0e+3]],
            ["concat", ["to-string", ["round", ["/", ["number", ["get", "sum"]], ["number", 1.0e+3] ]]], "K"],
            ["get", "sum"]
        ],
		//   'text-field': '{point_count_abbreviated}',
		  'text-font': ['Proxima Nova'],
		  'text-size': 10,
		  'text-allow-overlap': true
        }
	  };


    const clusterLayer: any = {
        layout: {
		  'icon-image': 'map-lightning',
		  'icon-size': 2.2,
		  'icon-padding': 0,
          "icon-allow-overlap": true,
          ///Comment out to remove text.
		//   'text-field': '{point_count_abbreviated}',
		//   'text-font': ['Proxima Nova'],
		//   'text-size': 10
          ///
        },
        type: 'symbol',
        id: 'clusters-icon',
        source: 'lightning',
        filter: ['any',
        ['has', 'point_count'],
        ['>', ['get', 'Count'], 1]],
        paint: {
		  'icon-color': '#FF8C00',
		  'icon-halo-color': '#000',
		  'icon-halo-width': 5,
		//   'icon-halo-blur': 0,
        }
	  };

    /// If I turn this into geojson should be able to add points to map as a layer and thus allow for below text labels.

        return ( data ?
        // <Source id="lightning" type="geojson" data={'https://docs.mapbox.com/mapbox-gl-js/assets/earthquakes.geojson'}>
            // <Source id="lightning" type="geojson" data={data} cluster clusterRadius={50} clusterMaxZoom={2} clusterProperties={
            <Source id="lightning" type="geojson" data={data} cluster clusterRadius={10} clusterMaxZoom={8} clusterProperties={
                {
                    sum: ["+", ["get", "Count"]]
                }

             }>
            {/* <Source id="lightning" type="geojson" data={data} > */}
                {/* <Layer {...layerStyle} /> */}
                <Layer {...greenLayer} />
                <Layer {...yellowLayer} />
                <Layer {...redLayer} />

                <Layer {...clusterLayer} />
                <Layer {...circleClusterLayer} />
                <Layer {...clusterCountLayer} />

            </Source>
            : <></>
        )
}

export default LightningLayer;
