import { useTheme } from "@mui/material/styles";
import { Box } from '@mui/material';



// ** Components ** //
import Routes from '../../Routes';
import Background from './Background';
import Sidebar from './Sidebar';
import { Toast } from '../Base';
import { closeToast, selectToast } from "../../features/toast/toastSlice";
import { useAppSelector } from "../../app/hooks";
import { useDispatch } from "react-redux";
import { Route, useLocation } from 'wouter';
import { Widget } from "../../Pages/Widget";
import { Historical } from "../../Pages";

interface ContentProps {
  toggleTheme: Function
}


export default function Content (props: ContentProps)  {
  const theme = useTheme();
  const dispatch = useDispatch();
  const toast = useAppSelector(selectToast);

	const [location] = useLocation();

  if (location.toLocaleLowerCase().includes('public/') || location.toLocaleLowerCase().includes('weatherstation/')) {
    return (
      <>
        {/* Backwards compatibility for "Widget" until route change is made */}
        <Route path="/WeatherStation/Station/:weatherStationId" component={Historical} />
        <Route path="/public/:customerName/:sirenName/Widget" component={Widget} />
        <Route path="/public/historical/:weatherStationId" component={Historical} />
      </>
    );
  }
  else
	return(
			<Box>
				{/* <nav>
					<Sidebar/>
				</nav> */}
        <main>
				<Box sx={{
          flexGrow: 1,
          [theme.breakpoints.up('sm')]: {
            paddingLeft: theme.spacing(14),
            paddingRight: theme.spacing(14),
            paddingTop: theme.spacing(10)
          },
          [theme.breakpoints.down('sm')]: {
            paddingTop: theme.spacing(14)
          },
        }}>
					<Routes toggleTheme={props.toggleTheme}/>
          <Toast {...toast} onClose={e => dispatch(closeToast())}/>
				</Box>
        </main>
				<Background />
			</Box>
		)
}
