import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined';
import React from 'react';
import { Button, Box, Grow, styled, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import BaseControl from './BaseControl';
import { getOverlays, getPrecipLayer, getWXSLayer, layerToggleDirection, selectPrecipLayer, selectWXSLayer, setOverlays } from '../../../../features/map/mapSlice';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { PWColors } from '../../../../Theme/PWColors';

const StyledToggleButton = styled(ToggleButton)(() => ({
	marginTop: 2,
	marginBottom: 2,
	border: 'none',
    borderRadius:'8px',
	'&:hover': {
		backgroundColor: '#E9E9E9'
	},
}))

interface LayerControlProps {
	onClick?: React.MouseEventHandler,
	show: boolean
}


export function LayerControl (props: LayerControlProps) {
	const {onClick, show} = props;

	const dispatch = useAppDispatch();

	var chosenLayers = useAppSelector(getOverlays);
	var chosenWXSLayer = useAppSelector(getWXSLayer);
	var chosenPrecip = useAppSelector(getPrecipLayer);

	const handleLayerChange = (event: React.MouseEvent<HTMLElement>, newLayers: string[]) => {
		dispatch(setOverlays(newLayers));
	};

    const handleToggleClick = (event: React.MouseEvent<HTMLElement>, type: string, direction: layerToggleDirection) => {
        switch (type)
        {
            case 'precip':
                dispatch(selectPrecipLayer(direction));
                break;
            case 'wxs':
                dispatch(selectWXSLayer(direction));
                break;
            default:
        }
        event.stopPropagation();
    };


	return (
		<Box>
			<Grow in={show}>
				<div className="overlayMenu right">
					<ToggleButtonGroup
						color="secondary"
						orientation="vertical"
						value={chosenLayers as string[]}
						onChange={handleLayerChange}
					>
						<StyledToggleButton value="tropical-models">
							<Typography color={PWColors.dark.base['800']}>Tropical Models</Typography>
						</StyledToggleButton>
						<StyledToggleButton value="surface-analysis">
							<Typography color={PWColors.dark.base['800']}>Surface Analysis</Typography>
						</StyledToggleButton>
						<StyledToggleButton value="storm-vectors">
							<Typography color={PWColors.dark.base['800']}>Storm Cells</Typography>
						</StyledToggleButton>
						<StyledToggleButton value="nws-alerts">
							<Typography color={PWColors.dark.base['800']}>NWS Alerts</Typography>
						</StyledToggleButton>
						<StyledToggleButton value="radar">
							<Typography color={PWColors.dark.base['800']}>Radar</Typography>
						</StyledToggleButton>
						<StyledToggleButton value="lightning">
							<Typography color={PWColors.dark.base['800']}>Lightning</Typography>
						</StyledToggleButton>
						<StyledToggleButton value="hardware">
							<Typography color={PWColors.dark.base['800']}>Hardware</Typography>
						</StyledToggleButton>
						<StyledToggleButton value="wxs">
                            <Box display="flex" justifyContent="space-between">
                                <Button variant="contained" onClick={(event) => handleToggleClick(event, 'wxs', 'prev')}>{'<'}</Button>
                                    <Typography color={PWColors.dark.base['800']}>WXS: {chosenWXSLayer}</Typography>
                                <Button variant="contained" onClick={(event) => handleToggleClick(event, 'wxs', 'next')}>{'>'}</Button>
                            </Box>
						</StyledToggleButton>
                        <StyledToggleButton value="precip-layer">
                            <Button variant="contained" onClick={(event) => handleToggleClick(event, 'precip', 'prev')}>{'<'}</Button>
                                <Typography color={PWColors.dark.base['800']}>{chosenPrecip}</Typography>
                            <Button variant="contained" onClick={(event) => handleToggleClick(event, 'precip', 'next')}>{'>'}</Button>
                        </StyledToggleButton>
					</ToggleButtonGroup>
				</div>
			</Grow>
			<BaseControl onClick={onClick} label="Layer Options">
				<LayersOutlinedIcon/>
			</BaseControl>
		</Box>
	);
}

export default LayerControl;
