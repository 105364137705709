import { Source, Layer, LayerProps } from 'react-map-gl';
import { RadarParams, useRadarAnimListQuery, useRadarQuery } from '../../../../Services/API';
import { RasterPaint, Map } from 'maplibre-gl';
import { useState, useEffect } from 'react';
import { getAnimSpeed, getOpacity, getProgress, getRadarPlaying, getRadarStyle, setCurrentTime, setProgress } from '../../../../features/map/mapSlice';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { getBBox, Viewport } from '../Util';


interface RadarProps {
	hasLocation?: boolean,
	viewport: Viewport,
	mapRef: Map
}

function project(latitude: number, longitude: number) {
	const MAX_LAT = 85.0511287798;
	var d = Math.PI / 180.0;
	var lat = Math.max(Math.min(MAX_LAT, latitude), -MAX_LAT);
	var x = longitude * d;
	var y = lat * d;

	y = Math.log(Math.tan(Math.PI / 4 + y / 2));
	const obj = {
	x: x * 6378137,
		y: y * 6378137,
		};
		return obj;
}


function getCoordinates(mapRef: Map): number[][] {
	if (mapRef) {
		let bounds = mapRef.getBounds();
		return [
			[bounds.getNorthWest().lng, bounds.getNorthWest().lat],
			[bounds.getNorthEast().lng, bounds.getNorthEast().lat],
			[bounds.getSouthEast().lng, bounds.getSouthEast().lat],
			[bounds.getSouthWest().lng, bounds.getSouthWest().lat],
		];
	}
	else return [
		[0.0, 0.0],
		[0.0, 0.0],
		[0.0, 0.0],
		[0.0, 0.0]
	];
}


function getPath(viewport: Viewport, mapRef: Map, urls: string[], index: number) {
	let bbox = getBBox(viewport, mapRef);
	var a = project(bbox.top, bbox.left);
	var b = project(bbox.bottom, bbox.right);
	let width = mapRef.getCanvas().width;
	let height = mapRef.getCanvas().height;

	// let ar = convertBBoxToAspectRatio([a.x, b.y, b.x, a.y]);

	let bboxR = `&BBOX=${a.x},${b.y},${b.x},${a.y}&height=${height}&width=${width}`;
	if (index < urls.length && urls[index] !== undefined)
		return urls[index] + bboxR;
	else return urls[0] + bboxR;
}


export function RadarLayer (props: RadarProps) {
	const { mapRef, viewport, hasLocation } = props;
	const radarPlaying = useAppSelector(getRadarPlaying);
	const animSpeed = useAppSelector(getAnimSpeed);
	const opacity = useAppSelector(getOpacity);
	const progress = useAppSelector(getProgress);
	const dispatch = useAppDispatch();
	var currentImage = progress;

	const [radarParams, setRadarParams] = useState<RadarParams>( {type: 'Radar', isFuture: false, product: '', frame:19});
	const [currentUrl, setCurrentUrl] = useState<string>('');
	const [coordinates, setCoordinates] = useState<number[][]>(getCoordinates(mapRef));

	const radarStyle = useAppSelector(getRadarStyle);
	const { data, isSuccess: tmsSuccess} = useRadarQuery(radarParams, { pollingInterval: 1 * 60000, refetchOnFocus: true, refetchOnReconnect: true });
	const { data: radarAnimList, isSuccess } = useRadarAnimListQuery(radarParams, { skip: !radarPlaying} );


	useEffect( () => {
		if (radarStyle === 'past')
			setRadarParams ({type: 'Radar', product: '', isFuture: false, frame: 19});
		else if (radarStyle === 'future')
			setRadarParams ({type: 'Radar', product: '', isFuture: true, frame: 0});
		else if (radarStyle === 'extended')
			setRadarParams ({type: 'Radar', product:'nam-maxreflectivity-dbz-all', isFuture: true, frame: 0});
	}, [radarStyle]);


	useEffect ( () => {
		// let source: RasterSource = mapRef.getSource('radar') as RasterSource;
		let source: any = mapRef.getSource('radar');
		if (data && source) {
			source.tiles=[data!]
			source.url=data!;

			let mapAny: any = mapRef as any;
			mapAny.getSource('radar').tiles = [ data! ]
			mapAny.style.sourceCaches['radar'].clearTiles()
			// // Load the new tiles for the current viewport (map.transform -> viewport)
			mapAny.style.sourceCaches['radar'].update(mapAny.transform)
			mapAny.triggerRepaint();
		}
	}, [data, mapRef]);

	/// Should implemenet aspect ratio correction.
	useEffect(() => {
		if (mapRef && !radarPlaying && tmsSuccess) {
				///This is all for static...
				var urlTime: string ="";
				var result = data!.match('(\\+.*?){2}(.*?)(.*?\\:*\\:.*?\\:.*?)\\/.*')
				if (result && result.length > 2) urlTime = result[3];


				result = data!.match('(}.*?){3}=(.*?)(.*?:*:.*?:.*?:*?)&');
				if (result && result.length > 2) {
					urlTime = result[3];
				}
				dispatch(setCurrentTime({time: urlTime, displayDate: radarStyle === 'extended'}));
		}
		else if (mapRef && radarPlaying && radarAnimList && isSuccess)
		{
			var timer = setInterval(function () {
				// eslint-disable-next-line react-hooks/exhaustive-deps
				currentImage = (currentImage + 1) % radarAnimList.length;
				currentImage = Math.round(currentImage);
				const progressDecimal = currentImage / radarAnimList.length;
				dispatch(setProgress(progressDecimal * 100));

				let radarAnimLR = [...radarAnimList!];

				let path = (getPath(viewport, mapRef, radarAnimLR.reverse(), currentImage));

				var dt: string = "";
				if (radarStyle === 'future' || radarStyle === 'extended') {
					let pathmatch = path.match('(=.*?){5}=(.*?)(.*?:*:.*?:.*?:*?)&');
					if (pathmatch) dt = pathmatch![3];
				}
				else {
					let pathmatch = path.match('(=.*?){4}=(.*?)(.*?:*:.*?:.*?:*?)&');
					if (pathmatch) dt = pathmatch![3];
				}
				dispatch(setCurrentTime({time: dt, displayDate: radarStyle === 'extended'}));

				setCurrentUrl(path);
				setCoordinates(getCoordinates(mapRef));

			}, animSpeed);
			return () => { clearInterval(timer);}
		}

	}, [radarPlaying, radarStyle, radarAnimList, isSuccess, animSpeed, tmsSuccess, data]);



	const paints: RasterPaint = {
		'raster-opacity': opacity,
	};

	const layerStyle: LayerProps = {
		id: 'radar',
		type: 'raster',
		minzoom: 0,
		maxzoom: 14,
		paint: paints,
	};


	const animStyle: LayerProps = {
		id: 'radar-anim',
		type: 'raster',
		minzoom: 0,
		maxzoom: 14,
		paint: {
			"raster-fade-duration": 0,
			// "raster-resampling": 'nearest',
			'raster-opacity': opacity
		},
	};


	const animation = (
		<Source coordinates={coordinates} id="radar-anim" url={currentUrl} type="image" >
			{radarPlaying ? ( hasLocation ? <Layer beforeId="radii-1" {...animStyle} /> : <Layer beforeId="admin_sub" {...animStyle} /> ) : (<></>) }
		</Source>
	);

	const url: string = data ? data : 'https://st3.depositphotos.com/10839834/32287/v/950/depositphotos_322878930-stock-illustration-transparent-checkered-chess-background.jpg';

	return (
		<>
			<Source  id="radar" type="raster" tileSize={256} tiles={ [ url ] } scheme="tms">
				{!radarPlaying ? ( hasLocation ? <Layer beforeId="radii-1" {...layerStyle} /> : <Layer beforeId="admin_sub" {...layerStyle} /> ) : (<></>)}
			</Source>
			{currentUrl !== '' ? animation : <></>}
		</>
	);
}

export default RadarLayer;
