import { Source, Layer, LayerProps } from 'react-map-gl';
import { RadarParams, useRadarQuery } from '../../../../Services/API';
import { RasterPaint, Map } from 'maplibre-gl';
import { useState, useEffect } from 'react';
import { getOpacity, getOverlays, getPrecipLayer } from '../../../../features/map/mapSlice';
import { useAppSelector } from '../../../../app/hooks';
import { Viewport } from '../Util';


interface PrecipLayerProps {
	hasLocation?: boolean,
	viewport: Viewport,
	mapRef: Map
}

// function project(latitude: number, longitude: number) {
// 	const MAX_LAT = 85.0511287798;
// 	var d = Math.PI / 180.0;
// 	var lat = Math.max(Math.min(MAX_LAT, latitude), -MAX_LAT);
// 	var x = longitude * d;
// 	var y = lat * d;

// 	y = Math.log(Math.tan(Math.PI / 4 + y / 2));
// 	const obj = {
// 	x: x * 6378137,
// 		y: y * 6378137,
// 		};
// 		return obj;
// }



export function PrecipAccumLayer (props: PrecipLayerProps) {

	const { mapRef, hasLocation } = props;

	// const radarPlaying = useAppSelector(getRadarPlaying);
	// const animSpeed = useAppSelector(getAnimSpeed);
	const opacity = useAppSelector(getOpacity);
	const chosenLayers = useAppSelector(getOverlays);
	const precipLayer = useAppSelector(getPrecipLayer);

				// {mapRef.current && chosenLayers.includes('alert') ? <AlertLayer viewport={viewport} mapRef={mapRef.current?.getMap()} /> : <></> }
	// const progress = useAppSelector(getProgress);
	// const dispatch = useAppDispatch();


	const [layerParams, setLayerParams] = useState<RadarParams>( {product: precipLayer ,type: 'Radar', isFuture: false, frame:0});
	// const [layerParams] = useState<RadarParams>( {type: 'Alert', isFuture: false, frame:0});


	// const radarParams: RadarParams = {
	// 	type: 'Radar',
	// 	isFuture: false,
	// 	frame: 19
	// }

	const { data } = useRadarQuery(layerParams, { pollingInterval: 1 * 60000, refetchOnFocus: true, refetchOnReconnect: true });



    useEffect( () => {
        // refetch() ;
        setLayerParams({product: precipLayer, type: 'Radar', isFuture: false, frame: 0});
    }, [precipLayer]);

	useEffect ( () => {
		// let source: RasterSource = mapRef.getSource('radar') as RasterSource;
		let source: any = mapRef.getSource('precip');
		if (data && source) {
			source.tiles=[data!]
			source.url=data!;

			// console.debug(mapRef);
			let mapAny: any = mapRef as any;
			mapAny.getSource('precip').tiles = [ data! ]
			mapAny.style.sourceCaches['precip'].clearTiles()
			// // Load the new tiles for the current viewport (map.transform -> viewport)
			mapAny.style.sourceCaches['precip'].update(mapAny.transform)
			mapAny.triggerRepaint();
		}

	}, [data, mapRef]);

	const paints: RasterPaint = {
		'raster-opacity': opacity,
	};

	const layerStyle: LayerProps = {
		id: 'precip',
		type: 'raster',
		minzoom: 0,
		maxzoom: 14,
		paint: paints,
	};


	var precipBase = hasLocation ? (<Layer beforeId="radii-1" {...layerStyle} />) : (<Layer beforeId="admin_sub" {...layerStyle} />);
	// var alertsWhenNotAnimating = !radarPlaying ? radarRender : (<></>);
	var precipToRender = chosenLayers.includes('radar') ? (<Layer beforeId='radar' {...layerStyle}/>) : (precipBase) ;
	const url: string = data ? data : 'https://st3.depositphotos.com/10839834/32287/v/950/depositphotos_322878930-stock-illustration-transparent-checkered-chess-background.jpg';

	return (
		<>
			<Source  id="precip" type="raster" tileSize={256} tiles={ [ url ] } scheme="tms">
				{/* {!radarPlaying ? ( hasLocation ? <Layer beforeId="radii-1" {...layerStyle} /> : <Layer beforeId="admin_sub" {...layerStyle} /> ) : (<></>)} */}
				{precipToRender}
			</Source>
		</>
	);
}

export default PrecipAccumLayer;
