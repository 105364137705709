import { Box, Divider, Grid, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { API_URL, DateRange } from "../../Services/API";
import { getToken } from "../../Services/Auth/authSlice";
import { BaseModal, Button } from "../Base";
import { useAppSelector } from '../../app/hooks';
import DateRangePicker from '@mui/lab/DateRangePicker';
import {DateRange as MUIDateRange} from '@mui/lab/DateRangePicker/RangeTypes'

interface ModalProps {
    onSubmit?: React.MouseEventHandler,
    onCancel?: React.MouseEventHandler,
    open: boolean,
    handleClose: Function,
    weatherStationId?: string,
    weatherStationName?: string
}

export function ExportDataModal(props: ModalProps) {
    const token: string | null = useAppSelector(getToken);

    const { open, handleClose, weatherStationId, weatherStationName } = props;
    const [dateRange, setDateRange] = useState<DateRange>({
        start: function(d){ d.setDate(d.getDate()-8); return d} (new Date()),
        end: function(d){ d.setDate(d.getDate()-1); return d} (new Date())
    });

    const handleDateRangeChange = (dateRange: MUIDateRange<Date>) => {
        let startDate = dateRange[0];
        let endDate = dateRange[1];

        if (dateRange && startDate && endDate) {
            let start = new Date(startDate.toString());
            let end = new Date(endDate.toString());

            let dateNow = new Date();

            let timeDiffInMs: any = end.getTime() - start.getTime();
            let timeDiffInDays = timeDiffInMs / 1000 / 60 / 60 / 24;

            if (end > start && dateNow > end && timeDiffInDays <= 1095) {
                setDateRange({ start: startDate, end: endDate })
            }
        }
    };

    const handleExport = () => {
        let startDate = dateRange!.start.toISOString();
        let endDate = dateRange!.end.toISOString();

        fetch(`${API_URL}/Observations/Historical/Export/${weatherStationId}?startDate=${startDate}&endDate=${endDate}`,
            {
                method: 'GET',
                headers: {
                    'Accept': 'text/csv',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                }
            })
            .then((response) => response.blob())
            .then(blob => URL.createObjectURL(blob))
            .then(url => {
                var link = document.createElement("a");
                link.href = url;
                link.download = `${weatherStationName}.csv`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            });
        handleClose();
    };

    return (
        <BaseModal modalState={{ open: open, handleOpen: () => { }, handleClose: () => handleClose(false) }}>
            <Grid container style={{ height: '100%' }}>
                <Grid item xs={12} style={{ marginBottom: 8 }}>
                    <Typography variant='h5'>Export Data</Typography>
                </Grid>

                <Divider style={{ width: '100%', marginBottom: 16 }} />

                <Grid item container xs={12} display='flex' justifyContent='center' marginTop={2} marginBottom={4}>
                <DateRangePicker
                    label="Start Date"
                    inputFormat="MM/DD/yyyy"
                    value={[new Date(dateRange.start), new Date(dateRange.end)]}
                    onChange={(dateRange) => handleDateRangeChange(dateRange)}
                    renderInput={(startProps, endProps) => (
                      <>
                        <TextField {...startProps} sx={{maxWidth: 150}} />
                        <Box height='100%' marginLeft={1} marginRight={1}>
                          <Typography height='100%' variant="h3">-</Typography>
                        </Box>
                        <TextField {...endProps} sx={{maxWidth: 150}} />
                      </>
                    )}
                  />
                </Grid>

                <Grid container display='flex' justifyContent='flex-end' spacing={1}>
                    <Grid item>
                        <Button type='clear' onClick={e => handleClose()}>
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button onClick={e => handleExport()}>
                            Export
                        </Button>
                    </Grid>
                </Grid>

            </Grid>
        </BaseModal>);
}