import { Grid, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useWidgetQuery } from "../Services/API/publicApi";


export function Widget(props: any) {
    const {customerName, sirenName} = props.params;
    const theme = useTheme();

    const [allClearTime, setAllClearTime] = useState<string>("");

    const {data} = useWidgetQuery({customerName: window.decodeURI(customerName).split(' ').join(''), sirenName: window.decodeURI(sirenName).split(' ').join('')}, {pollingInterval: 150000});

    useEffect(() => {
        if(data){
            let time = data.clearTime;
            let seconds: number = Number.parseInt(time.split(':')[0]) * 60 + (Number.parseInt(time.split(':')[1]));

            let psuedoTimer = setInterval(() => {
                setAllClearTime(Math.trunc(seconds/60).toString() + ':' + ((seconds % 60) >= 10 ? (seconds % 60).toString() : '0' + (seconds % 60).toString()))
                --seconds;

                if(seconds < 0)
                    seconds = 0;

              }, 1000);

              return () => {
                clearInterval(psuedoTimer);
            };
        }
    }, [data]);

    if(data){
        return (
            <Grid container display='flex' flexDirection='column' justifyContent='center' width='100vw' height='100vh' style={{backgroundColor:(data.isInDelay ? 'red' : 'green')}}>
                <Grid item marginBottom='16vh'>
                    <Typography fontSize='12vh' fontWeight='bold'>{data.locationName}</Typography>
                </Grid>
                <Grid item>
                    <Typography fontSize='12vh' fontWeight='bold'>{data.isInDelay ? allClearTime : 'All Clear'}</Typography>
                </Grid>
            </Grid>
        )
    }
    else
        return(
            <Grid container display='flex' flexDirection='column' justifyContent='center' width='100vw' height='100vh' style={{backgroundColor:theme.palette.warning.main}}>
                <Grid item marginBottom='16vh'>
                    <Typography fontSize='12vh' fontWeight='bold'>Failed to get data</Typography>
                </Grid>
            </Grid>
        )
}