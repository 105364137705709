export default function TimeParser(time: string) {
    var timeValues = time.split(':');
    var hours = parseInt(timeValues[0], 10);
    var minutes = parseInt(timeValues[1], 10);
    var seconds = parseInt(timeValues[2], 10);

    var mins = minutes + hours * 60;

    return [mins, seconds];
  }

export const formatTimeStringFromMins = (timeInMins: number, appendTxt: string = 'ago') => {
    if (timeInMins < 90) {
        return Math.round(timeInMins) + ` minute${timeInMins > 1 ? 's' : ''} ${appendTxt}`;
    }
    else {
        let timeInHours = Math.round(timeInMins / 60);

        if (timeInHours < 36) {
            return timeInHours + ` hour${timeInMins > 1 ? 's' : ''} ${appendTxt}`;
        }
        else {
            let timeInDays = Math.round(timeInHours / 24);

            return timeInDays + ` day${timeInMins > 1 ? 's' : ''} ${appendTxt}`;
        }
    }
}

export function GetShortTime(time: Date) {
  var date = new Date(time.toString());
    var hours = date.getHours();
    var hourStr = '';
    var meridianStr = '';

    if (hours === 0) {
        hourStr = '12';
        meridianStr = 'AM';
    }
    else if (hours > 12) {
        hourStr = (hours - 12).toString();
        meridianStr = 'PM';
    }
    else if (hours === 12) {
        hourStr = '12';
        meridianStr = 'PM';
    }
    else {
        hourStr = hours.toString();
        meridianStr = 'AM';
    }

    return hourStr + meridianStr;
}

export const ConvertToUtc = (dateString: string) => {
    let date: Date;

    if(dateString.substr(dateString.length - 1).toLowerCase() === 'z')
        date = new Date(dateString.substr(0, dateString.length-1));
    else
        date = new Date(dateString);

    
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(),
    date.getHours(), date.getMinutes(), date.getSeconds()));
}

export function getTimeDifferenceInMins(dt: Date){
    let dtNow: Date = new Date(new Date().toUTCString());
    var diff = dtNow.valueOf() - new Date(dt).valueOf();
    return diff / 60000;
}

export const DateFormatter = (dateStr: string) => {
    var date = new Date(dateStr);
    return (date.getUTCMonth() + 1) + '-' + date.getUTCDate() + '-' +  date.getUTCFullYear();
  }

export const DateTimeFormatter = (dateTimeStr: string) => {
    const options: Intl.DateTimeFormatOptions = { hour: 'numeric', minute: 'numeric' };
    var date = new Date(dateTimeStr);
    return date.toLocaleString('en-US', options)
  }